<template>
	<div class="appWhite rounded-lg pa-4">

		<app-text color="primary" size="normal-bold">{{cardData.inductionQuestion}}</app-text>

		<app-text class="mt-4" color="grey9" size="small">{{cardData.inductionCorrectAnswer}}</app-text>

	</div>
</template>

<script>

export default {

	name: "InductionManagementMobileCard",

	props: ['cardData'],

	data: () => ({}),

	methods: {},

}
</script>

<style scoped>

</style>
