<template>
	<div class="appWhite d-flex rounded-lg pa-4">

		<!--Status | Expiry Warning-->
		<div class="mt-2 mr-4">

			<!--Status-->
			<app-icon v-if="cardData.qualificationData?.qualificationStatus === 'Approved'"
					  color="green" icon="success" size="32"/>
			<app-icon v-if="cardData.qualificationData?.qualificationStatus === 'Pending'"
					  color="orange" icon="pending" size="32"/>
			<app-icon v-if="cardData.qualificationData?.qualificationStatus === 'Rejected'"
					  color="red" icon="cancel" size="32"/>

			<!--Expiry Warning-->
			<app-icon v-if="checkExpiringSoon(cardData.qualificationExpiryDate)"
					  class="flash pt-6" color="red" icon="error" title="Expiring soon"/>

		</div>

		<div class="d-flex flex-column">

			<!--Card Details-->
			<div class="d-flex flex-column mr-4" style="width: 100%">

				<!--Name-->
				<!--If no qualificationName, show temporaryQualificationName-->
				<div class="d-flex align-center">
					<app-text v-if="cardData.qualificationPresetData?.qualificationName"
							  color="primary"
							  size="normal-bold">
						{{ cardData.qualificationPresetData?.qualificationName }}
					</app-text>
					<app-text v-else
							  color="primary"
							  size="normal-bold">
						{{ cardData.qualificationData?.qualificationTemporaryName }}
					</app-text>
				</div>

				<!--Category - if the qualification is a preset-->
				<app-text v-if="cardData.qualificationPresetData?.qualificationCategoryId" color="grey9" size="small">
					{{ getCategoryById(cardData.qualificationPresetData?.qualificationCategoryId) }}
				</app-text>
				<app-text v-else color="grey9" size="small">-</app-text>

			</div>

			<v-divider class="greyD my-2"/>

			<!--Dates-->
			<div class="d-flex align-center">

				<!--Issue Date-->
				<!--If list has a issue date-->
				<app-text v-if="cardData.qualificationData?.qualificationIssueDate"
						  size="small">
					{{ MIX_formatDate(cardData.qualificationData?.qualificationIssueDate, 'numeric') }}
				</app-text>
				<!--If list has no issue date-->
				<app-text v-if="!cardData.qualificationData?.qualificationIssueDate" color="grey9" size="small">-
				</app-text>

				<app-text class="mx-2">-</app-text>

				<!--Expiry Date-->
				<!--If qualification has a expiry date and it is not expired-->
				<app-text
					v-if="cardData.qualificationData?.qualificationExpiryDate && new Date().getTime() < cardData.qualificationData?.qualificationExpiryDate"
					size="small">
					{{ MIX_formatDate(cardData.qualificationData?.qualificationExpiryDate, 'numeric') }}
				</app-text>
				<!--If qualification has a expiry date and it has expired-->
				<v-chip
					v-if="cardData.qualificationData?.qualificationExpiryDate && new Date().getTime() > cardData.qualificationData?.qualificationExpiryDate"
					class="px-1 white--text" :color="'red'" label small>
					{{ MIX_formatDate(cardData.qualificationData?.qualificationExpiryDate, 'numeric') }}
				</v-chip>
				<!--If qualification has no expiry date-->
				<app-text v-if="!cardData.qualificationData?.qualificationExpiryDate" color="grey9" size="small">-
				</app-text>

			</div>

		</div>

	</div>
</template>

<script>

export default {

	name: "QualificationPresetMobileCard",

	props: ['cardData', 'qualificationCategoriesData'],

	data: () => ({}),

	methods: {

		/**
		 * Check Expiring Soon
		 *
		 * Check if the expiry date is within 1 week.
		 *
		 * @param itemData {object} the item to check
		 * @returns {boolean} true if the expiry date is within 2 weeks
		 */
		checkExpiringSoon(itemData) {
			const t = this

			if (!itemData) return false

			const EXPIRY_DATE = new Date(itemData).getTime()
			const TODAY = new Date().getTime()
			const ONE_WEEK = 1000 * 60 * 60 * 24 * 7

			return EXPIRY_DATE - TODAY < ONE_WEEK
		},

		/**
		 * Get Category By ID
		 *
		 * Get the category name by its ID.
		 *
		 * @param qualificationCategoryId {string} the category ID
		 * @returns {string} the category name
		 */
		getCategoryById(qualificationCategoryId) {
			const t = this

			// Find the category
			const CATEGORY = t.qualificationCategoriesData.find(category => category.entityId === qualificationCategoryId)

			return CATEGORY?.qualificationCategoryName || 'None'
		},
	},

}
</script>

<style scoped>

</style>
