<template>

	<page-loading-animation v-if="isLoading" :is-loading="isLoading"/>

	<!--Page Content-->
	<div v-else>

		<!--Header-->
		<page-title :divider="true"
					icon="termsConditions"
					info="View and administer your documentation."
					title="Documentation"/>

		<!--Action Bar-->
		<div class="d-flex align-center mt-4">

			<!--Search-->
			<app-form-field form-type="textInput"
							append-icon="icons8-search"
							class="mr-4"
							:clearable="true"
							label="Document Name"
							style="width: 100%"
							v-model.trim="searchByDocumentName"/>

			<!--Add Button-->
			<app-btn v-if="['Staff-Admin', 'Contractor-Manager'].includes(MIX_getCurrentUser().userLevel)"
					 @click.native="openRightPanel" icon="add" label="Add"/>

			<!--Filter Button - with a numbered badge-->
			<!--<div style="position:relative;">-->
			<!--	<app-btn @click.native="toggleFiltersVisibility"-->
			<!--			 class="mr-4" color="appWhite" icon="filter" icon-color="primary"/>-->
			<!--	<span v-if="computedNumberOfActiveFilters > 0" class="badge">{{ computedNumberOfActiveFilters }}</span>-->
			<!--</div>-->

			<!--More Actions Menu-->
			<!--<more-actions-menu @emitMenuSelection="handleEmittedMenuSelection" :menuList="moreActionsMenuOption"/>-->

		</div>

		<!--Clear Filters-->
		<div v-if="computedNumberOfActiveFilters > 0" class="d-flex justify-end mt-4">
			<app-btn @click.native="clearFilters" icon="close" label="Clear Filters"/>
		</div>

		<!--Table-->
		<v-data-table v-if="$vuetify.breakpoint.width >= 600"
					  class="appWhite rounded-lg mt-4"
					  :headers="computedHeaders"
					  :items="computedTableData">

			<!--ID-->
			<template v-slot:item.entityId="{item}">
				<app-text size="small">{{ item.entityId }}</app-text>
			</template>

			<!--Has Accepted-->
			<template v-slot:item.acceptanceStatus="{item}">

				<!--Not Required-->
				<app-text v-if="getUserDocumentAcceptanceStatus(item) === 'Not Required'"
						  class="greyD rounded-lg pa-1" size="small">
					N/A
				</app-text>

				<!--New-->
				<app-text v-else-if="getUserDocumentAcceptanceStatus(item) === 'New'"
						  class="green rounded-lg pa-1" color="white" size="small">
					New
				</app-text>

				<!--Accepted-->
				<app-icon v-else-if="getUserDocumentAcceptanceStatus(item)" color="green" icon="success" size="32"/>

				<!--Not Accepted-->
				<app-icon v-else-if="!getUserDocumentAcceptanceStatus(item)" color="red" icon="cancel" size="32"/>

			</template>

			<!--File Type-->
			<template v-slot:item.documentType="{item}">
				<div class="d-flex justify-center pa-2">
					<v-img v-if="item.documentType === 'application/pdf'"
						   :src="require('@/assets/images/icons8-pdf.svg')"
						   width="24"></v-img>
					<v-img
						v-if="item.documentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"
						:src="require('@/assets/images/icons8-microsoft-excel-2019.svg')"
						width="24"></v-img>
					<v-img
						v-if="item.documentType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'"
						:src="require('@/assets/images/icons8-microsoft-powerpoint-2019.svg')"
						width="24"></v-img>
					<v-img
						v-if="item.documentType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'"
						:src="require('@/assets/images/icons8-microsoft-word-2019.svg')"
						width="24"></v-img>
					<v-img v-if="['image/jpeg', 'image/jpg', 'image/png'].includes(item.documentType)"
						   :src="require('@/assets/images/icons8-image.svg')"
						   width="24"></v-img>
				</div>
			</template>

			<!--Document Name-->
			<template v-slot:item.documentName="{item}">
				<app-text size="small">{{ item.documentName }}</app-text>
			</template>

			<!--Uploaded Date-->
			<template v-slot:item.createdDateTime="{item}">
				<app-text size="small">
					{{ MIX_formatDate(item.createdDateTime, 'short') }}
					@
					{{ MIX_formatDateTimeToTime(item.createdDateTime) }}
				</app-text>
			</template>

			<!--Action Button-->
			<template v-slot:item.action="{item}">
				<app-icon @click.native="openRightPanel(item)"
						  class="cursorPointer" color="primary" icon="arrowForward"/>
			</template>

		</v-data-table>

		<!--Mobile Cards-->
		<div v-if="$vuetify.breakpoint.width < 600">

			<!--No data message-->
			<app-text v-if="!computedTableData.length"
					  class="text-center mt-4" color="grey9">You have no Documents to view
			</app-text>

			<div v-for="item in computedTableData" :key="item.entityId"
				 @click="openRightPanel(item)"
				 class="appWhite d-flex align-center rounded-lg mt-4 pa-4">

				<!--File Type-->
				<div class="d-flex justify-center mr-4" style="width: 48px">
					<v-img v-if="item.documentType === 'application/pdf'"
						   :src="require('@/assets/images/icons8-pdf.svg')"
						   width="24"></v-img>
					<v-img
						v-if="item.documentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"
						:src="require('@/assets/images/icons8-microsoft-excel-2019.svg')"
						width="24"></v-img>
					<v-img
						v-if="item.documentType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'"
						:src="require('@/assets/images/icons8-microsoft-powerpoint-2019.svg')"
						width="24"></v-img>
					<v-img
						v-if="item.documentType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'"
						:src="require('@/assets/images/icons8-microsoft-word-2019.svg')"
						width="24"></v-img>
					<v-img v-if="['image/jpeg', 'image/jpg', 'image/png'].includes(item.documentType)"
						   :src="require('@/assets/images/icons8-image.svg')"
						   width="24"></v-img>
				</div>

				<div>

					<!--Document Name-->
					<app-text size="normal-bold">{{ item.documentName }}</app-text>

					<!--Uploaded Date-->
					<app-text color="grey9" size="small">{{ MIX_formatDate(item.createdDateTime, 'short') }}</app-text>

				</div>

				<v-spacer/>

				<div>
					<!--Not Required-->
					<app-text v-if="getUserDocumentAcceptanceStatus(item) === 'Not Required'"
							  class="greyD rounded-lg pa-1" size="small">
						N/A
					</app-text>

					<!--New-->
					<app-text v-else-if="getUserDocumentAcceptanceStatus(item) === 'New'"
							  class="green rounded-lg pa-1" color="white" size="small">
						New
					</app-text>

					<!--Accepted-->
					<app-icon v-else-if="getUserDocumentAcceptanceStatus(item)" color="green" icon="success" size="32"/>

					<!--Not Accepted-->
					<app-icon v-else-if="!getUserDocumentAcceptanceStatus(item)" color="red" icon="cancel" size="32"/>
				</div>

			</div>
		</div>

		<!--Filters Panel ------------------------------------------------------------------------------------------ -->
		<filter-panel :is-filters-panel-visible="isFiltersPanelVisible" @toggle="toggleFiltersVisibility">
			<div class="pa-4">

			</div>
		</filter-panel>

		<!--Right Side ----------------------------------------------------------------------------------- Right Side-->
		<transition enter-active-class="animate__animated animate__fadeInRight animate__faster"
					leave-active-class="animate__animated animate__fadeOutRight animate__faster"
					mode="out-in">

			<div v-if="isRightPanelVisible"
				 class="appGrey formShadow"
				 style="position:absolute; top:0; bottom: 0; right: 0; overflow: auto"
				 :style="$vuetify.breakpoint.width < 600 ? 'width: 100%' : 'width: 50%'"
				 v-model="isRightPanelVisible">

				<!--Header-->
				<div class="d-flex align-center primary pa-4">
					<app-text color="appWhite" size="normal">{{ selectedItem?.documentName || 'New' }}</app-text>
					<v-spacer/>
					<app-icon @click.native="closeRightPanel" class="cursorPointer" color="white" icon="close"
							  size="32"/>
				</div>

				<!--Action Bar-->
				<div v-if="tabs === 'overview'" class="d-flex align-center pa-4">

					<v-spacer/>

					<edit-icon v-if="tabs === 'overview'" @click.native="editItem" :isActive="!isReadOnly"/>
					<!--<delete-icon @emitDeleteItem="deleteItem(selectedItem?.documentName)"-->
					<!--			 itemName="documentName"-->
					<!--			 :selectedItem="selectedItem?.documentName"/>-->

				</div>

				<!--Tabs-->
				<v-tabs v-model="tabs">

					<!--Overview-->
					<v-tab v-if="['Staff-Admin', 'Contractor-Manager'].includes(MIX_getCurrentUser().userLevel)
						&& !(MIX_getCurrentUser().userLevel === 'Contractor-Manager' && selectedItem.documentUploadedByType === 'Staff')"
						   href="#overview">
						<app-text size="small">Overview</app-text>
					</v-tab>

					<!--Viewer-->
					<v-tab href="#viewer">
						<app-text size="small">Viewer</app-text>
					</v-tab>

					<!--Responses-->
					<v-tab v-if="['Staff-Admin', 'Contractor-Manager'].includes(MIX_getCurrentUser().userLevel)
						&& !(MIX_getCurrentUser().userLevel === 'Contractor-Manager' && selectedItem.documentUploadedByType === 'Staff')"
						   href="#responses">
						<app-text size="small">Responses</app-text>
					</v-tab>

				</v-tabs>

				<!--Tabs Content-->
				<v-tabs-items v-model="tabs">

					<!--Overview-->
					<v-tab-item class="appGrey pa-4" value="overview">

						<documentation-form @emitReloadPage="reloadPage"
											:form-data="selectedItem"
											:is-read-only="isReadOnly"
											:users-data="usersData"/>

					</v-tab-item>

					<!--Viewer-->
					<v-tab-item class="appGrey pa-4" value="viewer">

						<documentation-viewer @emitReloadPage="reloadPage"
											  :file-url="selectedItemFileUrl"
											  :selected-item="selectedItem"
											  :user-document-action-data="userDocumentActionData"/>

					</v-tab-item>

					<!--Responses-->
					<v-tab-item class="appGrey pa-4" value="responses">

						<documentation-responses @emitReloadPage="reloadPage"
												 :document-actions-data="documentActionsData"
												 :selected-item="selectedItem"
												 :users-data="usersData"/>

					</v-tab-item>

				</v-tabs-items>

			</div>

		</transition>

	</div>

</template>

<script>

import DocumentationForm from "@/views/documentation/documentationForm/DocumentationForm.vue";
import DocumentationViewer from "@/views/documentation/documentationViewer/DocumentationViewer.vue";
import DocumentationResponses from "@/views/documentation/documentationResponses/DocumentationResponses.vue";

export default {

	name: "Documentation",

	components: {DocumentationResponses, DocumentationViewer, DocumentationForm},

	data: () => ({
		isLoading: false,
		isFiltersPanelVisible: false,
		isReadOnly: false,
		isRightPanelVisible: false,
		moreActionsMenuOption: [
			{name: 'Export', icon: 'export'}
		],
		searchByDocumentName: '',
		selectedItem: {},
		selectedItemFileUrl: '',
		tableHeaders: [
			{text: 'ID', value: 'entityId', align: 'start', sortable: false, hidden: true},
			{text: 'Accepted?', value: 'acceptanceStatus', align: 'center', sortable: false, width: '104px'},
			{text: 'File Type', value: 'documentType', align: 'center', sortable: false, width: '96px'},
			{text: 'Document Name', value: 'documentName', align: 'start', sortable: false},
			{text: 'Uploaded Date', value: 'createdDateTime', align: 'start', sortable: false},
			{text: '', value: 'action', align: 'center', sortable: false, width: '48px'},
		],
		tabs: '',
		hasAccepted: false,

		// Data
		documentationData: [],
		documentActionsData: [],
		usersData: [],
		userDocumentActionData: {},
		allCurrentUserDocumentActionsData: [],
	}),

	computed: {

		/**
		 * Computed Headers
		 *
		 * Remove hidden headers and only show the actions column to those with access.
		 *
		 * @returns an array of header objects
		 */
		computedHeaders() {
			const t = this
			let headers = t.tableHeaders

			// Remove hidden headers
			headers = headers.filter(h => !h.hidden)

			return headers
		},

		/**
		 * Computed Number of Active Filters
		 *
		 * Count the number of active filters to display in the filters button badge.
		 *
		 * @returns {*}
		 */
		computedNumberOfActiveFilters() {
			const t = this
			return [].reduce((acc, curr) => acc + curr, 0)
		},

		/**
		 * Computed Table Data
		 *
		 * Sort and return the table data.
		 *
		 * @returns {array[{}]} an array of objects
		 */
		computedTableData() {
			const t = this
			let tableData = t.documentationData
			const CURRENT_USER_DATA = t.MIX_getCurrentUser()

			tableData = tableData.filter(item => {

				// Staff-Admin (or anyone with the correct Access&Ability) can see all documents
				if (['Staff-Admin'].includes(CURRENT_USER_DATA.userLevel)
					|| t.MIX_getCurrentUser().userAccessAndAbilities.includes('Documentation: View Documents')) return true

				// If the document is uploaded by an Organisation, only users from the same organisation can view it
				if (item.documentUploadedByType === 'Contractor') {
					// Organisation users can see documents from their own organisation
					return CURRENT_USER_DATA.userType === 'Contractor' && item.documentUploadedByOrganisationId === CURRENT_USER_DATA.userOrganisation
				}

				// For documents meant for 'All'
				if (item.documentRecipientType === 'All') return true

				// For documents meant for specific 'Users'
				if (item.documentRecipientType === 'Users' && item.documentRecipientTypeUsers.includes(CURRENT_USER_DATA.entityId)) return true

				// For documents meant for specific 'Levels'
				if (item.documentRecipientType === 'Levels' && item.documentRecipientTypeLevels.includes(CURRENT_USER_DATA.userLevel)) return true

				// For documents meant for specific 'Types'
				if (item.documentRecipientType === 'Types' && item.documentRecipientTypeTypes.includes(CURRENT_USER_DATA.userType)) return true

				// If none of the conditions match, the document is not relevant to the current user
				return false
			})

			// Filter by document name
			if (t.searchByDocumentName) {
				const DOCUMENT_NAME = t.searchByDocumentName.toUpperCase()
				tableData = tableData.filter(item => item.documentName.toUpperCase().includes(DOCUMENT_NAME))
			}

			// Sort by created date
			tableData = tableData.sort((a, b) => a.createdDateTime > b.createdDateTime ? 1 : -1)

			return tableData
		},

	},

	methods: {

		/**
		 * Clear Filters
		 *
		 * Clear all the filters.
		 */
		clearFilters() {
			const t = this

		},

		/**
		 * Close Right Panel
		 *
		 * Close the right panel.
		 */
		closeRightPanel() {
			const t = this

			t.tabs = ''
			t.selectedItem = {}
			t.isRightPanelVisible = false
		},

		/**
		 *
		 * Delete Item
		 *
		 * Delete the selected document by its ID.
		 *
		 * @param itemData {object} the item to delete
		 */
		async deleteItem(itemData) {
			const t = this

			const RESPONSE = await t.MIX_redis_delete('document', itemData.entityId, itemData)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error deleting Document', RESPONSE.error)
				return
			}

			t.$sharedState.successMessage = 'Deleted Document'

			// Reload the data
			await t.loadData()

			// Close the panel
			t.closeRightPanel()
		},

		/**
		 * Edit Item
		 *
		 * Toggle the editability of the form.
		 */
		editItem() {
			const t = this

			t.isReadOnly = !t.isReadOnly
		},

		/**
		 * Get File Data
		 *
		 * Get the file data for the selected item.
		 *
		 * @param selectedItem the selected item
		 * @returns {Promise<void>}
		 */
		async getFileData(selectedItem) {
			const t = this

			const RESPONSE = await t.MIX_redis_getById('file', selectedItem.documentFileId)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error loading File: ', RESPONSE.error)
				return
			}

			const FILE_DATA = RESPONSE.data
			const FILE_URL = t.MIX_getImagePath('documentation', FILE_DATA.fileName, FILE_DATA.fileToken)

			t.selectedItemFileUrl = FILE_URL
		},

		/**
		 * Get User Document Acceptance Status
		 *
		 * Get the user document acceptance status.
		 *
		 * @param item the selected item
		 */
		getUserDocumentAcceptanceStatus(item) {
			const t = this
			let acceptanceStatus

			// If the document does not require acceptance
			if (!item.documentIsAcceptanceRequired) acceptanceStatus = 'Not Required'

			else {

				// Get the user document action data and check if it has been accepted
				const USER_DOCUMENT_ACTION = t.allCurrentUserDocumentActionsData.find(action => action.documentDocumentId === item.entityId)

				// If the documentAction has not been read, it's NEW
				if (!USER_DOCUMENT_ACTION?.documentActionFirstOpenedDateTime) acceptanceStatus = 'New'

				// If the documentAction has been read but not accepted, it's false
				else acceptanceStatus = USER_DOCUMENT_ACTION?.documentActionHasAccepted
			}

			return acceptanceStatus
		},

		/**
		 * Handle Emitted Menu Selection
		 *
		 * Take the emitted selection from the menu, and call the relevant function.
		 *
		 * @param selection a string-based menu option
		 */
		handleEmittedMenuSelection(selection) {
			const t = this

			if (selection === 'New') t.openRightPanel()
		},

		/**
		 * Load Data
		 *
		 * Load all the data required for the page.
		 *
		 * @returns {Promise<void>}
		 */
		async loadData() {
			const t = this

			t.isLoading = true

			await Promise.all([
				t.loadDocumentationData(),
				t.loadUsersData(),
				t.loadAllDocumentActionsForCurrentUser()
			])

			t.isLoading = false
		},

		/**
		 * Load All Document Actions For Current User
		 *
		 * Load all the document actions for the current user.
		 *
		 * @returns {Promise<void>}
		 */
		async loadAllDocumentActionsForCurrentUser() {
			const t = this

			const RESPONSE = await t.MIX_redis_getDocumentActionsWhere([
				{whereKey: 'documentActionUserId', whereValue: t.MIX_getCurrentUser().entityId}
			])

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error loading All Current User\'s Document Actions data: ', RESPONSE.error)
				return
			}

			t.allCurrentUserDocumentActionsData = RESPONSE.data
		},

		/**
		 * Load Documentation Data
		 *
		 * Load all the documentation data.
		 *
		 * @returns {Promise<void>}
		 */
		async loadDocumentationData() {
			const t = this

			const RESPONSE = await t.MIX_redis_getAll('document')

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error loading Documentation: ', RESPONSE.error)
				return
			}

			t.documentationData = RESPONSE.data
		},

		/**
		 * Load User Document Action Data
		 *
		 * Load the User Document Action data for the current user and document.
		 *
		 * @returns {Promise<void>}
		 */
		async loadUserDocumentActionData() {
			const t = this

			const RESPONSE = await t.MIX_redis_getDocumentActionsWhere([
				{whereKey: 'documentActionUserId', whereValue: t.MIX_getCurrentUser().entityId},
				{whereKey: 'documentDocumentId', whereValue: t.selectedItem.entityId}
			])

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error loading User Document Actions: ', RESPONSE.error)
				return
			}

			// Set the user document actions data if it exists
			t.userDocumentActionData = RESPONSE.data.length ? RESPONSE.data[0] : {}
		},

		/**
		 * Load Document Actions Data
		 *
		 * Load all the User Document Actions data for the current document.
		 *
		 * @returns {Promise<void>}
		 */
		async loadDocumentActionsData() {
			const t = this

			const RESPONSE = await t.MIX_redis_getDocumentActionsWhere([
				{whereKey: 'documentDocumentId', whereValue: t.selectedItem.entityId}
			])

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error loading Document Actions: ', RESPONSE.error)
				return
			}

			t.documentActionsData = RESPONSE.data
		},

		/**
		 * Load Users Data
		 *
		 * Load all the User's data.
		 *
		 * @returns {Promise<void>}
		 */
		async loadUsersData() {
			const t = this

			const RESPONSE = await t.MIX_redis_getAll('user')

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error loading Users: ', RESPONSE.error)
				return
			}

			t.usersData = RESPONSE.data
		},

		/**
		 * Open Form
		 *
		 * Open the form dialog.
		 * If no item is passed in, the form will open as new (blank).
		 *
		 * @param item the selected key
		 */
		async openRightPanel(item) {
			const t = this

			if (item?.entityId) {
				t.isReadOnly = true
				t.selectedItem = {...item}
				await t.getFileData(item)
				if (['Staff-Admin', 'Contractor-Manager'].includes(t.MIX_getCurrentUser().userLevel)) await t.loadDocumentActionsData()
				await t.loadUserDocumentActionData()
			} else {
				t.isReadOnly = false
				t.selectedItem = {}
			}

			t.isRightPanelVisible = true
		},

		/**
		 * Reload Page
		 *
		 * Reload the page.
		 */
		reloadPage() {
			const t = this

			t.closeRightPanel()
			t.loadData()
		},

		/**
		 * Toggle Filters Visibility
		 *
		 * Toggle the visibility of the filters panel.
		 */
		toggleFiltersVisibility() {
			const t = this

			t.isFiltersPanelVisible = !t.isFiltersPanelVisible
		},

	},

	async mounted() {
		const t = this

		await t.loadData()
	},

}
</script>

<style scoped>

</style>
