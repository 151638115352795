<template>

	<page-loading-animation v-if="isLoading" :is-loading="isLoading"/>

	<div v-else class="d-flex flex-column justify-space-between primary"
		 :style="$vuetify.breakpoint.width < 600 ? 'min-height: 100vh' : 'min-height: calc(100vh - 64px)'">

		<!--Main Items --------------------------------------------------------------------------------------------- -->
		<div>

			<!--Menu Items-->
			<v-list nav>

				<!--Nav Item-->
				<v-list-item v-for="item in computedMenu" :key="item.name" @click="MIX_go(item.path)"
							 class="sideMenu-item"
							 :class="$route.path === item.path && 'appWhite'"
							 dense
							 link>

					<!--Icon-->
					<app-icon class="mr-4"
							  :color="$route.path === item.path ? 'primary' : 'appWhite'"
							  :icon="item.icon"/>

					<!--Name-->
					<app-text class="mt-1" :color="$route.path === item.path ? 'primary' : 'appWhite'">
						{{ item.name }}
					</app-text>

				</v-list-item>

			</v-list>

		</div>

	</div>

</template>

<script>
import appConfig from '../../../appConfig.json'
export default {

	name: "AppSideNavigation",

	data: () => ({
		isLoading: true,
		menu: [
			{name: 'Home', path: '/', icon: 'home'},
			{name: 'Accreditation', path: '/accreditation', icon: 'accreditation'},
			{name: 'Contractors', path: '/contractors', icon: 'organisation'},
			{name: 'Documentation', path: '/documentation', icon: 'termsConditions'},
			{name: 'Fire Roll Call', path: '/fireRollCall', icon: 'fireRollCall'},
			{name: 'Events', path: '/events', icon: 'events'},
			{name: 'Pre Event Dashboard', path: '/preEventDashboard', icon: 'eventsDashboardPre'},
			{name: 'Event Day Dashboard', path: '/eventDayDashboard', icon: 'eventsDashboardDay'},
			{name: 'Induction', path: '/induction', icon: 'inductions'},
			{name: 'My Accreditation', path: '/myAccreditation', icon: 'accreditation'},
			{name: 'My Contractor', path: '/myContractor', icon: 'organisation'},
			{name: 'Notice Board', path: '/noticeBoard', icon: 'noticeBoard'},
			{name: 'Orientation', path: '/orientation', icon: 'orientation'},
			{name: 'Parking', path: '/parking', icon: 'car'},
			{name: 'Qualifications', path: '/qualifications', icon: 'qualification'},
			{name: 'Reporting', path: '/reporting', icon: 'eye'},
			{name: 'Sites', path: '/sites', icon: 'sites'},
			{name: 'Swapp', path: '/swapp', icon: 'qrCode'},
			{name: 'Teams', path: '/teams', icon: 'teams'},
			{name: 'Users', path: '/users', icon: 'users'},
		],

		// Data
		fullCurrentUserData: {},
	}),

	computed: {

		/**
		 * Computed Menu
		 *
		 * Filter the menu items based on the user's level, and the allowed routes.
		 *
		 * @returns {*|*[]} - The filtered menu items.
		 */
		computedMenu() {
			const t = this
			const CURRENT_USER_LEVEL = t.fullCurrentUserData?.userData?.userLevel

			if (!CURRENT_USER_LEVEL) return []

			// Fetch path strings for the user level
			let ALLOWED_PATHS_FOR_USER_LEVEL = appConfig.userMenus[CURRENT_USER_LEVEL]
			if (!ALLOWED_PATHS_FOR_USER_LEVEL) return []

			// Filter the menu items
			return t.menu.filter(item =>
				ALLOWED_PATHS_FOR_USER_LEVEL.includes(item.path) &&
				appConfig.allowedRoutes.some(route => route.path === item.path) &&
				(t.hasAccess(item) || item.name === 'Home') // Ensure Home is always accessible
			)
		},

	},

	methods: {

		/**
		 * Has Access
		 *
		 * Check if the user has access to a specific menu item via Access & Abilities.
		 *
		 * @param item - The menu item to check.
		 * @returns {boolean} - If the user has access.
		 */
		hasAccess(item) {
			const t = this

			const ACCESS_AND_ABILITIES = t.fullCurrentUserData?.userData?.userAccessAndAbilities || []

			if (item.name === 'Fire Roll Call') return ACCESS_AND_ABILITIES.includes('Fire Roll Call')

			return true
		},

		/**
		 * Load Data
		 *
		 * Load all the data required for the page.
		 *
		 * @returns {Promise<void>}
		 */
		async loadData() {
			const t = this

			t.isLoading = true

			await Promise.all([
				t.loadFullCurrentUserData(),
			])

			t.isLoading = false
		},

		/**
		 * Load Full Current User Data
		 *
		 * Load the current user's full data.
		 *  - User data
		 *  - File data (profile picture)
		 *
		 * @returns {Promise<void>}
		 */
		async loadFullCurrentUserData() {
			const t = this
			const CURRENT_USER_AUTH = t.currentUserAuth

			const RESPONSE = await t.MIX_redis_getFullUserData(CURRENT_USER_AUTH.uid)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error Loading Current User data: ', RESPONSE.errors)
				return
			}

			t.fullCurrentUserData = RESPONSE.data
		}

	},

	async created() {
		const t = this

		await t.loadData()
	}

}
</script>

<style scoped>
.sideMenu-item {
	transition: 0.25s;
}

.sideMenu-item:hover {
	background: #222222;
}
</style>
