<template>
	<div class="">

		<v-toolbar class="rounded-lg" color="primary">
			<app-text color="white" size="medium">Cancel Fire Roll Call</app-text>
		</v-toolbar>

		<!--Content-->
		<div class="appWhite pa-4">

			<app-text>You are about to cancel this Fire Roll Call, all changes will be lost.</app-text>

			<app-text class="mt-4" size="normal-bold">Are you sure you want to do this?</app-text>

			<!--Action Buttons-->
			<div class="d-flex align-center justify-space-between mt-4">

				<!--No button-->
				<app-btn @click.native="closeDialog" color="grey"
						 icon="cancel"
						 label="No"/>

				<!--Yes button-->
				<app-btn @click.native="handleCancelButton"
						 color="green"
						 icon="success"
						 label="Yes"/>

			</div>

		</div>

	</div>
</template>

<script>

export default {

	name: "FireRollCallCancelDialog",

	data: () => ({}),

	methods: {

		/**
		 * Close Dialog
		 *
		 * Emits an event to close the dialog
		 */
		closeDialog() {
			const t = this

			t.$emit('closeDialog')
		},

		/**
		 * Handle Cancel Button
		 *
		 * Emits an event to cancel the Fire Roll Call
		 */
		handleCancelButton() {
			const t = this

			t.$emit('cancelFireRollCall')
		}

	},

}
</script>

<style scoped>

</style>
