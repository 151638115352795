<template>
	<div>

		<!--Header-->
		<div class="d-flex flex-column">

			<div class="d-flex">

				<page-title icon="inductions"
							title="Induction"/>

				<v-spacer/>

				<!--View Type Button-->
				<app-btn v-if="['Staff-Admin'].includes(MIX_getCurrentUser().userLevel)"
						 @click.native="handleViewTypeSelection"
						 :label="computedViewTypeButton"/>

			</div>

			<!--Page Description-->
			<app-text class="mt-4" color="grey9" size="small">
				Successfully answer the questions to complete your induction. This must be completed before commencing
				work.
			</app-text>

		</div>

		<v-divider class="mt-4"/>

		<induction-questions v-if="viewType === 'Questions'"/>

		<induction-management v-if="viewType === 'Management'"/>

	</div>

</template>

<script>

import InductionQuestions from "@/views/induction/inductionQuestions/InductionQuestions.vue";
import InductionManagement from "@/views/induction/inductionManagement/InductionManagement.vue";

export default {

	name: "Induction",

	components: {InductionManagement, InductionQuestions},

	data: () => ({
		viewType: 'Questions',
	}),

	computed: {

		computedViewTypeButton() {
			const t = this
			let label = ''

			if (t.$vuetify.breakpoint.width < 600) {
				label = t.viewType === 'Questions' ? 'Questions' : 'Management'
			} else {
				label = t.viewType === 'Questions' ? 'Manage Questions' : 'View Questions'
			}

			return label
		},

	},

	methods: {

		handleViewTypeSelection() {
			const t = this

			t.viewType = t.viewType === 'Questions' ? 'Management' : 'Questions'
		},

	},

	async mounted() {
	},

}
</script>

<style scoped>
</style>
