<template>
	<div class="appGrey pa-4">

		{{ computedInit }}

		<!--Instructions-->
		<app-text class="mb-4">
			Complete the form to Sign In <strong>{{ selectedItem.userData.userName }}</strong>,
			this will also SWAPP them in if they haven't already done so.
		</app-text>

		<!--Instructions - Already Signed In-->
		<app-text v-if="selectedItem[`${viewType}Data`][`${viewType}SignInTime`] !== '0'" class="mb-4" color="red">
			<strong>{{ selectedItem.userData.userName }}</strong>
			was already Signed In at
			<strong>{{ selectedItem[`${viewType}Data`][`${viewType}SignInTime`] }}</strong>.
			Continuing with this will override their Sign In time.
		</app-text>

		<!--Time Selection-->
		<time-picker @emitTime="handleTimeSelection($event, 'signInTime')"
					 :error="errors.signInTime"
					 :error-message="errors.signInTimeErrorMessage"
					 label="Sign In time"
					 :time="signInTime"/>

		<v-divider class="mt-4"/>

		<!--Action Buttons-->
		<div class="d-flex justify-space-between mt-4">

			<!--Cancel-->
			<app-btn @click.native="closeDialog"
					 color="grey"
					 icon="cancel"
					 label="Cancel"/>

			<!--Yes-->
			<app-btn @click.native="handleYesButton"
					 color="green"
					 icon="success"
					 label="Sign In"/>

		</div>

	</div>
</template>

<script>
export default {

	name: "EventControlPanelSignDialog",

	props: ['selectedItem', 'viewType'],

	data: () => ({
		errors: {
			signInTime: false,
			signInTimeErrorMessage: '',
		},
		signInTime: '',
	}),

	computed: {

		/**
		 * Computed Init
		 *
		 * Initialise the component.
		 */
		computedInit() {
			const t = this
			const VIEW_TYPE = t.$props.viewType
			const SIGN_IN_TIME = t.$props.selectedItem[`${VIEW_TYPE}Data`][`${VIEW_TYPE}LateArrivalTime`]

			t.signInTime = SIGN_IN_TIME === '0' ? '' : SIGN_IN_TIME
		},

	},

	methods: {

		/**
		 * Clear Errors
		 *
		 * Clear all errors and their messages.
		 */
		clearErrors() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		/**
		 * Close Dialog
		 *
		 * Emit back to the parent to close the dialog box.
		 */
		closeDialog() {
			const t = this

			t.$emit('emitCloseEventControlPanelSignDialog')
		},

		/**
		 * Handle Time Selection
		 *
		 * Take the time from the picker and store it.
		 *
		 * @param time the time string from the picker
		 * @param field the form field to set
		 */
		handleTimeSelection(time, field) {
			const t = this

			// Set the time to the current form field
			t[field] = time
		},

		/**
		 * Handle Yes Button
		 *
		 * Emit back to the parent to update the EventUser document's time.
		 */
		handleYesButton() {
			const t = this

			// Only continue if the form is valid
			if (!t.validateForm()) return

			t.$emit('emitEventControlPanelSign', t.signInTime)
		},

		/**
		 * Validate Form
		 *
		 * Validate the form and return true or false if it is valid, or not.
		 *
		 * @returns {boolean}
		 */
		validateForm() {
			const t = this

			t.clearErrors()

			if (!t.signInTime) {
				t.errors.signInTime = true
				t.errors.signInTimeErrorMessage = 'Please select a time'
			}

			return !Object.values(t.errors).includes(true)
		}

	},

}
</script>

<style scoped>

</style>
