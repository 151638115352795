<template>
	<div class="eventAvailabilityCard-container appWhite">

		<!--Event Details | Shift Details | Info Icon |  Action Buttons -->
		<div class="d-flex">

			<!--Event Date/Time-->
			<div class="eventAvailabilityCard-dateTime-container">

				<!--Date-->
				<div class="eventAvailabilityCard-dateTime-date">
					<app-text>{{ computedDate.dayName }}</app-text>
					<app-text size="normal-bold">{{ computedDate.dayNumber }}</app-text>
					<app-text>{{ computedDate.monthName }}</app-text>
				</div>

				<!--KO Time-->
				<app-text class="eventAvailabilityCard-dateTime-KO text-center" color="greyD" size="small">
					KO @ {{ fullEventData.eventData.eventStartTime }}
				</app-text>

			</div>

			<!--Event Shift Details | Info Icon | Action Buttons-->
			<div class="eventAvailabilityCard-shiftDetails-container">

				<!--Event Shift Details | Info Icon-->
				<div class="d-flex align-center">

					<!--Event Name | Shift Details-->
					<div>

						<!--Event Name-->
						<app-text size="normal-bold">{{ fullEventData.eventData.eventName }}</app-text>

						<!--Shift Details-->
						<app-text class="mt-2" color="grey" size="small">
							Start at
							<strong>{{ computedShiftDetails.startTime }}</strong>
							for a
							<strong>{{ computedShiftDetails.shiftLength }}</strong>
							hour shift
						</app-text>

					</div>

					<v-spacer/>

					<!--Info Icon-->
					<app-icon @click.native="openStaffInstructionsDialog" icon="info" size="48"/>

				</div>

				<!--Action Buttons-->
				<div class="d-flex mt-4">

					<!--No-->
					<app-btn @click.native="handleAvailabilitySelection('Unavailable')"
							 :block="true"
							 class="pr-2"
							 :color="fullEventData?.eventUserData?.eventUserStatus === 'Unavailable' ? 'grey3' : 'grey'"
							 :disabled="disabled"
							 label="No"
							 style="width: 100%"/>

					<!--Yes-->
					<app-btn @click.native="handleAvailabilitySelection('Available')"
							 :block="true"
							 class="pl-2"
							 :color="['Available', 'Confirmed', 'Reserved', 'Not Required'].includes(fullEventData?.eventUserData?.eventUserStatus) ? 'green' : 'grey'"
							 :disabled="disabled"
							 label="Yes"
							 style="width: 100%"/>

				</div>

			</div>

		</div>

		<!--See Team Availability Button - Supervisor Only-->
		<app-btn v-if="!disabled && ['Steward-Supervisor', 'Steward-Deputy'].includes(MIX_getCurrentUser().userLevel)"
				 @click.native="$emit('emitShowTeamAvailability', {eventData: fullEventData.eventData, eventsView: 'Status Selection'})"
				 :block="true" color="primary mt-4"
				 :disabled="disabled"
				 icon="teams"
				 label="See Team Availability"/>

		<!--Status Information-->
		<div v-if="computedStatusInformation.statusTitle" class="text-center mt-4">

			<app-text :color="computedStatusInformation.statusColor" size="normal-bold">{{ computedStatusInformation.statusTitle }}</app-text>
			<app-text size="small">{{ computedStatusInformation.statusInformation }}</app-text>

		</div>

		<!--Dialogs ------------------------------------------------------------------------------------------------ -->

		<!--Staff Information Dialog-->
		<app-dialog :is-visible="isStaffInstructionsDialogVisible">
			<event-availability-card-staff-instructions-dialog
				@emitCloseEventAvailabilityCardStaffInstructionsDialog="emittedCloseEventAvailabilityCardStaffInstructionsDialog"
				:eventData="fullEventData.eventData"/>
		</app-dialog>

		<!--Status Selection Confirmation Dialog-->
		<app-dialog :is-visible="isStatusSelectionConfirmationDialogVisible" dialogTitle="Update Status">
			<event-availability-card-status-selection-confirmation-dialog
				@emitCloseEventAvailabilityCardStatusSelectionConfirmationDialog="emittedCloseEventAvailabilityCardStatusSelectionConfirmationDialog"
				@emitEventAvailabilityCardStatusSelectionConfirmation="emittedEventAvailabilityCardStatusSelectionConfirmation"
				:eventUserData="fullEventData.eventUserData"
				:statusSelection="statusSelection"/>
		</app-dialog>

	</div>
</template>

<script>
import EventAvailabilityCardStatusSelectionConfirmationDialog
	from "@/views/events/steward/eventAvailabilityCard/eventAvailabilityCardStatusSelectionConfirmationDialog/EventAvailabilityCardStatusSelectionConfirmationDialog";
import EventAvailabilityCardStaffInstructionsDialog
	from "@/views/events/steward/eventAvailabilityCard/eventAvailabilityCardStaffInstructionsDialog/EventAvailabilityCardStaffInstructionsDialog";

export default {

	name: "EventAvailabilityCard",

	components: {
		EventAvailabilityCardStaffInstructionsDialog, EventAvailabilityCardStatusSelectionConfirmationDialog},

	props: ['disabled', 'fullEventData'],

	data: () => ({
		isStaffInstructionsDialogVisible: false,
		isStatusSelectionConfirmationDialogVisible: false,
		statusSelection: '',
	}),

	computed: {

		/**
		 * Computed Date
		 *
		 * Return the date in fragments for the DateTime box.
		 *
		 * @returns {{dayName: string, dayNumber: number, monthName: string}} fragments of the date
		 */
		computedDate() {
			const t = this
			const EVENT_DATE = t.$props.fullEventData.eventData.eventDate

			let dayName = new Date(EVENT_DATE).toLocaleDateString('en-GB', {weekday: 'short'})
			let dayNumber = new Date(EVENT_DATE).getDate()
			let monthName = new Date(EVENT_DATE).toLocaleDateString('en-GB', {month: 'short'})

			return {dayName, dayNumber, monthName}
		},

		/**
		 * Computed Shift Details
		 *
		 * Return the Shift Start Time and Length for the event by userLevel.
		 *
		 * @returns {{startTime: string, shiftLength: string}} shift details
		 */
		computedShiftDetails() {
			const t = this
			const CURRENT_USER_DATA = t.MIX_getCurrentUser()
			const FULL_EVENT_DATA = t.$props.fullEventData
			let startTime
			let shiftLength

			// Supervisor & Deputy
			if (['Steward-Supervisor', 'Steward-Deputy'].includes(CURRENT_USER_DATA.userLevel)) {
				startTime = FULL_EVENT_DATA.eventTeamData?.eventTeamSupervisorsStartTime || FULL_EVENT_DATA.eventData.eventSupervisorsStartTime
				shiftLength = FULL_EVENT_DATA.eventTeamData?.eventTeamSupervisorsShiftLength || FULL_EVENT_DATA.eventData.eventSupervisorsShiftLength
			}

			// Steward
			if (['Steward-User'].includes(CURRENT_USER_DATA.userLevel)) {
				startTime = FULL_EVENT_DATA.eventTeamData?.eventTeamStewardsStartTime || FULL_EVENT_DATA.eventData.eventStewardsStartTime
				shiftLength = FULL_EVENT_DATA.eventTeamData?.eventTeamStewardsShiftLength || FULL_EVENT_DATA.eventData.eventStewardsShiftLength
			}

			return {startTime, shiftLength}
		},

		/**
		 * Computed Status Information
		 *
		 * Create and return the required status information for rendering.
		 *
		 * @returns {{statusInformation: string, statusColor: string, statusTitle: string}}
		 */
		computedStatusInformation() {
			const t = this
			const EVENT_USER_STATUS = t.$props.fullEventData?.eventUserData?.eventUserStatus
			let statusColor
			let statusTitle
			let statusInformation

			if (EVENT_USER_STATUS === 'Available') {
				statusColor = 'orange'
				statusInformation = 'You will be notified if you are required.'
				statusTitle = 'Available'
			}

			if (EVENT_USER_STATUS === 'Confirmed') {
				statusColor = 'green'
				statusInformation = `When you arrive at the Stadium on event day, you must SWAPP in with a QR Code on the gate, and then see your Supervisor to get Signed In.`
				statusTitle = 'Confirmed'
			}

			if (EVENT_USER_STATUS === 'Reserved') {
				statusColor = 'blue'
				statusInformation = 'You are on the Reserve list and will be notified if you are required.'
				statusTitle = 'Reserved'
			}

			if (EVENT_USER_STATUS === 'Not Required') {
				statusColor = 'red'
				statusInformation = 'You are not required for this Event, but will be notified if anything changes.'
				statusTitle = 'Not Required'
			}

			return {statusColor, statusInformation, statusTitle}
		},

	},

	methods: {

		/**
		 * Close Staff Instructions Dialog
		 *
		 * Close the dialog box.
		 */
		closeStaffInstructionsDialog() {
			const t = this

			t.isStaffInstructionsDialogVisible = false
		},

		/**
		 * Close Status Selection Confirmation Dialog
		 *
		 * Close the dialog box.
		 */
		closeStatusSelectionConfirmationDialog() {
			const t = this

			t.isStatusSelectionConfirmationDialogVisible = false
		},

		/**
		 * Emitted Close Event Availability Card Staff Instructions Dialog
		 *
		 * Call to close the dialog box.
		 */
		emittedCloseEventAvailabilityCardStaffInstructionsDialog() {
			const t = this

			t.closeStaffInstructionsDialog()
		},

		/**
		 * Emitted Close Event Availability Card Status Selection Confirmation Dialog
		 *
		 * Call to close the dialog.
		 */
		emittedCloseEventAvailabilityCardStatusSelectionConfirmationDialog() {
			const t = this

			t.closeStatusSelectionConfirmationDialog()
		},

		/**
		 * Emitted Event Availability Card Status Selection Confirmation
		 *
		 * Call to update the User's EventUser status, and close the dialog.
		 */
		emittedEventAvailabilityCardStatusSelectionConfirmation() {
			const t = this

			t.updateAvailability()
			t.closeStatusSelectionConfirmationDialog()
		},

		/**
		 * Handle Availability Selection
		 *
		 * Handle the button selection by setting the status to the state and opening the confirmation dialog.
		 *
		 * @param statusSelection - the selected status
		 */
		handleAvailabilitySelection(statusSelection) {
			const t = this

			if (t.$props.disabled) return

			t.statusSelection = statusSelection
			t.isStatusSelectionConfirmationDialogVisible = true
		},

		/**
		 * Open Staff Instructions Dialog
		 *
		 * Open the dialog box.
		 */
		openStaffInstructionsDialog() {
			const t = this

			t.isStaffInstructionsDialogVisible = true
		},

		/**
		 * Update Availability
		 *
		 * Update the current User's EventUser status for this Event.
		 *
		 * @returns {Promise<void>}
		 */
		async updateAvailability() {
			const t = this
			const EVENT_USER_DATA = t.$props.fullEventData?.eventUserData
			const STATUS_SELECTION = t.statusSelection

			EVENT_USER_DATA.eventUserStatus = STATUS_SELECTION

			const RESPONSE = await t.MIX_redis_update('eventUser', EVENT_USER_DATA.entityId, EVENT_USER_DATA)

			// Handle and errors
			if (RESPONSE.hasErrors) {
				console.error('Error updating EventUser: ', RESPONSE.error)
				return
			}

		},

	},

}
</script>

<style scoped>
.eventAvailabilityCard-container {
	border-radius: 16px;
	margin-top: 16px;
	max-width: 600px;
	padding: 16px;
}

.eventAvailabilityCard-dateTime-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;

	border-radius: 8px;
	width: 128px;
}

.eventAvailabilityCard-dateTime-date {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;

	background-color: var(--v-greyD-base);
	border-radius: 8px 8px 0 0;
	padding: 8px;
	width: 100%;
}

.eventAvailabilityCard-dateTime-KO {
	background-color: var(--v-grey3-base);
	border-radius: 0 0 8px 8px;
	padding: 8px;
	width: 100%;
}

.eventAvailabilityCard-shiftDetails-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	margin-left: 16px;
	width: 100%;
}
</style>
