<template>

	<page-loading-animation v-if="isLoading" :is-loading="isLoading"/>

	<!--Page Content-->
	<div v-else>

		<!--Header-->
		<page-title :divider="true"
					icon="organisation"
					info="View and administer your Contractor profile and Users."
					title="My Contractor"/>

		<!--Action Bar-->
		<div class="d-flex align-center mt-4">

			<!--Search-->
			<app-form-field form-type="textInput"
							append-icon="icons8-search"
							class="mr-4"
							:clearable="true"
							label="User Name"
							style="width: 100%"
							v-model.trim="searchByUserName"/>

			<!--Add Button-->
			<!--<app-btn v-if="MIX_getCurrentUser().userLevel === 'Staff-Admin'"-->
			<!--		 @click.native="openRightPanel" class="mr-4" icon="add" label="Add"/>-->

			<!--Filter Button - with a numbered badge-->
			<div style="position:relative;">
				<app-btn @click.native="toggleFiltersVisibility"
						 class="mr-4" color="appWhite" icon="filter" icon-color="primary"/>
				<span v-if="computedNumberOfActiveFilters > 0" class="badge">{{ computedNumberOfActiveFilters }}</span>
			</div>

			<!--More Actions Menu-->
			<more-actions-menu @emitMenuSelection="handleEmittedMenuSelection" :menuList="moreActionsMenuOption"/>

		</div>

		<!--Clear Filters-->
		<div v-if="computedNumberOfActiveFilters > 0" class="d-flex justify-end mt-4">
			<app-btn @click.native="clearFilters" icon="close" label="Clear Filters"/>
		</div>

		<!--Table-->
		<v-data-table v-if="$vuetify.breakpoint.width >= 600"
					  class="appWhite rounded-lg mt-4"
					  :headers="computedHeaders"
					  :items="computedTableData">

			<!--ID-->
			<template v-slot:item.entityId="{item}">
				<app-text size="small">{{ item.userData.entityId }}</app-text>
			</template>

			<!--Avatar-->
			<template v-slot:item.userProfilePicture="{item}">
				<avatar :file="item.fileData" size="32" :user="item.userData"/>
			</template>

			<!--UserName-->
			<template v-slot:item.userName="{item}">
				<app-text size="small">{{ item.userData.userName }}</app-text>
				<!--<app-text color="grey9" size="small">{{ fullOrganisationData.organisationData.organisationName }}</app-text>-->
				<app-text v-if="item.userData.userLevel === 'Contractor-Manager'" color="primary" size="small">
					Manager
				</app-text>
			</template>

			<!--User Position-->
			<template v-slot:item.userPosition="{item}">
				<app-text size="small">{{ item.userData.userPosition }}</app-text>
			</template>

			<!--Action Button-->
			<template v-slot:item.action="{item}">
				<app-icon @click.native="handleOpenRightPanel('User', item)"
						  class="cursorPointer" color="primary" icon="arrowForward"/>
			</template>

		</v-data-table>

		<!--Mobile Cards-->
		<div v-if="$vuetify.breakpoint.width < 600">

			<!--No Organisations Message-->
			<app-text v-if="!computedTableData.length" class="text-center mt-4" color="grey9">
				You have no Users to view
			</app-text>

			<my-organisation-mobile-card v-for="item in computedTableData" :key="item.userData.entityId"
										 @click.native="handleOpenRightPanel('User', item)"
										 class="mt-4"
										 :cardData="item"
										 :readOnly="isReadOnly"/>

		</div>

		<!--Filters Panel ------------------------------------------------------------------------------------------ -->
		<filter-panel :is-filters-panel-visible="isFiltersPanelVisible" @toggle="toggleFiltersVisibility">
			<div class="pa-4">

				<!--User Role-->
				<page-break-title title="Role"/>
				<div class="mt-4">
					<app-form-field form-type="autoComplete"
									:deletable-chips="true"
									:items="['Manager', 'User']"
									label="Roles"
									:multiple="true"
									:small-chips="true"
									v-model.trim="filterByRoles"/>
					</div>

				<!--Position-->
				<page-break-title class="mt-8" title="Position"/>
				<div class="mt-4">
					<app-form-field form-type="textInput"
									label="Position"
									v-model.trim="filterByPosition"/>
				</div>

			</div>
		</filter-panel>

		<!--Right Side ----------------------------------------------------------------------------------- Right Side-->
		<transition enter-active-class="animate__animated animate__fadeInRight animate__faster"
					leave-active-class="animate__animated animate__fadeOutRight animate__faster"
					mode="out-in">

			<div v-if="isRightPanelVisible"
				 class="appGrey formShadow"
				 style="position:absolute; top:0; bottom: 0; right: 0; overflow: auto"
				 :style="$vuetify.breakpoint.width < 600 ? 'width: 100%' : 'width: 50%'"
				 v-model="isRightPanelVisible">

				<!--Header - Organisation-->
				<div v-if="activeForm === 'Organisation'">

					<!--Header-->
					<div class="d-flex align-center primary pa-4">
						<app-text color="appWhite" size="normal">
							{{ selectedItem?.organisationData?.organisationName || 'New' }}
						</app-text>
						<v-spacer/>
						<app-icon @click.native="closeRightPanel" class="cursorPointer" color="white" icon="close"
								  size="32"/>
					</div>

					<!--Action Bar-->
					<div class="d-flex align-center pa-4">

						<v-spacer/>

						<edit-icon @click.native="editItem" :isActive="!isReadOnly"/>
						<delete-icon v-if="selectedItem?.organisationData?.entityId"
									 @emitDeleteItem="deleteItem(selectedItem?.organisationData)"
									 itemName="organisationName"
									 :selectedItem="selectedItem?.organisationData"/>

					</div>
				</div>

				<!--Header - User-->
				<div v-if="activeForm === 'User'">

					<!--Header-->
					<div class="d-flex align-center primary pa-4">
						<app-text color="appWhite" size="normal">
							{{ selectedItem?.userData?.userName || 'New' }}
						</app-text>
						<v-spacer/>
						<app-icon @click.native="closeRightPanel" class="cursorPointer" color="white" icon="close"
								  size="32"/>
					</div>

					<!--Action Bar-->
					<div class="d-flex align-center pa-4">

						<v-spacer/>

						<edit-icon @click.native="editItem" :isActive="!isReadOnly"/>
						<delete-icon v-if="selectedItem?.userData?.entityId"
									 @emitDeleteItem="deleteItem(selectedItem?.userData)"
									 itemName="userName"
									 :selectedItem="selectedItem?.userData"/>

					</div>
				</div>

				<!--Header - Bulk-->
				<div v-if="activeForm === 'Bulk'">

					<!--Header-->
					<div class="d-flex align-center primary pa-4">
						<app-text color="appWhite" size="normal">Bulk Upload</app-text>
						<v-spacer/>
						<app-icon @click.native="closeRightPanel" class="cursorPointer" color="white" icon="close"
								  size="32"/>
					</div>

					<!--Action Bar-->
					<div class="d-flex align-center pa-4">

						<v-spacer/>

						<edit-icon @click.native="editItem" :isActive="!isReadOnly"/>
						<delete-icon v-if="selectedItem?.organisationData?.entityId"
									 @emitDeleteItem="deleteItem(selectedItem?.organisationData)"
									 itemName="organisationName"
									 :selectedItem="selectedItem?.organisationData"/>

					</div>
				</div>

				<!--Tabs-->
				<v-tabs v-model="tabs">

					<!--Organisation-->
					<v-tab v-if="activeForm === 'Organisation'" href="#organisation">
						<app-text size="small">Contractor</app-text>
					</v-tab>

					<!--User-->
					<v-tab v-if="activeForm === 'User'" href="#user">
						<app-text size="small">User</app-text>
					</v-tab>

					<!--Bulk-->
					<v-tab v-if="activeForm === 'Bulk'" href="#bulk">
						<app-text size="small">Bulk</app-text>
					</v-tab>

				</v-tabs>

				<!--Tabs Content-->
				<v-tabs-items class="appGrey" v-model="tabs">

					<!--Organisation-->
					<v-tab-item v-if="activeForm === 'Organisation'" value="organisation">
						<organisation-form @emitReloadPage="emittedReloadPage"
										   class="pa-4"
										   :formData="selectedItem"
										   :isReadOnly="isReadOnly"/>
					</v-tab-item>

					<!--User-->
					<v-tab-item v-if="activeForm === 'User'" value="user">
						<organisation-user-form @emitReloadPage="emittedReloadPage"
												class="pa-4"
												:formData="selectedItem"
												:isReadOnly="isReadOnly"/>
					</v-tab-item>

					<!--Bulk-->
					<v-tab-item v-if="activeForm === 'Bulk'" value="bulk">
						<organisation-bulk-user-form @emitReloadPage="emittedReloadPage"
													 class="pa-4"
													 :formData="selectedItem"
													 :isReadOnly="isReadOnly"/>
					</v-tab-item>

				</v-tabs-items>

			</div>

		</transition>

	</div>

</template>

<script>
import OrganisationForm from "@/views/organisations/components/organisationForm/OrganisationForm.vue";
import OrganisationMobileCard from "@/views/organisations/components/organisationMobileCard/OrganisationMobileCard.vue";
import OrganisationUserForm from "@/views/organisations/components/organisationUserForm/OrganisationUserForm.vue";
import MyOrganisationMobileCard
	from "@/views/organisations/components/myOrganisationMobileCard/MyOrganisationMobileCard.vue";
import OrganisationBulkUserForm
	from "@/views/organisations/components/organisationBulkUserForm/OrganisationBulkUserForm.vue";

export default {

	name: "MyOrganisation",

	components: {
		OrganisationBulkUserForm,
		MyOrganisationMobileCard,
		OrganisationUserForm,
		OrganisationMobileCard,
		OrganisationForm
	},

	data: () => ({
		activeForm: '',
		filterByRoles: [],
		filterByPosition: '',
		isFiltersPanelVisible: false,
		isRightPanelVisible: false,
		isLoading: true,
		isReadOnly: false,
		moreActionsMenuOption: [
			{name: 'Add User', icon: 'add'},
			{name: 'Bulk Add Users', icon: 'add'},
			{name: 'My Contractor', icon: 'organisation'},
		],
		searchByUserName: '',
		searchByUserPosition: '',
		selectedItem: {},
		tableHeaders: [
			{text: 'ID', value: 'entityId', align: 'left', sortable: false, hidden: true},
			{text: '', value: 'userProfilePicture', align: 'center', sortable: false, width: '48px'},
			{text: 'Name', value: 'userName', align: 'left', sortable: false},
			{text: 'Position', value: 'userPosition', align: 'left', sortable: false},
			{text: '', value: 'action', align: 'center', sortable: false, width: '48px'},
		],
		tabs: '',

		// Data
		fullOrganisationData: [],
	}),

	computed: {

		/**
		 * Computed Headers
		 *
		 * Remove hidden headers and only show the actions column to those with access.
		 *
		 * @returns an array of header objects
		 */
		computedHeaders() {
			const t = this
			let headers = t.tableHeaders

			// Remove hidden headers
			headers = headers.filter(h => !h.hidden)

			return headers
		},

		/**
		 * Computed Number of Active Filters
		 *
		 * Count the number of active filters to display in the filters button badge.
		 *
		 * @returns {*}
		 */
		computedNumberOfActiveFilters() {
			const t = this
			return [
				t.filterByRoles.length,
				t.filterByPosition ? 1 : 0,
			].reduce((acc, curr) => acc + curr, 0)
		},

		/**
		 * Computed Table Data
		 *
		 * Sort and return the table data.
		 *
		 * @returns {array[{}]} an array of objects
		 */
		computedTableData() {
			const t = this
			let tableData = t.fullOrganisationData.usersData

			// Search by UserName
			if (t.searchByUserName) {
				const SEARCH_CRITERIA = t.searchByUserName.toUpperCase()
				tableData = tableData.filter(item => {
					const USER_NAME = item.userData.userName.toUpperCase()
					return USER_NAME.includes(SEARCH_CRITERIA)
				})
			}

			// Filter by Role
			if (t.filterByRoles.length) tableData = tableData.filter(item => t.filterByRoles.includes(item.userData.userRole))

			// Filter by Position
			if (t.filterByPosition) {
				const SEARCH_CRITERIA = t.filterByPosition.toUpperCase()
				tableData = tableData.filter(item => {
					const USER_POSITION = item.userData.userPosition.toUpperCase()
					return USER_POSITION.includes(SEARCH_CRITERIA)
				})
			}

			// Sort by UserName
			tableData = tableData.sort((a, b) => (a.userData.userName > b.userData.userName) ? 1 : -1)

			return tableData
		},

	},

	methods: {

		/**
		 * Clear Filters
		 *
		 * Clear all the filters.
		 */
		clearFilters() {
			const t = this

			t.filterByRoles = []
			t.filterByPosition = ''
		},

		/**
		 * Close Right Panel
		 *
		 * Close the right panel.
		 */
		closeRightPanel() {
			const t = this

			t.activeForm = ''
			t.tabs = ''
			t.selectedItem = {}
			t.isRightPanelVisible = false
		},

		/**
		 *
		 * Delete Item
		 *
		 * Delete the selected document by its ID.
		 *
		 * @param itemData {object} the item to delete
		 */
		async deleteItem(itemData) {
			const t = this

			// Stringify the default zones as they are stored as a string in the DB
			// itemData.organisationDefaultZones = JSON.stringify(itemData.organisationDefaultZones)

			const RESPONSE = await t.MIX_redis_delete('user', itemData.entityId, itemData)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error deleting User: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem deleting this User, please try again.'
				return
			}

			t.$sharedState.successMessage = 'Deleted Organisation'

			// Reload the data
			await t.loadData()

			// Close the panel
			t.closeRightPanel()
		},

		/**
		 * Edit Item
		 *
		 * Toggle the editability of the form.
		 */
		editItem() {
			const t = this

			t.isReadOnly = !t.isReadOnly
		},

		/**
		 * Emitted Reload Data
		 *
		 * Reload the data to refresh.
		 */
		emittedReloadData() {
			const t = this

			t.loadData()
		},

		/**
		 * Emitted Reload Page
		 *
		 * Close the panel and Reload the data to refresh the page.
		 */
		emittedReloadPage() {
			const t = this

			t.closeRightPanel()
			t.loadData()
		},

		/**
		 * Handle Emitted Menu Selection
		 *
		 * Take the emitted selection from the menu, and call the relevant function.
		 *
		 * @param selection a string-based menu option
		 */
		handleEmittedMenuSelection(selection) {
			const t = this

			if (selection === 'Add User') t.handleOpenRightPanel('User', t.fullOrganisationData)

			if (selection === 'Bulk Add Users') t.handleOpenRightPanel('Bulk', t.fullOrganisationData)

			if (selection === 'My Contractor') t.handleOpenRightPanel('Organisation', t.fullOrganisationData)

		},

		/**
		 * Handle Open Right Panel
		 *
		 * Open the right panel and set the active form.
		 *
		 * @param activeForm {string} the form to open
		 * @param itemData {object} the item data to pass to the form
		 */
		handleOpenRightPanel(activeForm, itemData) {
			const t = this

			t.activeForm = activeForm

			if (activeForm === 'Organisation') {
				if (itemData?.organisationData?.entityId) {
					t.isReadOnly = true
					t.selectedItem = itemData
				} else {
					t.isReadOnly = false
					t.selectedItem = {}
				}
			}

			if (activeForm === 'User') {
				if (itemData?.userData?.entityId) {
					t.isReadOnly = true
					t.selectedItem = {...itemData, organisationData: t.fullOrganisationData.organisationData}
				} else {
					t.isReadOnly = false
					t.selectedItem = {
						fileData: {},
						userData: {},
						organisationData: t.fullOrganisationData.organisationData
					}
				}
			}

			if (activeForm === 'Bulk') {
				t.tabs = 'bulk'
				t.selectedItem = {
					fileData: {},
					userData: {},
					organisationData: t.fullOrganisationData.organisationData
				}

			}

			t.openRightPanel()
		},

		/**
		 * Load Data
		 *
		 * Load all the data required for the page.
		 *
		 * @returns {Promise<void>}
		 */
		async loadData() {
			const t = this

			t.isLoading = true

			await Promise.all([
				t.loadFullSingleOrganisationData(),
			])

			t.isLoading = false
		},

		/**
		 * Load Full Single Organisation Data
		 *
		 * Load all the data required for a Single Organisation.
		 *  - Organisation Data
		 *  - Users Data
		 *  -- User Data
		 *  -- File Data
		 *
		 * @returns {Promise<void>}
		 */
		async loadFullSingleOrganisationData() {
			const t = this
			const CURRENT_USER_DATA = t.MIX_getCurrentUser()

			// Fetch the data
			const RESPONSE = await t.MIX_redis_getFullSingleOrganisationData(CURRENT_USER_DATA.userOrganisation)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Full Single Organisation data: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'The was a problem getting the Organisation, please try again.'
				return
			}

			// Assign the data to the state
			t.fullOrganisationData = RESPONSE.data
		},

		/**
		 * Open Form
		 *
		 * Open the form dialog.
		 */
		openRightPanel() {
			const t = this

			t.isRightPanelVisible = true
		},

		/**
		 * Toggle Filters Visibility
		 *
		 * Toggle the visibility of the filters panel.
		 */
		toggleFiltersVisibility() {
			const t = this

			t.isFiltersPanelVisible = !t.isFiltersPanelVisible
		},

	},

	async mounted() {
		const t = this

		await t.loadData()
	},

}
</script>

<style scoped>
</style>
