<template>
	<div class="appGrey pa-4">

		{{ computedInit }}

		<!--Instructions-->
		<app-text>
			Complete the form to transfer
			<strong>{{ selectedItem.userData.userName }}</strong>
			to another team for this shift.
		</app-text>

		<!--Team-->
		<app-form-field form-type="select"
						class="mt-4"
						:error="errors.eventTransferTeamTo"
						:error-message="errors.eventTransferTeamToErrorMessage"
						:items="teamsData"
						item-text="teamName"
						item-value="entityId"
						label="Team"
						placeholder="What team is this user transferring to?"
						v-model="form.eventTransferTeamTo"/>

		<!--Time Selection-->
		<div class="d-flex mt-4">

			<!--Start-->
			<time-picker @emitTime="handleTimeSelection($event, 'eventTransferStartTime')"
						 :error="errors.eventTransferStartTime"
						 :error-message="errors.eventTransferStartTimeErrorMessage"
						 label="Start Time"
						 :time="form.eventTransferStartTime"/>

			<div class="mx-2"/>

			<!--Finish-->
			<time-picker @emitTime="handleTimeSelection($event, 'eventTransferFinishTime')"
						 :error="errors.eventTransferFinishTime"
						 :error-message="errors.eventTransferFinishTimeErrorMessage"
						 label="Finish Time"
						 :time="form.eventTransferFinishTime"/>
		</div>

		<!--Role-->
		<app-form-field form-type="select"
						class="mt-4"
						:error="errors.eventTransferRoleTo"
						:error-message="errors.eventTransferRoleToErrorMessage"
						:items="['Supervisor', 'Deputy', 'Steward']"
						label="Role"
						placeholder="What role is this user transferring as?"
						v-model="form.eventTransferRoleTo"/>

		<!--Notes-->
		<app-form-field form-type="textArea"
						class="mt-4"
						:error="errors.eventTransferNotes"
						:error-message="errors.eventTransferNotesErrorMessage"
						label="Transfer Notes"
						placeholder="Why is this user being transferred?"
						v-model.trim="form.eventTransferNotes"/>

		<v-divider class="mt-4"/>

		<!--Action Buttons-->
		<div class="d-flex justify-space-between mt-4">

			<!--Cancel-->
			<app-btn @click.native="closeDialog"
					 color="grey"
					 icon="cancel"
					 label="Cancel"/>

			<!--Yes-->
			<app-btn @click.native="handleYesButton"
					 color="green"
					 icon="success"
					 label="Save"/>

		</div>

	</div>
</template>

<script>
export default {

	name: "EventControlPanelTransferDialog",

	props: ['selectedItem', 'usersFullEventData'],

	data: () => ({
		errors: {
			eventTransferTeamTo: false,
			eventTransferTeamToErrorMessage: '',
			eventTransferStartTime: false,
			eventTransferStartTimeErrorMessage: '',
			eventTransferFinishTime: false,
			eventTransferFinishTimeErrorMessage: '',
			eventTransferRoleTo: false,
			eventTransferRoleToErrorMessage: '',
			eventTransferNotes: false,
			eventTransferNotesErrorMessage: '',
		},
		form: {
			entityId: '',

			eventTransferEarlyFinishNotes: '',
			eventTransferEarlyFinishTime: '0',
			eventTransferEventId: '',
			eventTransferFinishTime: '0',
			eventTransferHours: 0,
			eventTransferIsNoShow: false,
			eventTransferIsNoShowNotes: '',
			eventTransferLateArrivalNotes: '',
			eventTransferLateArrivalTime: '0',
			eventTransferNotes: '',
			eventTransferRoleFrom: '',
			eventTransferRoleTo: '',
			eventTransferSignInTime: '0',
			eventTransferSignOutNotes: '',
			eventTransferSignOutTime: '0',
			eventTransferStartTime: '0',
			eventTransferSwappInTime: '0',
			eventTransferSwappOutTime: '0',
			eventTransferSwappStatus: 'Out',
			eventTransferTeamFrom: '',
			eventTransferTeamTo: '',
			eventTransferUserId: '',

			createdUserId: '',
			createdUserName: '',
			createdDateTime: 0,
			modifiedUserId: '',
			modifiedUserName: '',
			modifiedDateTime: 0,
			isDeleted: false,
			deletedUserId: '',
			deletedUserName: '',
			deletedDateTime: 0,
		},
		teamsData: [],
	}),

	computed: {

		/**
		 * Computed Init
		 *
		 * Initialise the component.
		 */
		computedInit() {
			const t = this
			const FORM_DATA = t.$props.selectedItem

			// If the selected item has eventTransferData, set the form to it
			if (FORM_DATA.eventTransferData?.entityId) t.form = FORM_DATA.eventTransferData

			t.teamsData = FORM_DATA.teamsData
		},

	},

	methods: {

		/**
		 * Clear Errors
		 *
		 * Clear all errors and their messages.
		 */
		clearErrors() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		/**
		 * Close Dialog
		 *
		 * Emit back to the parent to close the dialog box.
		 */
		closeDialog() {
			const t = this

			t.$emit('emitCloseEventControlPanelTransferDialog')
		},

		/**
		 * Handle Time Selection
		 *
		 * Take the time from the picker and store it.
		 *
		 * @param time the time string from the picker
		 * @param field the form field to set
		 */
		handleTimeSelection(time, field) {
			const t = this

			// Set the time to the current form field
			t.form[field] = time
		},

		/**
		 * Handle Yes Button
		 *
		 * Set the time either to the selected time, or now if no selection is made.
		 * Emit back to the parent to update the EventUser document's time.
		 */
		handleYesButton() {
			const t = this
			const SELECTED_ITEM = t.$props.selectedItem
			const FORM_DATA = t.form

			// Only continue if the form is valid
			if (!t.validateForm()) return

			// If the roleTo is 'Steward', set it to 'User'
			if (FORM_DATA.eventTransferRoleTo === 'Steward') FORM_DATA.eventTransferRoleTo = 'User'
			FORM_DATA.eventTransferEventId = SELECTED_ITEM.eventData.entityId
			FORM_DATA.eventTransferHours = t.MIX_formatTimeToHHMM(FORM_DATA.eventTransferStartTime, FORM_DATA.eventTransferFinishTime)?.decimalTime
			FORM_DATA.eventTransferRoleFrom = SELECTED_ITEM.userData.userRole
			FORM_DATA.eventTransferTeamFrom = SELECTED_ITEM.userData.userTeam
			FORM_DATA.eventTransferUserId = SELECTED_ITEM.userData.entityId

			t.$emit('emitEventControlPanelShiftTransfer', FORM_DATA)
		},

		/**
		 * Validate Form
		 *
		 * Validate the form and return true or false if it is valid, or not.
		 *
		 * @returns {boolean}
		 */
		validateForm() {
			const t = this

			// Clear any existing errors
			t.clearErrors()

			// Team To
			if (!t.form.eventTransferTeamTo) {
				t.errors.eventTransferTeamTo = true
				t.errors.eventTransferTeamToErrorMessage = 'A Team is required'
			}

			// Start Time
			if (t.form.eventTransferStartTime === '0') {
				t.errors.eventTransferStartTime = true
				t.errors.eventTransferStartTimeErrorMessage = 'Start Time is required'
			}
			// If the start time is after the finish time, set an error
			else if (t.form.eventTransferStartTime > t.form.eventTransferFinishTime) {
				t.errors.eventTransferStartTime = true
				t.errors.eventTransferStartTimeErrorMessage = 'Start Time cannot be after Finish Time'
			}

			// Finish Time
			if (t.form.eventTransferFinishTime === '0') {
				t.errors.eventTransferFinishTime = true
				t.errors.eventTransferFinishTimeErrorMessage = 'Finish Time is required'
			}
			// If the finish time is before the start time, set an error
			else if (t.form.eventTransferFinishTime < t.form.eventTransferStartTime) {
				t.errors.eventTransferFinishTime = true
				t.errors.eventTransferFinishTimeErrorMessage = 'Finish Time cannot be before Start Time'
			}

			// Role To
			if (!t.form.eventTransferRoleTo) {
				t.errors.eventTransferRoleTo = true
				t.errors.eventTransferRoleToErrorMessage = 'A Role is required'
			}

			// Notes
			if (!t.form.eventTransferNotes.trim()) {
				t.errors.eventTransferNotes = true
				t.errors.eventTransferNotesErrorMessage = 'Notes are required'
			}

			// Return true if there are no errors
			return !Object.values(t.errors).includes(true)
		},

	},

}
</script>

<style scoped>

</style>
