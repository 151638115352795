import Vue from 'vue'
import VueRouter from 'vue-router'
import {getAuth} from "firebase/auth";
import appConfig from "../appConfig.json";

// Route Components
import Accreditation from "@/views/accreditation/staff/Accreditation.vue"
import MyAccreditation from "@/views/accreditation/organisation/MyAccreditation.vue"
import AppSupport from '@/views/appSupport/AppSupport.vue'
import Documentation from '@/views/documentation/Documentation.vue'
import EventDayDashboard from '@/views/eventDayDashboard/EventDayDashboard.vue'
import Events from '@/views/events/Events.vue'
import FireRollCall from "@/views/fireRollCall/FireRollCall.vue"
import Home from '@/views/home/Home.vue'
import Induction from '@/views/induction/Induction.vue'
import MyOrganisation from "@/views/organisations/organisation/MyOrganisation.vue"
import MyProfile from '@/views/myProfile/MyProfile.vue'
import NoticeBoard from '@/views/noticeBoard/NoticeBoard.vue'
import Organisations from '@/views/organisations/staff/Organisations.vue'
import Orientation from '@/views/orientation/Orientation.vue'
import Parking from '@/views/parking/Parking.vue'
import PreEventDashboard from '@/views/preEventDashboard/PreEventDashboard.vue'
import Qualifications from '@/views/qualifications/Qualifications.vue'
import Register from '@/views/auth/Register.vue'
import Reporting from '@/views/reporting/Reporting.vue'
import ResetPassword from '@/views/auth/ResetPassword.vue'
import Sites from '@/views/sites/Sites.vue'
import SignIn from '@/views/auth/SignIn.vue'
import Swapp from "@/views/swapp/Swapp.vue"
import Teams from '@/views/teams/Teams.vue'
import Unauthorised from '@/views/unauthorised/Unauthorised.vue'
import Users from '@/views/users/Users.vue'

Vue.use(VueRouter)

const routes = [
	// Auth
	{
		path: '/register',
		name: 'Register',
		component: Register
	},
	{
		path: '/signIn',
		name: 'SignIn',
		component: SignIn
	},
	{
		path: '/resetPassword',
		name: 'ResetPassword',
		component: ResetPassword
	},

	// App
	{
		path: '/',
		name: 'Home',
		component: Home,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin', 'Staff-User', 'Contractor-Manager', 'Contractor-User'],
			userStatus: ['Approved']
		}
	},
	{
		path: '/accreditation',
		name: 'Accreditation',
		component: Accreditation,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin'],
			userStatus: ['Approved']
		}
	},
	{
		path: '/documentation',
		name: 'Documentation',
		component: Documentation,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin', 'Staff-User', 'Contractor-Manager', 'Contractor-User'],
			userStatus: ['Approved']
		}
	},
	{
		path: '/fireRollCall',
		name: 'Fire Roll Call',
		component: FireRollCall,
		meta: {
			requiresAbility: 'Fire Roll Call',
			requiresAuth: true,
			userLevel: ['Staff-Admin', 'Staff-User', 'Contractor-Manager', 'Contractor-User'],
			userStatus: ['Approved']
		}
	},
	{
		path: '/myAccreditation',
		name: 'MyAccreditation',
		component: MyAccreditation,
		meta: {
			requiresAuth: true,
			userLevel: ['Contractor-Manager'],
			userStatus: ['Approved']
		}
	},
	{
		path: '/appSupport',
		name: 'AppSupport',
		component: AppSupport,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin', 'Staff-User', 'Contractor-Manager', 'Contractor-User'],
			userStatus: ['Approved']
		}
	},
	{
		path: '/eventDayDashboard',
		name: 'EventDayDashboard',
		component: EventDayDashboard,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin', 'Staff-User'],
			userStatus: ['Approved']
		}
	},
	{
		path: '/events',
		name: 'Events',
		component: Events,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin', 'Staff-User'],
			userStatus: ['Approved']
		}
	},
	{
		path: '/induction',
		name: 'Induction',
		component: Induction,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin', 'Staff-User', 'Contractor-Manager', 'Contractor-User'],
			userStatus: ['Approved']
		}
	},
	{
		path: '/myContractor',
		name: 'My Contractor',
		component: MyOrganisation,
		meta: {
			requiresAuth: true,
			userLevel: ['Contractor-Manager'],
			userStatus: ['Approved']
		}
	},
	{
		path: '/myProfile',
		name: 'My Profile',
		component: MyProfile,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin', 'Staff-User', 'Contractor-Manager', 'Contractor-User'],
			userStatus: ['Approved']
		}
	},
	{
		path: '/noticeBoard',
		name: 'Notice Board',
		component: NoticeBoard,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin', 'Staff-User'],
			userStatus: ['Approved']
		}
	},
	{
		path: '/contractors',
		name: 'Contractors',
		component: Organisations,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin', 'Staff-User'],
			userStatus: ['Approved']
		}
	},
	{
		path: '/orientation',
		name: 'Orientation',
		component: Orientation,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin', 'Staff-User', 'Contractor-Manager', 'Contractor-User'],
			userStatus: ['Approved']
		}
	},
	{
		path: '/parking',
		name: 'Parking',
		component: Parking,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin', 'Staff-User'],
			userStatus: ['Approved']
		}
	},
	{
		path: '/preEventDashboard',
		name: 'PreEventDashboard',
		component: PreEventDashboard,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin', 'Staff-User'],
			userStatus: ['Approved']
		}
	},
	{
		path: '/qualifications',
		name: 'Qualifications',
		component: Qualifications,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin'],
			userStatus: ['Approved']
		}
	},
	{
		path: '/reporting',
		name: 'Reporting',
		component: Reporting,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin', 'Staff-User', 'Contractor-Manager', 'Contractor-User'],
			userStatus: ['Approved']
		}
	},
	{
		path: '/sites',
		name: 'Sites',
		component: Sites,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin', 'Staff-User'],
			userStatus: ['Approved']
		}
	},
	{
		name: 'Swapp',
		path: '/swapp/:id?',
		component: Swapp,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin', 'Staff-User', 'Contractor-Manager', 'Contractor-User'],
			userStatus: ['Approved']
		}
	},
	{
		path: '/teams',
		name: 'Teams',
		component: Teams,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin', 'Staff-User'],
			userStatus: ['Approved']
		}
	},
	{
		path: '/unauthorised',
		name: 'Unauthorised',
		component: Unauthorised,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin', 'Staff-User', 'Contractor-Manager', 'Contractor-User'],
			userStatus: ['Pending', 'Approved', 'Rejected', 'Suspended', 'Archived'],
		}
	},
	{
		path: '/users',
		name: 'Users',
		component: Users,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin', 'Staff-User'],
			userStatus: ['Approved']
		}
	},

	// Fallback to Home page for unknown routes
	{
		path: '*',
		name: 'Home',
		component: Home,
		meta: {requiresAuth: true}
	}
]

/**
 * Vue Router
 *
 * The Vue Router instance.
 *
 * @type {VueRouter} - The Vue Router instance.
 */
const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

/**
 * Has Access To Route
 *
 * Check if the current user has access to the route via their userAccessAndAbilities.
 *
 * @param currentUserData - The current user's data.
 * @param to - The route to check.
 * @returns {boolean} - True if the user has access to the route, false if not.
 */
const hasAccessToRoute = (currentUserData, to) => {
	const ACCESS_AND_ABILITIES = currentUserData.userAccessAndAbilities || []

	// If the route requires an ability, check if the user has that ability
	if (to.meta && to.meta.requiresAbility) return ACCESS_AND_ABILITIES.includes(to.meta.requiresAbility)

	// If the route does not require an ability, the user has access to the route
	return true
}

/**
 * Before Each
 *
 * Run this function before each route change.
 *
 * @param to - The route that the user is going to.
 * @param from - The route that the user is coming from.
 * @param next - The function to call to continue to the next route.
 */
router.beforeEach((to, from, next) => {
	const REQUIRES_AUTH = to.matched.some(record => record.meta.requiresAuth)
	let currentUserData = {}

	// Register the service worker
	navigator.serviceWorker.register('/service-worker.js')
		.then(reg => reg.update())

	// If the route does not require authorisation, continue
	if (!REQUIRES_AUTH) next()

	// If the route does require authorisation
	if (REQUIRES_AUTH) {

		// Get the current user's data from local storage
		const CURRENT_USER_AUTH = getAuth().currentUser
		if (CURRENT_USER_AUTH) currentUserData = JSON.parse(localStorage.getItem('currentUserData'))

		// If the route is not included in the allowedRoutes, redirect them to the Home page
		const isRouteAllowed = appConfig.allowedRoutes.some(route => route.name === to.name)
		if (!isRouteAllowed) next({path: '/'})

		// If the user is not logged in, redirect them to the login page
		if (!CURRENT_USER_AUTH) next({path: '/signIn'})

		// If the user's userLevel is not included in the meta.userLevel, redirect them to the Home page
		else if (to.meta.userLevel && !to.meta.userLevel.includes(currentUserData.userLevel)) next({path: '/'})

		// If the user's userStatus (meta.userStatus) is not 'Approved', redirect them to ''unauthorised.vue
		else if (to.meta.userStatus && !to.meta.userStatus.includes(currentUserData.userStatus)) next({path: '/unauthorised'})

		// If the route requires an ability and the user does not have that ability, redirect them to the Home page
		else if (to.meta.requiresAbility && !hasAccessToRoute(currentUserData, to)) return next({path: '/'})

		// If all is good
		else next()
	}

})

export default router
