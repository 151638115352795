<template>
	<div style="min-height: calc(100vh - 72px)">

		<page-title :divider="true"
					icon="qrCode"
					info="SWAPP in when you arrive at the venue, and out when you leave."
					:title="`SWAPP ${currentUserData.userSwappStatus === 'In' ? 'Out' : 'In'}`"/>

		<!--SWAPP Reader ------------------------------------------------------------------------------------------- -->

		<div v-if="pageView === 'Reader'">

			<!--Error Messages-->
			<div v-if="error" class="centerInPage text-center pa-4">
				<app-text class="" v-if="noFrontCamera">You don't seem to have a front camera on your device</app-text>
				<app-text class="" v-if="noRearCamera">You don't seem to have a rear camera on your device</app-text>
				<app-text class="mt-4" v-if="error">{{ error }}</app-text>
			</div>

			<!--QR Reader-->
			<qrcode-stream v-if="!error"
						   @decode="onDecode"
						   @init="onInit"
						   :camera="camera || null"
						   class="animate__animated animate__zoomIn animate__faster"
						   :track="paintOutline"
						   style="aspect-ratio: 1/1; border-radius: 14px; width: 100%">

				<!--Camera Buttons-->
				<!--<div class="qrReader-cameraButtons-container">-->
				<!--	<app-btn @click.native="switchCamera('default')" label="Default"/>-->
				<!--	<app-btn @click.native="switchCamera('front')" label="Front" class="mx-4"/>-->
				<!--	<app-btn @click.native="switchCamera('rear')" label="Rear" class="mr-4"/>-->
				<!--</div>-->

				<!--Loader-->
				<div class="d-flex align-center justify-center" style="height: 100%; width: 100%">
					<app-text v-if="isLoading" size="xlarge">Loading...</app-text>
				</div>

				<!--Instructions-->
				<div class="d-flex align-center justify-center"
					 style="height: 100%; width: 100%; position:absolute; top:0; bottom:0">

					<!--QR Icon-->
					<app-icon color="#FFFFFF33" icon="qrCode" size="256" style="position:absolute;"
							  class="animate__animated animate__pulse animate__fast animate__infinite"/>

					<!--Instruction-->
					<app-text color="white" size="large">Scan your QR code</app-text>

				</div>

			</qrcode-stream>

			<!--Instructions-->
			<div class="pa-4">

				<app-text>1) Allow your camera when prompted</app-text>
				<app-text class="my-2">2) Point your camera at the QR code on the poster</app-text>
				<app-text>3) Make sure the QR Code is visible in the camera box</app-text>

				<!--Can't SWAPP In Button-->
				<app-btn @click.native="pageView = 'Manual'"
						 :block="true"
						 class="mt-4"
						 :label="`Can't SWAPP ${currentUserData.userSwappStatus === 'In' ? 'Out' : 'In'}?`"/>

				<!--Privacy Notice-->
				<app-text class="mt-4" size="small">
					<span class="font-weight-bold">Privacy</span>
					<v-divider class="my-1"/>
					Your camera is only used for SWAPPing in and out.
					Your camera and microphone are not used for any other purpose.
					We respect your privacy and do not access or use any of your personal data, other than what is held
					in the app.
				</app-text>

			</div>

		</div>

		<!--Can't SWAPP -------------------------------------------------------------------------------------------- -->

		<div v-if="pageView === 'Manual'" class="d-flex flex-column justify-space-between"
			 style="height: calc(100vh - 216px)">

			<!--Info | Form-->
			<div>

				<app-text class="mt-4" color="primary" size="normal-bold">Trouble SWAPPing?</app-text>
				<app-text class="mt-2" size="small">
					If you are unable to SWAPP using the QR Code, select a Site and Location from the options below.
				</app-text>

				<!--Reason-->
				<app-form-field form-type="select"
								class="mt-4"
								:items="['Camera Not Working', 'Cannot Find QR Code', 'Unable to Scan QR Code', 'Other']"
								label="Reason"
								v-model="manualSwappReason"/>

				<!--Site-->
				<app-form-field v-if="manualSwappReason"
								form-type="select"
								class="mt-4"
								:items="sitesData"
								item-text="siteName"
								label="Site"
								:return-object="true"
								v-model="manualSwappSite"/>

				<!--Location-->
				<app-form-field v-if="manualSwappSite"
								form-type="select"
								class="mt-4"
								:items="computedLocationsData"
								item-text="locationName"
								label="Location"
								:return-object="true"
								v-model="manualSwappLocation"/>

				<!--Selected Site Details-->
				<div v-if="manualSwappLocation" class="mt-4">
					<app-text color="primary" size="normal-bold">{{ manualSwappSite?.siteName }}</app-text>
					<div class="d-flex align-start mt-2">
						<app-icon color="primary" icon="home"/>
						<div class="ml-4">
							<app-text>{{ manualSwappSite?.siteAddressLine1 }}</app-text>
							<app-text class="mt-2">
								{{ manualSwappSite?.siteTown || manualSwappSite?.siteCity }}
							</app-text>
						</div>
					</div>
				</div>

			</div>

			<!--Action Buttons-->
			<div>

				<!--SWAPP Button-->
				<app-btn v-if="manualSwappLocation"
						 @click.native="handleManualSwappButton"
						 :block="true"
						 class="mt-4"
						 color="primary"
						 icon="qrCode"
						 icon-color="white"
						 label="SWAPP"
						 label-color="white"/>

				<!--Back Button-->
				<app-btn @click.native="pageView = 'Reader'"
						 :block="true"
						 class="mt-4"
						 label="Back to QR Code"/>

			</div>

		</div>

		<!--SWAPP Result ------------------------------------------------------------------------------------------- -->

		<div v-if="pageView === 'Result'" class="d-flex flex-column justify-space-between text-center pa-4"
			 style="height: 100%">

			<div>
				<app-text>You have</app-text>
				<app-text class="mt-2" color="green" size="medium-bold">Successfully</app-text>
				<app-text class="mt-2">
					SWAPPed
					<strong>{{ currentUserData.userSwappStatus }}</strong>
					{{ currentUserData.userSwappStatus === 'In' ? 'to' : 'of' }}
				</app-text>
				<app-text class="mt-2" color="primary" size="medium">
					{{ getSiteDataFromId(currentUserData.userLastSwappSiteId)?.siteName }},
					<strong>{{ getLocationDataFromId(currentUserData.userLastSwappLocationId)?.locationName }}</strong>
				</app-text>
			</div>

			<app-icon class="pulse" color="green" icon="success" size="64vw"/>

			<app-btn @click.native="MIX_go('/')" :block="true" icon="home" label="Home"/>

		</div>

		<!--Dialogs ------------------------------------------------------------------------------------------------ -->

		<!--Report to Office-->
		<app-dialog :isVisible="isReportToOfficeDialogVisible">
			<div class="appGrey rounded-lg pa-4">

				<!--Icon | Title-->
				<div class="d-flex align-center">
					<app-icon class="mr-4" color="orange" icon="error" size="32"/>
					<app-text size="medium-bold">Report to Site Office</app-text>
				</div>

				<v-divider class="greyD mt-4"/>


				<!--Information-->
				<app-text class="mt-4">
					As this is your first time SWAPPing into this site, you must report to the site office for your
					Orientation.
				</app-text>

				<v-divider class="greyD mt-4"/>

				<!--Close Button-->
				<app-btn @click.native="MIX_go('/')" :block="true" class="mt-4" label="Close"/>

			</div>
		</app-dialog>

	</div>
</template>

<script>
import {QrcodeStream} from 'vue-qrcode-reader'

export default {

	name: "QrCodeReader",

	components: {QrcodeStream},

	data: () => ({
		camera: 'rear',
		currentUserData: {},
		error: '',
		isLoading: false,
		isReportToOfficeDialogVisible: false,
		manualSwappLocation: '',
		manualSwappReason: '',
		manualSwappSite: '',
		noFrontCamera: false,
		noRearCamera: false,
		pageView: 'Reader',
		qrCodeLocation: {},
		qrCodeSite: {},

		// Data
		locationsData: [],
		sitesData: [],
	}),

	computed: {

		computedLocationsData() {
			const t = this

			return t.locationsData.filter(location => location.locationSite === t.manualSwappSite.entityId)
		},

	},

	methods: {

		/**
		 * Check Orientation Status For Current Site
		 *
		 * Check if the current user has passed orientation for the given site.
		 * If they have not, redirect them to the Orientation page.
		 *
		 * @param siteId - the ID of the site to check
		 * @returns {Promise<boolean>}
		 */
		async checkOrientationStatusForCurrentSite(siteId) {
			const t = this

			const RESPONSE = await t.MIX_redis_getOrientationsWhere([
				{
					whereKey: 'orientationUserId',
					whereValue: t.currentUserData.entityId,
				},
				{
					whereKey: 'orientationSiteId',
					whereValue: siteId,
				},
			])

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Orientation: ', RESPONSE.error)
				return
			}

			// If the user has not passed orientation for this site, display the Report to Office dialog
			if (!RESPONSE.data.length) {
				t.toggleReportToOfficeDialogVisibility()
				return false
			} else {
				return true
			}

			// NOT BEING USED
			// If the user has not passed orientation for this site, redirect them to the Orientation page
			// if (!RESPONSE.data.length) {
			// 	t.MIX_addToLocalStorage('orientationSiteId', siteId)
			// 	t.MIX_go('/orientation')
			// }
		},

		/**
		 * Get Location Data From ID
		 *
		 * Return the Location data for the given ID.
		 *
		 * @param locationId - the ID of the location
		 * @returns {JSON} and object containing the Location data
		 */
		getLocationDataFromId(locationId) {
			const t = this

			return t.locationsData.find(location => location.entityId === locationId)
		},

		/**
		 * Get Site Data From ID
		 *
		 * Return the Site data for the given ID.
		 *
		 * @param siteId - the ID of the site
		 * @returns {JSON} and object containing the Site data
		 */
		getSiteDataFromId(siteId) {
			const t = this

			return t.sitesData.find(site => site.entityId === siteId)
		},

		/**
		 * Handle Manual Swapp Button
		 *
		 * Handle the SWAPP button click when the user has manually entered the SWAPP details.
		 */
		handleManualSwappButton() {
			const t = this
			const CURRENT_USER_DATA = t.currentUserData
			const SITE_DATA = t.manualSwappSite
			const LOCATION_DATA = t.manualSwappLocation

			t.swappUser(
				CURRENT_USER_DATA,
				SITE_DATA.entityId,
				LOCATION_DATA.entityId,
				'Manually',
				CURRENT_USER_DATA.userSwappStatus === 'Out' ? 'In' : 'Out'
			)

		},

		/**
		 * Load Data
		 *
		 * Load all the data required for the page.
		 *
		 * @returns {Promise<void>}
		 */
		async loadData() {
			const t = this

			t.isLoading = true

			await Promise.all([
				t.loadSitesData(),
				t.loadLocationsData()
			])

			t.isLoading = false
		},

		/**
		 * Load Locations Data
		 *
		 * Load Locations data from the database.
		 *
		 * @returns {Promise<void>}
		 */
		async loadLocationsData() {
			const t = this

			// Fetch the data
			const RESPONSE = await t.MIX_redis_getAll('location')

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Location: ', RESPONSE.error)
				return
			}

			// Assign the data to the state as an array
			t.locationsData = RESPONSE.data
		},

		/**
		 * Load Sites Data
		 *
		 * Load Sites data from the database.
		 *
		 * @returns {Promise<void>}
		 */
		async loadSitesData() {
			const t = this

			// Fetch the data
			const RESPONSE = await t.MIX_redis_getAll('site')

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Sites: ', RESPONSE.error)
				return
			}

			// Assign the data to the state as an array
			t.sitesData = RESPONSE.data
		},

		/**
		 * On Decode
		 *
		 * Decode the QR code and SWAPP the user in or out.
		 *
		 *
		 * @returns {Promise<void>}
		 * @param siteLocationData
		 */
		async onDecode(siteLocationData) {
			const t = this
			const CURRENT_USER_DATA = t.currentUserData

			try {
				let locationId

				// Check if the QR code is a URL or just the location ID
				// The full URL is used when using the SWAPP camera, and just the location ID is used when coming in externally through the router
				if (siteLocationData.includes('http')) {
					const QR_URL = new URL(siteLocationData)
					const SEGMENTS = QR_URL.pathname.split('/')
					locationId = SEGMENTS.pop()
				} else {
					locationId = siteLocationData
				}

				// Get the Location data from the decoded QR code's location ID
				const LOCATION_DATA = t.locationsData.find(location => location.entityId === locationId)

				// Handle any errors, reset the camera if there are so the User can try again
				if (!LOCATION_DATA?.entityId) {
					t.$sharedState.errorMessage = 'No Location was found for this QR code, please try again.'
					t.resetCamera()
					return
				}

				// Get the Site data from the Location data
				const SITE_DATA = t.sitesData.find(site => site.entityId === LOCATION_DATA.locationSite)

				// SWAPP the user in or out
				await t.swappUser(
					CURRENT_USER_DATA,
					SITE_DATA.entityId,
					LOCATION_DATA.entityId,
					'QR Code',
					CURRENT_USER_DATA.userSwappStatus === 'Out' ? 'In' : 'Out'
				)
			}

			// Handle any errors, reset the camera if there are so the User can try again
			catch (error) {
				console.error('Error decoding QR code:', error)
				t.$sharedState.errorMessage = `Error processing QR code: ${error.message}`
				t.resetCamera()
			}
		},

		/**
		 * On Init
		 *
		 * Initialise the QR code reader by checking for loading and camera errors.
		 * Any errors will stop the camera from loading.
		 *
		 * @param promise
		 * @returns {Promise<void>}
		 */
		async onInit(promise) {
			const t = this
			t.isLoading = true

			// Initialisation Errors
			try {
				await promise
			} catch (error) {
				if (error.name === 'NotAllowedError') t.error = "ERROR: you need to grant camera access permission"
				else if (error.name === 'NotFoundError') t.error = "ERROR: no camera on this device"
				else if (error.name === 'NotSupportedError') t.error = "ERROR: secure context required (HTTPS, localhost)"
				else if (error.name === 'NotReadableError') t.error = "ERROR: is the camera already in use?"
				else if (error.name === 'OverconstrainedError') t.error = "ERROR: installed cameras are not suitable"
				else if (error.name === 'StreamApiNotSupportedError') t.error = "ERROR: Stream API is not supported in this browser"
				else if (error.name === 'InsecureContextError') t.error = 'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.'
				else t.error = `ERROR: Camera error (${error.name})`
			}

			// Camera Errors
			try {
				await promise
			} catch (error) {
				const triedFrontCamera = t.camera === 'front'
				const triedRearCamera = t.camera === 'rear'

				const cameraMissingError = error.name === 'OverconstrainedError'

				if (triedRearCamera && cameraMissingError) t.noRearCamera = true

				if (triedFrontCamera && cameraMissingError) t.noFrontCamera = true

				console.error(error)
			}

			t.isLoading = false
		},

		/**
		 * Paint Outline
		 *
		 * Track the QR code with a colored outline when it has been found
		 *
		 * @param detectedCodes
		 * @param ctx
		 */
		paintOutline(detectedCodes, ctx) {
			for (const detectedCode of detectedCodes) {
				const [firstPoint, ...otherPoints] = detectedCode.cornerPoints

				ctx.strokeStyle = "red"

				ctx.beginPath();
				ctx.moveTo(firstPoint.x, firstPoint.y)
				for (const {x, y} of otherPoints) {
					ctx.lineTo(x, y);
				}
				ctx.lineTo(firstPoint.x, firstPoint.y)
				ctx.closePath()
				ctx.stroke()
			}
		},

		/**
		 * Reset Camera
		 *
		 * Reset the camera to be able to SWAPP again.
		 */
		resetCamera() {
			const t = this

			t.camera = 'off'

			setTimeout(() => {
				t.camera = 'auto'
			}, 1000)

		},

		/**
		 * SWAPP User
		 *
		 * Check if the user has passed orientation for this site.
		 * Call to update the User's document with the new SWAPP data.
		 *
		 * @param userData - a JSON of user data to update
		 * @param siteId - the Site ID to SWAPP to
		 * @param locationId - the Location ID to SWAPP to
		 * @param swappMethod - the method of SWAPPing
		 * @param newSwappStatus - the User's new SWAPP status
		 * @returns {Promise<void>}
		 */
		async swappUser(userData, siteId, locationId, swappMethod, newSwappStatus) {
			const t = this

			// Check if the user has passed orientation for this site
			// If they have not, display the Report to Office dialog
			if (newSwappStatus === 'In') {
				const hasPreviouslySwapped = await t.checkOrientationStatusForCurrentSite(siteId)
				if (!hasPreviouslySwapped) return
			}

			const RESPONSE = await t.MIX_swappUser(userData, siteId, locationId, swappMethod, newSwappStatus)

			// Handle any errors, reset the camera if there are so the User can try again
			if (RESPONSE.hasErrors) {
				t.$sharedState.errorMessage = 'There was a problem SWAPPing, please try again.'
				t.resetCamera()
				return
			}

			t.pageView = 'Result'
		},

		/**
		 * Switch Camera
		 *
		 * Switch the device's cameras between Front, Rear, and Default.
		 *
		 * @param camera the selection from the switcher buttons for which camera to use
		 */
		switchCamera(camera) {
			const t = this

			// Reset Errors
			t.noRearCamera = false
			t.noFrontCamera = false
			t.error = ''

			switch (camera) {
				case 'front':
					t.camera = 'front'
					break
				case 'rear':
					t.camera = 'rear'
					break
				default:
					t.camera = ''
			}

		},

		toggleReportToOfficeDialogVisibility() {
			this.isReportToOfficeDialogVisible = !this.isReportToOfficeDialogVisible
		},

	},

	async mounted() {
		const t = this

		t.currentUserData = t.MIX_getCurrentUser()
		// if (t.currentUserData.userSwappStatus === 'In') t.pageView = 'Result'

		// Set the camera to the default camera in development, so it works with webcams
		if (process.env.NODE_ENV === 'development') t.camera = ''

		await t.loadData()

		// Get any URL parameters and store them in a variable
		const URL_PARAMS = t.$route.params.id

		this.d_routeParams = URL_PARAMS

		if (URL_PARAMS) {
			// t.qrCodeLocation = t.locationsData.find(location => location.entityId === URL_PARAMS)
			// t.qrCodeSite = t.sitesData.find(site => site.entityId === t.qrCodeLocation.locationSite)
			t.onDecode(URL_PARAMS)
		}
	},

}
</script>

<style scoped>
.qrReader-cameraButtons-container {
	display: flex;
	justify-content: flex-end;

	position: absolute;
	top: 16px;
	right: 0;
	z-index: 9;
}

.qrcode-stream-wrapper >>> video {
	border-radius: 16px;
	margin-top: 16px;
}
</style>
