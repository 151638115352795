<template>
	<div class="appGrey pa-4">

		{{computedInit}}

		<!--Question-->
		<app-form-field form-type="textInput"
						:disabled="isReadOnly"
						label="Question"
						:error="errors.inductionQuestion"
						:error-message="errors.inductionQuestionErrorMessage"
						v-model.trim="form.inductionQuestion"/>

		<!--Correct Answer-->
		<app-form-field form-type="textInput"
						class="mt-4"
						:disabled="isReadOnly"
						:error="errors.inductionCorrectAnswer"
						:error-message="errors.inductionCorrectAnswerErrorMessage"
						label="Correct Answer"
						v-model.trim="form.inductionCorrectAnswer"/>

		<v-divider class="greyD my-4"/>

		<!--False Answer 1-->
		<app-form-field form-type="textInput"
						class="mt-4"
						:disabled="isReadOnly"
						:error="errors.inductionFalseAnswer1"
						:error-message="errors.inductionFalseAnswer1ErrorMessage"
						label="False Answer 1"
						v-model.trim="form.inductionFalseAnswer1"/>

		<!--False Answer 2-->
		<app-form-field form-type="textInput"
						class="mt-4"
						:disabled="isReadOnly"
						:error="errors.inductionFalseAnswer2"
						:error-message="errors.inductionFalseAnswer2ErrorMessage"
						label="False Answer 2"
						v-model.trim="form.inductionFalseAnswer2"/>

		<!--False Answer 3-->
		<app-form-field form-type="textInput"
						class="mt-4"
						:disabled="isReadOnly"
						:error="errors.inductionFalseAnswer3"
						:error-message="errors.inductionFalseAnswer3ErrorMessage"
						label="False Answer 3"
						v-model.trim="form.inductionFalseAnswer3"/>

		<!--Save Button-->
		<div v-if="!isReadOnly" class="d-flex justify-end mt-4">
			<app-btn @click.native="handleSaveButton" color="green" icon="save" label="Save"/>
		</div>

	</div>
</template>

<script>

export default {

	name: "InductionManagementForm",

	props: ['formData', 'isReadOnly'],

	data: () => ({
		errors: {
			inductionQuestion: false,
			inductionQuestionErrorMessage: '',
			inductionCorrectAnswer: false,
			inductionCorrectAnswerErrorMessage: '',
			inductionFalseAnswer1: false,
			inductionFalseAnswer1ErrorMessage: '',
			inductionFalseAnswer2: false,
			inductionFalseAnswer2ErrorMessage: '',
			inductionFalseAnswer3: false,
			inductionFalseAnswer3ErrorMessage: '',
		},
		form: {
			entityId: '',
			inductionQuestion: '',
			inductionCorrectAnswer: '',
			inductionFalseAnswer1: '',
			inductionFalseAnswer2: '',
			inductionFalseAnswer3: '',

			createdUserId: '',
			createdDateTime: 0,
			createdUserName: '',
			modifiedUserId: '',
			modifiedDateTime: 0,
			modifiedUserName: '',
			isDeleted: false,
			deletedUserId: '',
			deletedDateTime: 0,
			deletedUserName: '',
		},
	}),

	computed: {

		computedInit() {
			const t = this
			const FORM_DATA = t.$props.formData

			if (FORM_DATA?.entityId) {
				t.form = FORM_DATA
			}

		},

	},

	methods: {

		/**
		 * Clear Errors
		 *
		 * Clear all errors and their messages.
		 */
		clearErrors() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		/**
		 * Create Item
		 *
		 * Create a new item.
		 *
		 * @returns {Promise<void>}
		 */
		async createItem() {
			const t = this

			const RESPONSE = await t.MIX_redis_create('inductionQuestion', t.form)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error creating Induction: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem saving this question, please try again.'
				return
			}

			t.$sharedState.successMessage = 'Question saved'

			t.$emit('refreshData')
		},

		/**
		 * Handle Save Button
		 *
		 * Handle the save button click.
		 */
		async handleSaveButton() {
			const t = this

			if (!t.validateForm()) return

			// New
			if (!t.form.entityId) await t.createItem()
			else await t.updateItem()
		},

		/**
		 * Update Item
		 *
		 * Update the selected item.
		 *
		 * @returns {Promise<void>}
		 */
		async updateItem() {
			const t = this

			const RESPONSE = await t.MIX_redis_update('inductionQuestion', t.form.entityId, t.form)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error updating Induction Question: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem updating this question, please try again.'
				return
			}

			t.$sharedState.successMessage = 'Question updated'

			t.$emit('refreshData')
		},

		/**
		 * Validate Form
		 *
		 * Validate the form as required.
		 *
		 * @returns {boolean} if the form has passed validation, or not
		 */
		validateForm() {
			const t = this

			t.clearErrors()

			// Question
			if (!t.form.inductionQuestion) {
				t.errors.inductionQuestion = true
				t.errors.inductionQuestionErrorMessage = 'Please enter a question'
			}

			// Correct Answer
			if (!t.form.inductionCorrectAnswer) {
				t.errors.inductionCorrectAnswer = true
				t.errors.inductionCorrectAnswerErrorMessage = 'Please enter a correct answer'
			}

			// False Answer 1
			if (!t.form.inductionFalseAnswer1) {
				t.errors.inductionFalseAnswer1 = true
				t.errors.inductionFalseAnswer1ErrorMessage = 'Please enter a false answer'
			}

			// False Answer 2
			if (!t.form.inductionFalseAnswer2) {
				t.errors.inductionFalseAnswer2 = true
				t.errors.inductionFalseAnswer2ErrorMessage = 'Please enter a false answer'
			}

			// False Answer 3
			if (!t.form.inductionFalseAnswer3) {
				t.errors.inductionFalseAnswer3 = true
				t.errors.inductionFalseAnswer3ErrorMessage = 'Please enter a false answer'
			}

			return !Object.values(t.errors).includes(true)
		}

	},

}
</script>

<style scoped>

</style>
