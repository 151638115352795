<template>
	<div>

		<!--Action Buttons-->
		<div :class="$vuetify.breakpoint.width < 600
				? 'd-flex flex-column mt-4'
				: 'd-flex justify-end align-center mt-4'">
			<div class="d-flex flex-column">
				<app-text size="medium-bold">{{ siteData.siteName }}</app-text>
				<app-text size="small">
					{{ siteData.siteAddressLine1 }}
					{{ siteData.siteAddressTown && ', ' + siteData.siteAddressTown }}
					{{ siteData.siteAddressCity && ', ' + siteData.siteAddressCity }}
				</app-text>
			</div>

			<v-spacer/>

			<app-btn @click.native="handleCancelFireRollCall"
					 :block="$vuetify.breakpoint.width < 600"
					 :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : ''"
					 color="red" icon="fireRollCall" label="Cancel Fire Roll Call"/>
		</div>

		<!--Stats Bar-->
		<div :class="$vuetify.breakpoint.width < 600
				? 'appWhite d-flex flex-column align-center rounded-lg mt-4 pa-2'
				: 'appWhite d-flex justify-center rounded-lg mt-4 pa-2'">

			<!--Time Taken-->
			<div class="d-flex flex-column align-center">
				<app-text size="small">Time Taken</app-text>
				<app-text size="normal-bold">{{ computedFormatTimeTaken }}</app-text>
			</div>

			<v-divider class="mx-8" vertical/>

			<div class="d-flex">

				<!--Number In-->
				<div class="d-flex flex-column align-center">
					<app-text size="small">In</app-text>
					<app-text size="normal-bold">
						{{ computedNumberOfSwappedInUsersChecked }} / {{ usersData.swappedIn.length }}
					</app-text>
				</div>

				<v-divider class="mx-8" vertical/>

				<!--Number Out-->
				<div class="d-flex flex-column align-center">
					<app-text size="small">Out</app-text>
					<app-text size="normal-bold">
						{{ computedNumberOfSwappedOutUsersChecked }} / {{ usersData.swappedOut.length }}
					</app-text>
				</div>

				<v-divider class="mx-8" vertical/>

				<!--Total-->
				<div class="d-flex flex-column align-center">
					<app-text size="small">Total</app-text>
					<app-text size="normal-bold">
						{{ computedNumberOfSwappedInUsersChecked + computedNumberOfSwappedOutUsersChecked }}
						/
						{{ usersData.swappedIn.length }}
					</app-text>
				</div>

			</div>

		</div>

		<!--Search-->
		<app-form-field form-type="textInput"
						append-icon="icons8-search"
						class="mt-4"
						label="Search"
						v-model.trim="searchByName"/>

		<!--SWAPPed IN Cards-->
		<div class="d-flex align-center mt-4">
			<app-text class="mr-4" size="medium-bold">SWAPPed IN</app-text>
			<v-divider/>
		</div>
		<div class="frc-gridContainer mt-4">
			<fire-roll-call-user-card v-for="item in computedUsersData.swappedIn" :key="item.entityId"
									  @handleCheckboxSelection="handleCheckboxSelection"
									  :user-data="item"/>
			<app-text v-if="!usersData.swappedIn.length" class="ml-4" color="grey9">
				There are no users SWAPPed IN
			</app-text>
		</div>

		<!--SWAPPed OUT Cards-->
		<div class="d-flex align-center mt-4">
			<app-text class="mr-4" size="medium-bold">SWAPPed OUT</app-text>
			<v-divider/>
		</div>
		<div class="frc-gridContainer mt-4">
			<fire-roll-call-user-card v-for="item in computedUsersData.swappedOut" :key="item.entityId"
									  @handleCheckboxSelection="handleCheckboxSelection"
									  :user-data="item"/>
			<app-text v-if="!usersData.swappedOut.length" class="ml-4" color="grey9">
				No SWAPP OUTs in the last hour
			</app-text>
		</div>

		<v-divider class="mt-4"/>

		<!--Save Form-->
		<div>

			<v-row no-gutters>

				<!--Title-->
				<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'"
					   :cols="$vuetify.breakpoint.width < 600 ? '12' : '6'">
					<app-form-field form-type="textInput"
									:error="errors.frcTitle"
									:error-message="errors.frcTitleErrorMessage"
									label="Title"
									v-model.trim="form.frcTitle"/>
				</v-col>

				<!--Type-->
				<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pl-2'"
					   :cols="$vuetify.breakpoint.width < 600 ? '12' : '6'">
					<app-form-field form-type="select"
									:error="errors.frcType"
									:error-message="errors.frcTypeErrorMessage"
									:items="fireRollCallTypeOptions"
									label="Type"
									v-model="form.frcType"/>
				</v-col>

				<!--Notes-->
				<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
					   :cols="$vuetify.breakpoint.width < 600 ? '12' : '12'">
					<app-form-field form-type="textArea"
									label="Notes"
									v-model.trim="form.frcNotes"/>
				</v-col>

			</v-row>

			<!--Save Button-->
			<div class="d-flex justify-end mt-4">
				<app-btn @click.native="handleSave" color="green" icon="save" label="Save"/>
			</div>

		</div>

		<!--Dialogs ------------------------------------------------------------------------------------------------ -->

		<!--Cancel Fire Roll Call Dialog-->
		<app-dialog :is-visible="isCancelDialogOpen">
			<fire-roll-call-cancel-dialog @closeDialog="isCancelDialogOpen = false"
										  @cancelFireRollCall="cancelFireRollCall"/>
		</app-dialog>

	</div>
</template>

<script>

import FireRollCallUserCard from "@/views/fireRollCall/fireRollCallUserCard/FireRollCallUserCard.vue";
import FireRollCallCancelDialog from "@/views/fireRollCall/fireRollCallCancelDialog/FireRollCallCancelDialog.vue";

export default {

	name: "FireRollCallRegister",

	components: {FireRollCallCancelDialog, FireRollCallUserCard},

	props: ['siteData', 'usersData'],

	data: () => ({
		errors: {
			frcTitle: false,
			frcTitleErrorMessage: '',
			frcType: false,
			frcTypeErrorMessage: ''
		},
		form: {
			frcTitle: '',
			frcSiteId: '',
			frcType: '',
			frcTimeTaken: 0,
			frcNotes: '',
			frcUsers: [],

			createdDateTime: 0,
			createdUserId: '',
			createdUserName: '',
			modifiedDateTime: 0,
			modifiedUserId: '',
			modifiedUserName: '',
			isDeleted: false,
			deletedDateTime: 0,
			deletedUserId: '',
			deletedUserName: ''
		},
		isCancelDialogOpen: false,
		searchByName: '',
		timeTaken: 0,
		timeTakenTimer: null
	}),

	computed: {

		/**
		 * Computed Format Time Taken
		 *
		 * Return the time taken in HH:MM:SS format.
		 *
		 * @returns {string} - HH:MM:SS
		 */
		computedFormatTimeTaken() {
			const t = this

			const SECONDS = t.timeTaken % 60
			const MINUTES = Math.floor(t.timeTaken / 60) % 60
			const HOURS = Math.floor(t.timeTaken / 3600)

			return `${String(HOURS).padStart(2, '0')}:${String(MINUTES).padStart(2, '0')}:${String(SECONDS).padStart(2, '0')}`
		},

		/**
		 * Computed Number Of Swapped In Users Checked
		 *
		 * Return the number of users who have SWAPPed in and are checked.
		 *
		 * @returns {number} - Number of users checked
		 */
		computedNumberOfSwappedInUsersChecked() {
			const USERS_DATA = this.usersData

			return USERS_DATA.swappedIn.filter(user => user.isChecked).length
		},

		/**
		 * Computed Number Of Swapped Out Users Checked
		 *
		 * Return the number of users who have SWAPPed out and are checked.
		 *
		 * @returns {number} - Number of users checked
		 */
		computedNumberOfSwappedOutUsersChecked() {
			const USERS_DATA = this.usersData

			return USERS_DATA.swappedOut.filter(user => user.isChecked).length
		},

		/**
		 * Computed Users Data
		 *
		 * Return the users data filtered by the search input.
		 *
		 * @returns {{swappedIn: Array, swappedOut: Array}} - Filtered users data
		 */
		computedUsersData() {
			const t = this
			const USERS_DATA = t.$props.usersData
			const SEARCH_BY_NAME = t.searchByName?.toUpperCase()

			return {
				swappedIn: USERS_DATA.swappedIn.filter(user => user.userName.toUpperCase().includes(SEARCH_BY_NAME)),
				swappedOut: USERS_DATA.swappedOut.filter(user => user.userName.toUpperCase().includes(SEARCH_BY_NAME))
			}
		}

	},

	methods: {

		/**
		 * Cancel Fire Roll Call
		 *
		 * Close the dialog and emit an event to close the register.
		 */
		cancelFireRollCall() {
			const t = this

			t.isCancelDialogOpen = false
			clearInterval(t.timeTakenTimer)
			t.$emit('closeRegister')
		},

		/**
		 * Clear Errors
		 *
		 * Clear all errors and their messages.
		 */
		clearErrors() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		/**
		 * Create Item
		 *
		 * Create the Fire Roll Call.
		 */
		async createItem() {
			const t = this

			const RESPONSE = await t.MIX_redis_create('fireRollCall', t.form)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error creating Fire Roll Call: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem creating the Fire Roll Call, please try again.'
				return RESPONSE
			}

			t.$sharedState.successMessage = 'Fire Roll Call created successfully'

			t.$emit('emitReloadPage')
		},

		/**
		 * Handle Cancel Fire Roll Call
		 *
		 * Open the cancel dialog.
		 */
		handleCancelFireRollCall() {
			this.isCancelDialogOpen = true
		},

		/**
		 * Handle Checkbox Selection
		 *
		 * Toggle the isChecked property for the user.
		 *
		 * @param userData - User data
		 */
		handleCheckboxSelection(userData) {
			const USERS_DATA = this.usersData

			USERS_DATA.swappedIn = USERS_DATA.swappedIn.map(user =>
				user.entityId === userData.entityId ? {...user, isChecked: !user.isChecked} : user
			)
			USERS_DATA.swappedOut = USERS_DATA.swappedOut.map(user =>
				user.entityId === userData.entityId ? {...user, isChecked: !user.isChecked} : user
			)

		},

		/**
		 * Handle Save
		 *
		 * Validate the form, set the form details, and call to save the Fire Roll Call.
		 */
		async handleSave() {
			const t = this

			// Validate the form
			if (!t.validateForm()) return

			// Set the form data
			t.form.frcSiteId = t.$props.siteData.entityId
			t.form.frcTimeTaken = t.timeTaken
			// t.form.frcUsers = t.$props.usersData.swappedIn.filter(user => user.isChecked).map(user => user.entityId)
			// t.form.frcUsers.push(...t.$props.usersData.swappedOut.filter(user => user.isChecked).map(user => user.entityId))

			// Create an object for each swapped in user ({id: entityId, swappTime: userLastSwappDateTime, swappStatus: userSwappStatus})
			const SWAPPED_IN_USERS = t.$props.usersData.swappedIn.filter(user => user.isChecked).map(user => (
				JSON.stringify({
					id: user.entityId,
					swappTime: user.userLastSwappDateTime,
					swappStatus: user.userSwappStatus
				})
			))
			// Create an object for each swapped out user ({id: entityId, swappTime: userLastSwappDateTime, swappStatus: userSwappStatus})
			const SWAPPED_OUT_USERS = t.$props.usersData.swappedOut.filter(user => user.isChecked).map(user => (
				JSON.stringify({
					id: user.entityId,
					swappTime: user.userLastSwappDateTime,
					swappStatus: user.userSwappStatus
				})
			))
			// Combine the swapped in and swapped out users
			t.form.frcUsers = [...SWAPPED_IN_USERS, ...SWAPPED_OUT_USERS]

			// Clear the timer
			clearInterval(t.timeTakenTimer)

			await t.createItem()
		},

		/**
		 * Validate Form
		 *
		 * Validate the form fields and set the errors.
		 *
		 * @returns {boolean} - Form is valid
		 */
		validateForm() {
			const t = this

			t.clearErrors()

			if (!t.form.frcTitle) {
				t.errors.frcTitle = true
				t.errors.frcTitleErrorMessage = 'A Title is required'
			}

			if (!t.form.frcType) {
				t.errors.frcType = true
				t.errors.frcTypeErrorMessage = 'A Type is required'
			}

			return !Object.values(t.errors).includes(true)
		}

	},

	mounted() {
		const t = this

		// Set the timer - formatted as HH:MM:SS
		t.timeTakenTimer = setInterval(() => t.timeTaken++, 1000)
	}

}
</script>

<style scoped>
.frc-gridContainer {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
	grid-gap: 16px;
}
</style>
