<template>

	<page-loading-animation v-if="isLoading" :is-loading="isLoading"/>

	<!--Page Content-->
	<div v-else>

		<!--Header-->
		<page-title :divider="true"
					icon="fireRollCall"
					info="Start a new, and view previous fire roll calls."
					title="Fire Roll Call"/>

		<!--Table View-->
		<div v-if="viewType === 'tableView'">

			<!--Action Bar-->
			<div class="d-flex align-center mt-4">

				<!--Search-->
				<app-form-field form-type="textInput"
								append-icon="icons8-search"
								class="mr-4"
								:clearable="true"
								label="Title"
								style="width: 100%"
								v-model.trim="searchByTitle"/>

				<!--Start Button-->
				<app-btn @click.native="openRightPanel" class="mr-4" icon="fireRollCall"
						 :label="$vuetify.breakpoint.width < 600 ? 'Start' : 'Start a Fire Roll Call'"/>

				<!--Filter Button - with a numbered badge-->
				<div style="position:relative;">
					<app-btn @click.native="toggleFiltersVisibility"
							 class="mr-4" color="appWhite" icon="filter" icon-color="primary"/>
					<span v-if="computedNumberOfActiveFilters > 0" class="badge">{{ computedNumberOfActiveFilters
						}}</span>
				</div>

				<!--More Actions Menu-->
				<more-actions-menu @emitMenuSelection="handleEmittedMenuSelection" :menuList="moreActionsMenuOption"/>

			</div>

			<!--Clear Filters-->
			<div v-if="computedNumberOfActiveFilters > 0" class="d-flex justify-end mt-4">
				<app-btn @click.native="clearFilters" icon="close" label="Clear Filters"/>
			</div>

			<!--Table-->
			<v-data-table v-if="$vuetify.breakpoint.width >= 600"
						  class="appWhite rounded-lg mt-4"
						  :headers="computedHeaders"
						  :items="computedTableData">

				<!--ID-->
				<template v-slot:item.id="{item}">
					<app-text size="small">{{ item.siteData.id }}</app-text>
				</template>

				<!--Title-->
				<template v-slot:item.frcTitle="{item}">
					<app-text size="small">{{ item.frcTitle }}</app-text>
				</template>

				<!--Type-->
				<template v-slot:item.frcType="{item}">
					<app-text size="small">{{ item.frcType }}</app-text>
				</template>

				<!--Site-->
				<template v-slot:item.frcSiteId="{item}">
					<app-text size="small">{{ sitesData.find(site => site.entityId === item.frcSiteId)?.siteName }}
					</app-text>
				</template>

				<!--Date-->
				<template v-slot:item.createdDateTime="{item}">
					<app-text size="small">{{ MIX_formatDate(item.createdDateTime, 'long') }}</app-text>
				</template>

				<!--Action Button-->
				<template v-slot:item.action="{item}">
					<div class="d-flex justify-end">
						<app-icon @click.native="openRightPanel(item)"
								  class="cursorPointer" color="primary" icon="arrowForward"/>
					</div>
				</template>

			</v-data-table>

			<!--Mobile Cards-->
			<div v-if="$vuetify.breakpoint.width < 600">

				<!--No data message-->
				<app-text v-if="!computedTableData.length"
						  class="text-center mt-4" color="grey9">You have no Fire Roll Calls to view
				</app-text>

				<fire-roll-call-mobile-card v-for="item in computedTableData" :key="item.entityId"
											@click.native="openRightPanel(item)"
											class="mt-4"
											:card-data="item"
											:site-data="sitesData.find(site => site.entityId === item.frcSiteId)"/>

			</div>

		</div>

		<fire-roll-call-register v-if="viewType === 'registerView'"
								 @closeRegister="viewType = 'tableView'"
								 @emitReloadPage="emittedReloadPage"
								 :site-data="selectedSite"
								 :users-data="siteUsersData"/>

		<!--Filters Panel ------------------------------------------------------------------------------------------ -->
		<filter-panel :is-filters-panel-visible="isFiltersPanelVisible" @toggle="toggleFiltersVisibility">
			<div class="pa-4">

				<!--Dates-->
				<page-break-title
					description="Select a date range to filter the emails by (defaults from now to a year ago)."
					title="Dates"/>
				<div class="mt-4" style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 16px">
					<date-picker @emitDate="filterByDateFrom = Number($event)"
								 :clearable="false"
								 :date="filterByDateFrom"
								 label="Date from..."
								 :min-date="[1, 'years', 'past']"/>

					<date-picker @emitDate="filterByDateTo = Number($event)"
								 :clearable="false"
								 :date="filterByDateTo"
								 label="...Date up to"
								 :min-date="[1, 'years', 'past']"/>
				</div>

				<!--Sites-->
				<page-break-title class="mt-8" title="Sites"/>
				<div class="mt-4">
					<app-form-field form-type="autoComplete"
									:deletable-chips="true"
									:items="sitesData"
									item-text="siteName"
									label="Site"
									:multiple="true"
									:return-object="true"
									:small-chips="true"
									v-model="filterBySites"/>
				</div>

				<!--Types-->
				<page-break-title class="mt-8" title="Types"/>
				<div class="mt-4">
					<app-form-field form-type="autoComplete"
									:deletable-chips="true"
									:items="fireRollCallTypeOptions"
									label="Types"
									:multiple="true"
									:small-chips="true"
									v-model="filterByTypes"/>
				</div>

			</div>
		</filter-panel>

		<!--Right Side ----------------------------------------------------------------------------------- Right Side-->
		<transition enter-active-class="animate__animated animate__fadeInRight animate__faster"
					leave-active-class="animate__animated animate__fadeOutRight animate__faster"
					mode="out-in">

			<div v-if="isRightPanelVisible"
				 class="appGrey formShadow"
				 style="position:absolute; top:0; bottom: 0; right: 0; overflow: auto"
				 :style="$vuetify.breakpoint.width < 600 || selectedSite?.entityId ? 'width: 100%' : 'width: 50%'">

				<!--Header-->
				<div class="d-flex align-center primary pa-4">
					<app-text color="appWhite" size="normal">{{ (selectedItem?.frcTitle) || 'New' }}
					</app-text>
					<v-spacer/>
					<app-icon @click.native="closeRightPanel"
							  class="cursorPointer" color="white" icon="close" size="32"/>
				</div>

				<!--Action Bar-->
				<div class="d-flex align-center pa-4">

					<div class="d-flex flex-column">
						<app-text size="normal-bold">{{ selectedItem.frcTitle }}</app-text>
						<app-text color="grey9" size="small">
							{{ MIX_formatDate(selectedItem.createdDateTime, 'long') }}
							@
							{{ MIX_formatDateTimeToTime(selectedItem.createdDateTime) }}
						</app-text>
						<app-text v-if="!isReadOnly"
								  size="small-bold">
							NOTE: You cannot edit all the details of the Fire Roll Call
						</app-text>
					</div>

					<v-spacer/>

					<!--<edit-icon @click.native="editItem" :isActive="!isReadOnly"/>-->
					<!--<delete-icon v-if="tabs === 'site' && selectedItem?.siteData?.entityId"-->
					<!--			 @emitDeleteItem="deleteItem(selectedItem?.siteData)"-->
					<!--			 item-name="siteName"-->
					<!--			 :selected-item="selectedItem?.siteData"/>-->

				</div>

				<!--Tabs-->
				<v-tabs v-model="tabs">

					<!--Overview-->
					<v-tab href="#overview">
						<app-text size="small">Overview</app-text>
					</v-tab>

				</v-tabs>

				<!--Tabs Content-->
				<v-tabs-items v-model="tabs">

					<!--Overview-->
					<v-tab-item value="overview">
						<fire-roll-call-form :form-data="selectedItem"
											 :is-read-only="isReadOnly"
											 :site-data="sitesData.find(site => site.entityId === selectedItem.frcSiteId)"
											 :users-data="usersData"/>
					</v-tab-item>

				</v-tabs-items>

			</div>

		</transition>

		<!--Dialogs ------------------------------------------------------------------------------------------------ -->
		<app-dialog v-if="isStartFireRollCallDialogVisible" :is-visible="isStartFireRollCallDialogVisible">
			<fire-roll-call-start-dialog @closeDialog="closeStartFireRollCallDialog"
										 @startFireRollCall="startFireRollCall"
										 :sites-data="sitesData"/>
		</app-dialog>

	</div>

</template>

<script>

import SiteLocationsPanel from "@/views/sites/siteLocationsPanel/SiteLocationsPanel.vue";
import SitesMobileCard from "@/views/sites/siteMobileCard/SiteMobileCard.vue";
import SiteForm from "@/views/sites/sitePanel/SitePanel.vue";
import FireRollCallStartDialog from "@/views/fireRollCall/fireRollCallStartDialog/FireRollCallStartDialog.vue";
import FireRollCallRegister from "@/views/fireRollCall/fireRollCallRegister/FireRollCallRegister.vue";
import FireRollCallForm from "@/views/fireRollCall/fireRollCallForm/FireRollCallForm.vue";
import FireRollCallMobileCard from "@/views/fireRollCall/fireRollCallMobileCard/FireRollCallMobileCard.vue";

export default {

	name: "FireRollCall",

	components: {
		FireRollCallMobileCard,
		FireRollCallForm,
		FireRollCallRegister, FireRollCallStartDialog, SiteForm, SitesMobileCard, SiteLocationsPanel
	},

	data: () => ({
		filterByDateFrom: new Date().setFullYear(new Date().getFullYear() - 1),
		filterByDateTo: new Date().getTime(),
		filterBySites: [],
		filterByTypes: [],
		isFiltersPanelVisible: false,
		isLoading: true,
		isReadOnly: false,
		isRightPanelVisible: false,
		isStartFireRollCallDialogVisible: false,
		moreActionsMenuOption: [
			{name: 'Export', icon: 'export'}
		],
		searchByTitle: '',
		selectedItem: {},
		selectedSite: {},
		tableHeaders: [
			{text: 'ID', value: 'id', align: 'start', sortable: false, hidden: true},
			{text: 'Title', value: 'frcTitle', align: 'start', sortable: false},
			{text: 'Type', value: 'frcType', align: 'start', sortable: false},
			{text: 'Site', value: 'frcSiteId', align: 'start', sortable: false},
			{text: 'Date', value: 'createdDateTime', align: 'start', sortable: false},
			{text: '', value: 'action', align: 'center', sortable: false, width: '48px'},
		],
		tabs: '',
		viewType: 'tableView',

		// Data
		fireRollCallData: [],
		sitesData: [],
		siteUsersData: [],
		usersData: [],
	}),

	computed: {

		/**
		 * Computed Export CSV
		 *
		 * Return the data and headers for the CSV export
		 *
		 * @returns {{headers: {}, data: *[]}}
		 */
		computedExportCSV() {
			const t = this
			let data = []
			let headers = {}

			// Add the readable headers for the CSV columns
			headers = {
				entityId: 'ID',
				frcTitle: 'Title',
				frcType: 'Type',
				frcSiteId: 'Site',
				frcTimeTaken: 'Time Taken',
				createdDateTime: 'Date',
			}

			// Add the data
			t.computedTableData.forEach(entry => {

				const DATA_OBJECT = {
					entityId: entry?.entityId,
					frcTitle: entry?.frcTitle,
					frcType: entry?.frcType,
					frcSiteId: t.sitesData.find(site => site.entityId === entry.frcSiteId)?.siteName,
					frcTimeTaken: entry?.frcTimeTaken,
					createdDateTime: t.MIX_formatDate(entry.createdDateTime, 'numeric'),
				}

				data.push(DATA_OBJECT)
			})

			return {headers, data}
		},

		/**
		 * Computed Headers
		 *
		 * Remove hidden headers and only show the actions column to those with access.
		 *
		 * @returns an array of header objects
		 */
		computedHeaders() {
			const t = this
			let headers = t.tableHeaders

			// Remove hidden headers
			headers = headers.filter(h => !h.hidden)

			return headers
		},

		/**
		 * Computed Number of Active Filters
		 *
		 * Count the number of active filters to display in the filters button badge.
		 *
		 * @returns {*}
		 */
		computedNumberOfActiveFilters() {
			const t = this
			return [
				t.filterBySites.length,
				t.filterByTypes.length,
			].reduce((acc, curr) => acc + curr, 0)
		},

		/**
		 * Computed Table Data
		 *
		 * Sort and return the form data.
		 *
		 * @returns {array[{}]} an array of objects
		 */
		computedTableData() {
			const t = this
			let tableData = t.fireRollCallData

			// Search by Title
			if (t.searchByTitle) {
				const SEARCH_CRITERIA = t.searchByTitle.toUpperCase()
				tableData = tableData.filter(frc => {
					const FRC_TITLE = frc.frcTitle.toUpperCase()
					return FRC_TITLE.includes(SEARCH_CRITERIA)
				})
			}

			// Filter by Sites
			if (t.filterBySites.length) {
				tableData = tableData.filter(frc => t.filterBySites.some(site => site.entityId === frc.frcSiteId))
			}

			// Filter by Types
			if (t.filterByTypes.length) {
				tableData = tableData.filter(frc => t.filterByTypes.includes(frc.frcType))
			}

			// Filter by Dates
			if (t.filterByDateFrom && t.filterByDateTo) {

				// Helper functions to get the start and end of the day
				const START_OF_DAY = date => new Date(new Date(date).setHours(0, 0, 0, 0)).getTime()
				const END_OF_DAY = date => new Date(new Date(date).setHours(23, 59, 59, 999)).getTime()

				// Adjust filterByDateFrom to the start of the day and filterByDateTo to the end of the day
				const ADJUSTED_DATE_FROM = START_OF_DAY(t.filterByDateFrom)
				const ADJUSTED_DATE_TO = END_OF_DAY(t.filterByDateTo)

				tableData = tableData.filter(frc => {
					return frc.createdDateTime >= ADJUSTED_DATE_FROM && frc.createdDateTime <= ADJUSTED_DATE_TO
				})
			}

			// Sort by Site Name
			tableData = tableData.sort((a, b) => a.createdDateTime < b.createdDateTime ? 1 : -1)

			return tableData
		},

	},

	methods: {

		/**
		 * Clear Filters
		 *
		 * Clear all the filters.
		 */
		clearFilters() {
			const t = this

			t.filterBySites = []
			t.filterByTypes = []
		},

		/**
		 * Close Start Fire Roll Call Dialog
		 *
		 * Close the start fire roll call dialog.
		 */
		closeStartFireRollCallDialog() {
			this.isStartFireRollCallDialogVisible = false
		},

		/**
		 * Close Right Panel
		 *
		 * Close the right panel.
		 */
		closeRightPanel() {
			const t = this

			t.tabs = ''
			t.selectedItem = {}
			t.isRightPanelVisible = false
		},

		/**
		 * Edit Item
		 *
		 * Toggle the editability of the form.
		 */
		editItem() {
			const t = this

			t.isReadOnly = !t.isReadOnly
		},

		/**
		 * Emitted Reload Page
		 *
		 * Close the right panel and reload the data to refresh the page.
		 */
		emittedReloadPage() {
			const t = this

			t.closeRightPanel()
			t.viewType = 'tableView'
			t.loadData()
		},

		/**
		 * Export Data
		 *
		 * Export the data to CSV.
		 */
		exportData() {
			const t = this

			t.MIX_exportDocuments(t.computedExportCSV.headers, 'Fire Roll Calls', t.computedExportCSV.data)
		},

		/**
		 * Handle Emitted Menu Selection
		 *
		 * Take the emitted selection from the menu, and call the relevant function.
		 *
		 * @param selection a string-based menu option
		 */
		handleEmittedMenuSelection(selection) {
			const t = this

			if (selection === 'Export') t.exportData()
		},

		/**
		 * Load Data
		 *
		 * Load all the data required for the page.
		 *
		 * @returns {Promise<void>}
		 */
		async loadData() {
			const t = this

			t.isLoading = true

			await Promise.all([
				t.loadFireRollCallData(),
				t.loadSitesData(),
			])

			t.isLoading = false
		},

		/**
		 * Load Fire Roll Call Data
		 *
		 * Load Fire Roll Call data from the database.
		 *
		 * @returns {Promise<void>}
		 */
		async loadFireRollCallData() {
			const t = this

			// Fetch the data
			const RESPONSE = await t.MIX_redis_getAll('fireRollCall')

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Fire Roll Calls: ', RESPONSE.error)
				t.$sharedState.errorMessage = `There was an error loading the Fire Roll Call data, please try again. ${RESPONSE.error}`
				return
			}

			// Assign the data to the state
			t.fireRollCallData = RESPONSE.data
		},

		/**
		 * Load Sites Data
		 *
		 * Load Sites data from the database.
		 *
		 * @returns {Promise<void>}
		 */
		async loadSitesData() {
			const t = this

			// Fetch the data
			const RESPONSE = await t.MIX_redis_getAll('site')

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Sites: ', RESPONSE.error)
				t.$sharedState.errorMessage = `There was an error loading the Sites data, please try again. ${RESPONSE.error}`
				return
			}

			// Assign the data to the state
			t.sitesData = RESPONSE.data?.sort((a, b) => a.siteName < b.siteName ? -1 : 1)
		},

		/**
		 * Load Site Users Data
		 *
		 * Load Users data for the given site from the database.
		 *
		 * @param siteId the selected site
		 * @returns {Promise<void>}
		 */
		async loadSiteUsersData(siteId) {
			const t = this

			// Fetch the data
			const RESPONSE = await t.MIX_redis_getFireRollCallUsers(siteId)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Users: ', RESPONSE.error)
				t.$sharedState.errorMessage = `There was an error loading the Users data, please try again. ${RESPONSE.error}`
				return
			}

			// Assign the data to the state
			t.siteUsersData = RESPONSE.data
		},

		async loadUsersData() {
			const t = this

			t.isLoading = true

			// Fetch the data
			const RESPONSE = await t.MIX_redis_getAll('user')

			t.isLoading = false

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Users: ', RESPONSE.error)
				t.$sharedState.errorMessage = `There was an error loading the Users data, please try again. ${RESPONSE.error}`
				return
			}

			// Assign the data to the state
			t.usersData = RESPONSE.data
		},

		/**
		 * Open Form
		 *
		 * Open the form dialog.
		 * If no item is passed in, the form will open as new (blank).
		 *
		 * @param item the selected key
		 */
		async openRightPanel(item) {
			const t = this

			// New
			if (!item?.entityId) {
				t.isStartFireRollCallDialogVisible = true
			}

			// Existing
			else {
				await t.loadUsersData()
				t.isReadOnly = true
				t.selectedItem = item
				t.isRightPanelVisible = true
			}

		},

		/**
		 * Start Fire Roll Call
		 *
		 * Call to load the required users, and set the data to start a new Fire Roll Call.
		 *
		 * @param selectedSite the selected site
		 * @returns {Promise<void>}
		 */
		async startFireRollCall(selectedSite) {
			const t = this

			await t.loadSiteUsersData(selectedSite.entityId)

			t.isReadOnly = false
			t.selectedItem = {}
			t.selectedSite = selectedSite
			t.isStartFireRollCallDialogVisible = false
			t.viewType = 'registerView'
		},

		/**
		 * Toggle Filters Visibility
		 *
		 * Toggle the visibility of the filters panel.
		 */
		toggleFiltersVisibility() {
			const t = this

			t.isFiltersPanelVisible = !t.isFiltersPanelVisible
		},

	},

	async mounted() {
		const t = this

		await t.loadData()
	}

}
</script>

<style scoped>

</style>
