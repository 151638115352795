<template>

	<page-loading-animation v-if="isLoading" :is-loading="isLoading"/>

	<!--Page Content-->
	<div v-else>

		<!--Header-->
		<page-title :divider="true"
					icon="users"
					info="View and administer user accounts"
					title="Users"/>

		<!--Action Bar-->
		<div class="d-flex align-center mt-4">

			<!--Search-->
			<app-form-field form-type="textInput"
							append-icon="icons8-search"
							class="mr-4"
							:clearable="true"
							label="Name"
							style="width: 100%"
							v-model.trim="searchByName"/>

			<!--Add Button-->
			<!--<app-btn class="mr-4" icon="add" label="Add"/>-->

			<!--Filter Button - with a numbered badge-->
			<div style="position:relative;">
				<app-btn @click.native="toggleFiltersVisibility"
						 class="mr-4" color="appWhite" icon="filter" icon-color="primary"/>
				<span v-if="computedNumberOfActiveFilters > 0" class="badge">{{ computedNumberOfActiveFilters }}</span>
			</div>

			<!--More Actions Menu-->
			<more-actions-menu @emitMenuSelection="handleEmittedMenuSelection" :menuList="moreActionsMenuOption"/>

		</div>

		<!--Clear Filters-->
		<div v-if="computedNumberOfActiveFilters > 0" class="d-flex justify-end mt-4">
			<app-btn @click.native="clearFilters" icon="close" label="Clear Filters"/>
		</div>

		<!--Table-->
		<v-data-table v-if="$vuetify.breakpoint.width >= 600"
					  class="appWhite rounded-lg mt-4"
					  :headers="computedHeaders"
					  :items="computedTableData">

			<!--ID-->
			<template v-slot:item.entityId="{item}">
				<app-text size="small">{{ item.entityId }}</app-text>
			</template>

			<!--Status-->
			<template v-slot:item.userStatus="{item}">
				<div class="d-flex justify-space-between">
					<app-icon v-if="item.userData.userStatus === 'Pending'"
							  color="orange" icon="pending" size="24" :title="item.userData.userStatus"/>
					<app-icon v-if="item.userData.userStatus === 'Approved'"
							  color="green" icon="success" size="24" :title="item.userData.userStatus"/>
					<app-icon v-if="item.userData.userStatus === 'Rejected'"
							  color="red" icon="onHold" size="24" :title="item.userData.userStatus"/>
					<app-icon v-if="item.userData.userStatus === 'Suspended'"
							  color="red" icon="onHold" size="24" :title="item.userData.userStatus"/>
					<app-icon v-if="item.userData.userStatus === 'Deleted'"
							  color="red" icon="userDeleted" size="24" :title="item.userData.userStatus"/>

					<div class="mr-2"/>

					<app-icon v-if="item.userData.userSwappStatus === 'Out'"
							  color="grey" icon="qrCode" size="24" :title="`SWAPPed ${item.userData.userSwappStatus}`"/>
					<app-icon v-if="item.userData.userSwappStatus === 'In'"
							  color="green" icon="qrCode" size="24"
							  :title="`SWAPPed ${item.userData.userSwappStatus}`"/>
				</div>
			</template>

			<!--Name-->
			<template v-slot:item.userName="{item}">
				<app-text size="small">{{ item.userData.userName }}</app-text>
				<app-text color="grey9" size="small">{{ item.userData.userInternalIdNumber }}</app-text>
			</template>

			<!--Type-->
			<template v-slot:item.userLevel="{item}">
				<app-text size="small">{{ item.userData.userLevel }}</app-text>
			</template>

			<!--Position-->
			<template v-slot:item.userPosition="{item}">
				<app-text v-if="item.userData.userPosition" size="small">{{ item.userData.userPosition }}</app-text>
				<app-text v-else color="greyD" size="small">N/A</app-text>
			</template>

			<!--Action Button-->
			<template v-slot:item.action="{item}">
				<div class="d-flex justify-end">
					<!--<app-icon v-if="checkForMissingProfileData(item.userData)" class="flash" color="red" icon="error" title="Missing data"/>-->
					<app-icon @click.native="handleOpenRightPanel(item)"
							  class="cursorPointer" color="primary" icon="arrowForward"/>
				</div>
			</template>

		</v-data-table>

		<!--Mobile Cards-->
		<div v-if="$vuetify.breakpoint.width < 600">

			<!--No data message-->
			<app-text v-if="!computedTableData.length"
					  class="text-center mt-4" color="grey9">You have no Users to view
			</app-text>

			<users-mobile-card v-for="item in computedTableData" :key="item.userData.entityId"
							   @click.native="handleOpenRightPanel(item)"
							   :user="item"/>

		</div>

		<!--Filters Panel ------------------------------------------------------------------------------------------ -->
		<filter-panel :is-filters-panel-visible="isFiltersPanelVisible" @toggle="toggleFiltersVisibility">
			<div class="pa-4">

				<!--Statuses-->
				<page-break-title title="Statuses"/>
				<div class="mt-4"
					 style="display: grid; grid-template-columns: repeat(auto-fit, minmax(120px, 1fr)); grid-gap: 16px">
					<app-btn @click.native="handleFilterStatusSelection('Pending')"
							 :block="true"
							 color="orange"
							 label="Pending"
							 :outlined="!filterByStatuses.includes('Pending')"/>
					<app-btn @click.native="handleFilterStatusSelection('Approved')"
							 :block="true"
							 color="green"
							 label="Approved"
							 :outlined="!filterByStatuses.includes('Approved')"/>
					<app-btn @click.native="handleFilterStatusSelection('Rejected')"
							 :block="true"
							 color="red"
							 label="Rejected"
							 :outlined="!filterByStatuses.includes('Rejected')"/>
					<app-btn @click.native="handleFilterStatusSelection('Suspended')"
							 :block="true"
							 color="red"
							 label="Suspended"
							 :outlined="!filterByStatuses.includes('Suspended')"/>
				</div>

				<!--Position-->
				<page-break-title class="mt-8" title="Position"/>
				<div class="mt-4">
					<app-form-field form-type="textInput"
									label="Position"
									v-model="filterByPosition"/>
				</div>

				<!--Types-->
				<page-break-title class="mt-8" title="Types"/>
				<div class="mt-4">
					<app-form-field form-type="autoComplete"
									:deletable-chips="true"
									:items="userTypeOptions"
									label="Types"
									:multiple="true"
									:small-chips="true"
									v-model="filterByTypes"/>
				</div>

				<!--Roles-->
				<page-break-title class="mt-8" title="Roles"/>
				<div class="mt-4">
					<app-form-field form-type="autoComplete"
									:deletable-chips="true"
									:items="['Admin', 'Manager', 'User']"
									label="Roles"
									:multiple="true"
									:small-chips="true"
									v-model="filterByRoles"/>
				</div>

				<!--SWAPP Status-->
				<page-break-title class="mt-8" title="SWAPP Status"/>
				<div class="mt-4">
					<app-form-field form-type="autoComplete"
									:clearable="true"
									:items="['In', 'Out']"
									label="SWAPP Status"
									v-model="filterBySwappStatus"/>
				</div>

			</div>
		</filter-panel>

		<!--Right Side ----------------------------------------------------------------------------------- Right Side-->
		<transition enter-active-class="animate__animated animate__fadeInRight animate__faster"
					leave-active-class="animate__animated animate__fadeOutRight animate__faster"
					mode="out-in">

			<div v-if="isRightPanelVisible"
				 class="appGrey formShadow"
				 style="position:absolute; top:0; bottom: 0; right: 0; overflow: auto"
				 :style="$vuetify.breakpoint.width < 600 ? 'width: 100%' : 'width: 50%'">

				<!--Header-->
				<div class="d-flex align-center primary pa-4">
					<app-text color="appWhite" size="normal">{{ selectedItem.userData.userName }}</app-text>
					<v-spacer/>
					<app-icon @click.native="closeRightPanel" class="cursorPointer" color="white" icon="close"
							  size="32"/>
				</div>

				<!--Action Bar-->
				<div class="d-flex align-center pa-4">

					<!--User Status-->
					<div class="d-flex align-center">
						<app-icon v-if="selectedItem && selectedItem.userData.userStatus === 'Pending'"
								  class="flash mr-4" color="orange" icon="pending" size="48"/>
						<app-icon v-if="selectedItem && selectedItem.userData.userStatus === 'Approved'"
								  class="mr-4" color="green" icon="success" size="48"/>
						<app-icon v-if="selectedItem && selectedItem.userData.userStatus === 'Rejected'"
								  class="mr-4" color="red" icon="onHold" size="48"/>
						<app-icon v-if="selectedItem && selectedItem.userData.userStatus === 'Suspended'"
								  class="mr-4" color="red" icon="onHold" size="48"/>
					</div>

					<v-spacer/>

					<!--Action Buttons (Overview)-->
					<edit-icon v-if="tabs === 'overview'"
							   @click.native="editItem"
							   :isActive="!isReadOnly"/>
					<delete-icon v-if="tabs === 'overview' && ['Staff-Admin'].includes(MIX_getCurrentUser().userLevel)"
								 @emitDeleteItem="deleteUser(selectedItem.userData)"
								 itemName="userName"
								 :selectedItem="selectedItem.userData"/>

					<!--Action Buttons (Qualification Form)-->
					<edit-icon
						v-if="['Staff-Admin', 'Staff-Manager'].includes(MIX_getCurrentUser().userLevel) && tabs === 'qualifications' && isQualificationFormVisible"
						@click.native="editItem"
						:isActive="!isReadOnly"/>
					<delete-icon
						v-if="['Staff-Admin', 'Staff-Manager'].includes(MIX_getCurrentUser().userLevel) && tabs === 'qualifications' && isQualificationFormVisible"
						@emitDeleteItem="deleteQualification(selectedQualification?.qualificationData)"
						itemName="qualificationName"
						:selectedItem="selectedQualification?.qualificationData"/>

				</div>
				<!--Everyone apart from Visitors-->
				<div v-if="selectedItem.userData.userType !== 'Visitor'">

                    <!--Tabs-->
                    <v-tabs v-model="tabs">
                        <v-tab href="#overview">
                            <app-text size="small">Overview</app-text>
                        </v-tab>
                        <v-tab v-if="MIX_isFeatureAllowed('Qualifications')"
                            href="#qualifications">
                            <app-text size="small">Qualifications</app-text>
                        </v-tab>
                        <v-tab v-if="MIX_isFeatureAllowed('Induction')"
                            href="#induction">
                            <app-text size="small">Induction</app-text>
                        </v-tab>
                        <v-tab v-if="MIX_isFeatureAllowed('Orientation')"
                            href="#orientation">
                            <app-text size="small">Orientation</app-text>
                        </v-tab>
                        <v-tab href="#swappHistory">
                            <app-text size="small">SWAPP History</app-text>
                        </v-tab>
                    </v-tabs>

                    <!--Tabs Content-->
                    <v-tabs-items v-model="tabs">

                        <!--Overview-->
                        <v-tab-item value="overview">
                            <user-form @emitReloadPage="emittedReloadPage"
                                    class="pa-4"
                                    :fullUserData="selectedItem"
                                    :isReadOnly="isReadOnly"/>
                        </v-tab-item>

                        <!--Qualifications-->
                        <v-tab-item value="qualifications">

                            <user-qualifications-table v-if="!isQualificationFormVisible"
                                                    @emitHandleAddQualification="handleAddNewQualification"
                                                    @emitQualificationSelection="openSelectedQualification"
                                                    @emitReloadData="emittedReloadData"
                                                    class="appGrey px-4"
                                                    :full-user-data="selectedItem"
                                                    :isReadOnly="isReadOnly"
                                                    parentView="users"
                                                    :qualification-categories-data="qualificationCategoriesData"
                                                    :qualification-presets-data="qualificationPresetsData"/>

                            <user-qualification-form v-if="isQualificationFormVisible"
                                                    @closeQualificationForm="toggleQualificationFormVisibility"
                                                    @emitReloadData="emittedReloadData"
                                                    class="pa-4"
                                                    :formData="selectedQualification"
                                                    :full-user-data="selectedItem"
                                                    :isReadOnly="isReadOnly"
                                                    parentView="users"
                                                    :qualification-categories-data="qualificationCategoriesData"
                                                    :qualification-presets-data="qualificationPresetsData"/>

                        </v-tab-item>

                        <!--Induction-->
                        <v-tab-item class="appGrey pa-4" value="induction">
                            <user-inductions :induction-data="selectedItem?.inductionData"/>
                        </v-tab-item>

                        <!--Orientations-->
                        <v-tab-item class="appGrey pa-4" value="orientation">
                            <user-orientations :orientations-data="selectedItem?.orientationsData"/>
                        </v-tab-item>

                        <v-tab-item value="swappHistory">

                            <user-swapp-history class="appGrey pa-4"
                                                :user-data="selectedItem"/>

                        </v-tab-item>

                    </v-tabs-items>
                </div>

				<!--Visitors Only-->
				<div v-if="selectedItem.userData.userType === 'Visitor'">

					<!--Tabs-->
					<v-tabs v-model="tabs">
						<v-tab href="#overview">
							<app-text size="small">Overview</app-text>
						</v-tab>
						<v-tab href="#swappHistory">
							<app-text size="small">SWAPP History</app-text>
						</v-tab>
					</v-tabs>

					<!--Tabs Content-->
					<v-tabs-items v-model="tabs">

						<v-tab-item value="overview">
							<user-visitor-form @emitReloadPage="emittedReloadPage"
									   class="pa-4"
									   :user-data="selectedItem"
									   :is-read-only="isReadOnly"/>
						</v-tab-item>

						<v-tab-item value="swappHistory">

							<user-swapp-history class="appGrey pa-4"
												:user-data="selectedItem"/>

						</v-tab-item>

					</v-tabs-items>

				</div>

			</div>

		</transition>

	</div>

</template>

<script>
import UsersMobileCard from "@/views/users/userMobileCard/UserMobileCard";
import UserForm from "@/views/users/userForm/UserForm";
import UserQualificationsTable from "@/views/users/userQualificationsTable/UserQualificationsTable.vue";
import UserQualificationForm from "@/views/users/userQualificationForm/UserQualificationForm.vue";
import UserInductions from "@/views/users/userInductions/UserInductions.vue";
import UserOrientations from "@/views/users/userOrientations/UserOrientations.vue";
import UserSwappHistory from "@/views/users/userSwappHistory/UserSwappHistory.vue";
import UserVisitorForm from "@/views/users/userVisitorForm/UserVisitorForm.vue";

export default {

	name: "Users",

	components: {
		UserVisitorForm,
		UserSwappHistory,
		UserOrientations,
		UserInductions,
		UserQualificationForm,
		UserQualificationsTable,
		UserForm,
		UsersMobileCard
	},

	data: () => ({
		filterByPayGrades: [],
		filterByPosition: '',
		filterByRoles: [],
		filterByStatuses: [],
		filterBySwappStatus: '',
		filterByTypes: [],
		isFiltersPanelVisible: false,
		isLoading: true,
		isQualificationFormVisible: false,
		isReadOnly: false,
		isRightPanelVisible: false,

		moreActionsMenuOption: [
			{name: 'Export', icon: 'export'}
		],
		searchByName: '',
		searchByTeam: '',
		searchByLevel: '',
		selectedItem: {},
		tableHeaders: [
			{text: 'ID', value: 'id', align: 'start', sortable: false, hidden: true},
			{text: 'Status', value: 'userStatus', align: 'center', sortable: false, width: '10px'},
			{text: 'Name', value: 'userName', align: 'start', sortable: false},
			{text: 'Level', value: 'userLevel', align: 'start', sortable: false},
			{text: 'Position', value: 'userPosition', align: 'start', sortable: false},
			{text: '', value: 'action', align: 'right', sortable: false, width: '48px'},
		],
		tabs: '',

		// Data
		fullUsersData: [],
		qualificationCategoriesData: [],
		qualificationPresetsData: [],
		selectedQualification: {},
		teamsData: [],
		usersData: [],
		visitorsData: [],
	}),

	computed: {

		/**
		 * Computed Export CSV
		 *
		 * Return the data and headers for the CSV export
		 *
		 * @returns {{headers: {}, data: *[]}}
		 */
		computedExportCSV() {
			const t = this
			let data = []
			let headers = {}

			// Add the readable headers for the CSV columns
			headers = {
				entityId: 'ID',
				userStatus: 'Status',
				userInternalIdNumber: 'Internal ID',
				userName: 'Name',
				userPosition: 'Position',
				userEmail: 'Email',
				userTelephone: 'Telephone',
				userType: 'Type',
				userRole: 'Role',
				userLevel: 'Level',
			}

			// Add the data
			t.computedTableData.forEach(entry => {

				const DATA_OBJECT = {
					entityId: entry?.userData?.entityId,
					userStatus: entry?.userData?.userStatus,
					userInternalIdNumber: entry?.userData?.userInternalIdNumber,
					userName: entry?.userData?.userName,
					userPosition: entry?.userData?.userPosition,
					userEmail: entry?.userData?.userEmail,
					userTelephone: entry?.userData?.userTelephone,
					userType: entry?.userData?.userType,
					userRole: entry?.userData?.userRole,
					userLevel: entry?.userData?.userLevel,
				}

				data.push(DATA_OBJECT)
			})

			return {headers, data}
		},

		/**
		 * Computed Number of Active Filters
		 *
		 * Count the number of active filters to display in the filters button badge.
		 *
		 * @returns {*}
		 */
		computedNumberOfActiveFilters() {
			const t = this
			return [
				t.filterByStatuses.length,
				t.filterByPayGrades.length,
				t.filterByPosition ? 1 : 0,
				t.filterByTypes.length,
				t.filterByRoles.length,
				t.filterBySwappStatus ? 1 : 0,
			].reduce((acc, curr) => acc + curr, 0)
		},

		/**
		 * Computed Headers
		 *
		 * Remove hidden headers and only show the actions column to those with access.
		 *
		 * @returns an array of header objects
		 */
		computedHeaders() {
			const t = this
			const CURRENT_USER_DATA = t.MIX_getCurrentUser()
			let headers = t.tableHeaders

			// Remove hidden headers
			headers = headers.filter(h => !h.hidden)

			// Only those with access can view the actions column
			if (!['Staff-Admin'].includes(CURRENT_USER_DATA.userLevel)) headers = headers.filter(h => h.value !== 'action')

			return headers
		},

		/**
		 * Computed Table Data
		 *
		 * Sort and return the form data.
		 *
		 * @returns {array[{}]} an array of objects
		 */
		computedTableData() {
			const t = this

			const FULL_USERS_DATA = t.fullUsersData
			const VISITORS_DATA = t.visitorsData
			let tableData = [
				...FULL_USERS_DATA,
				...VISITORS_DATA.map(visitor => ({
					teamData: {},
					userData: visitor
				}))
			]

			// Search by Name
			if (t.searchByName) {
				const SEARCH_CRITERIA = t.searchByName.toUpperCase()
				tableData = tableData.filter(item => {
					const USER_NAME = item.userData.userName.toUpperCase()
					return USER_NAME.includes(SEARCH_CRITERIA)
				})
			}

			// Hide "Suspended" and "Rejected" by default
			if (!t.filterByStatuses.includes('Suspended') && !t.filterByStatuses.includes('Rejected')) {
				tableData = tableData.filter(item => item.userData.userStatus !== 'Suspended' && item.userData.userStatus !== 'Rejected')
			}

			// Filter by Status
			if (t.filterByStatuses.length) tableData = tableData.filter(item => t.filterByStatuses.includes(item.userData.userStatus))

			// Filter by Position
			if (t.filterByPosition) {
				const SEARCH_CRITERIA = t.filterByPosition.toUpperCase()
				tableData = tableData.filter(item => {
					const USER_POSITION = item.userData.userPosition.toUpperCase()
					return USER_POSITION.includes(SEARCH_CRITERIA)
				})
			}

			// Filter by Pay Grades
			if (t.filterByPayGrades.length) tableData = tableData.filter(item => t.filterByPayGrades.includes(item.userData.userPayGrade))

			// Filter by Types
			if (t.filterByTypes.length) tableData = tableData.filter(item => t.filterByTypes.includes(item.userData.userType))

			// Filter by Roles
			if (t.filterByRoles.length) tableData = tableData.filter(item => t.filterByRoles.includes(item.userData.userRole))

			// Filter by SWAPP Status
			if (t.filterBySwappStatus) tableData = tableData.filter(item => item.userData.userSwappStatus === t.filterBySwappStatus)

			tableData = tableData.sort((a, b) => a.userData.userName > b.userData.userName ? 1 : -1)

			return tableData
		},

	},

	methods: {

		/**
		 * Clear Filters
		 *
		 * Clear all the filters.
		 */
		clearFilters() {
			const t = this

			t.filterByPayGrades = []
			t.filterByPosition = ''
			t.filterByRoles = []
			t.filterByStatuses = []
			t.filterByTypes = []
			t.filterBySwappStatus = ''
		},

		/**
		 * Check For Missing Profile Data
		 *
		 * If the user is missing profile data, return true.
		 * Data is calculated by userType.
		 *
		 * @param item {object} - the user object
		 * @returns {boolean} - true if missing data
		 */
		checkForMissingProfileData(item) {
			let isMissingData = false

			// Staff
			if (item.userType === 'Staff') {
				if (!item.userPayGrade) isMissingData = true
			}

			// Steward
			if (item.userType === 'Steward') {
				if (!item.userInternalIdNumber) isMissingData = true
				if (!item.userPayGrade) isMissingData = true
				if (!item.userTeam) isMissingData = true
			}

			return isMissingData
		},

		/**
		 * Close Right Panel
		 *
		 * Close the right panel.
		 */
		closeRightPanel() {
			const t = this

			t.isQualificationFormVisible = false
			t.isRightPanelVisible = false
			t.selectedItem = {}
			t.selectedQualification = {}
			t.tabs = ''
		},

		/**
		 * Delete Qualification
		 *
		 * Delete the selected Qualification.
		 *
		 * @param itemData {object} the item to delete
		 */
		async deleteQualification(itemData) {
			const t = this

			const RESPONSE = await t.MIX_redis_delete('qualification', itemData.entityId, itemData)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error deleting Qualification', RESPONSE.error)
				return
			}

			t.$sharedState.successMessage = 'Deleted Qualification'

			// Reload the data
			await t.loadData()

			// Close the form
			t.toggleQualificationFormVisibility()
		},

		/**
		 *
		 * Delete User
		 *
		 * Delete the selected document by its ID.
		 *
		 * @param itemData {object} the item to delete
		 */
		async deleteUser(itemData) {
			const t = this

			const RESPONSE = await t.MIX_redis_delete('user', itemData.entityId, itemData)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error deleting User', RESPONSE.error)
				return
			}

			t.$sharedState.successMessage = 'Deleted User'

			// Reload the data
			await t.loadData()

			// Close the panel
			t.closeRightPanel()
		},

		/**
		 * Edit Item
		 *
		 * Toggle the editability of the form.
		 */
		editItem() {
			const t = this

			t.isReadOnly = !t.isReadOnly
		},

		/**
		 * Emitted Reload Data
		 *
		 * Emit the reload data event to the parent component.
		 */
		async emittedReloadData() {
			const t = this

			await t.loadFullUserData(t.selectedItem.userData.userFbId)

			// Close the form
			t.isQualificationFormVisible = false
		},

		/**
		 * Emitted Reload Page
		 *
		 * Close the right panel and reload the data to refresh the page.
		 */
		emittedReloadPage() {
			const t = this

			t.closeRightPanel()
			t.loadData()
		},

		/**
		 * Export Data
		 *
		 * Export the data to CSV.
		 */
		exportData() {
			const t = this

			t.MIX_exportDocuments(t.computedExportCSV.headers, 'Users', t.computedExportCSV.data)
		},

		/**
		 * Handle Add New Qualification
		 *
		 * Handle the Add New Qualification button click.
		 */
		handleAddNewQualification() {
			const t = this

			t.selectedQualification = {}
			t.isReadOnly = false
			t.isQualificationFormVisible = true
		},

		/**
		 * Handle Emitted Menu Selection
		 *
		 * Take the emitted selection from the menu, and call the relevant function.
		 *
		 * @param selection a string-based menu option
		 */
		handleEmittedMenuSelection(selection) {
			const t = this

			if (selection === 'Export') t.exportData()
		},

		/**
		 * Handle Filter Status Selection
		 *
		 * Add or remove the selected status from the filterStatuses array.
		 *
		 * @param status {string} the status to add or remove
		 */
		handleFilterStatusSelection(status) {
			const t = this

			if (t.filterByStatuses.includes(status)) t.filterByStatuses = t.filterByStatuses.filter(s => s !== status)
			else t.filterByStatuses.push(status)
		},

		/**
		 * Handle Open Right Panel
		 *
		 * Open the right panel and load the full user data.
		 *
		 * @param item {object} the selected item
		 */
		async handleOpenRightPanel(item) {
			const t = this

			t.isLoading = true

			
			if (item.userData.userType !== 'Visitor') {
				await Promise.all([
					t.loadFullUserData(item.userData.userFbId),
				])
			}
			else {
				t.openRightPanel(item)
			}

			t.isLoading = false
		},

		/**
		 * Load Data
		 *
		 * Load all the data required for the page.
		 *
		 * @returns {Promise<void>}
		 */
		async loadData() {
			const t = this

			t.isLoading = true

			await Promise.all([
				t.loadFullUsersData(),
				t.loadQualificationPresetsData(),
				t.loadQualificationCategoriesData(),
				t.loadVisitorsData(),
			])

			t.isLoading = false
		},

		/**
		 * Load Full User Data
		 *
		 * Load User data from the database.
		 *
		 * @param userFbId {string} the user's Firebase ID
		 * @returns {Promise<void>}
		 */
		async loadFullUserData(userFbId) {
			const t = this

			const RESPONSE = await t.MIX_redis_getFullUserData(userFbId)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting full User data: ', RESPONSE.error)
				return
			}

			// Assign the data to the state
			t.fullUserData = RESPONSE.data

			t.openRightPanel(RESPONSE.data)
		},

		/**
		 * Load Full Users Data
		 *
		 * Load User data from the database.
		 *
		 * @returns {Promise<void>}
		 */
		async loadFullUsersData() {
			const t = this

			const RESPONSE = await t.MIX_redis_getFullUsersData()

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting full Users data: ', RESPONSE.error)
				return
			}

			// Assign the data to the state
			t.fullUsersData = RESPONSE.data
		},

		/**
		 * Load Qualification Categories Data
		 *
		 * Load the required data from the database.
		 *
		 * @returns {Promise<void>}
		 */
		async loadQualificationCategoriesData() {
			const t = this

			// Fetch the data
			const RESPONSE = await t.MIX_redis_getAll('qualificationCategory')

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Qualfication data: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem getting the Qualification data, please try again.'
				return
			}
			// Assign the data
			t.qualificationCategoriesData = RESPONSE.data
		},

		/**
		 * Load Qualification Presets Data
		 *
		 * Load the required data from the database.
		 *
		 * @returns {Promise<void>}
		 */
		async loadQualificationPresetsData() {
			const t = this

			// Fetch the data
			const RESPONSE = await t.MIX_redis_getAll('qualificationPreset')

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Qualfication data: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem getting the Qualification data, please try again.'
				return
			}

			// Assign the data
			t.qualificationPresetsData = RESPONSE.data
		},

		/**
		 * Load Visitors Data
		 *
		 * Load the required data from the database.
		 *
		 * @returns {Promise<void>}
		 */
		async loadVisitorsData() {
			const t = this

			// Fetch the data
			const RESPONSE = await t.MIX_redis_getEntitiesByWhereAndFields(
				'Visitor',
				[{whereKey: 'userStatus', whereValue: 'Approved'}],
				[]
			)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Visitor data: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem getting the Visitor data, please try again.'
				return
			}

			// Assign the data
			t.visitorsData = RESPONSE.data
		},

		/**
		 * Open Form
		 *
		 * Open the form dialog.
		 * If no item is passed in, the form will open as new (blank).
		 *
		 * @param item the selected key
		 */
		openRightPanel(item) {
			const t = this

			// If an item is passed in, it's editable
			if (item.userData?.entityId) {
				t.isReadOnly = true
				t.selectedItem = item
			}
			// Otherwise, it's new
			else {
				t.isReadOnly = false
				t.selectedItem = {}
			}

			t.isRightPanelVisible = true
		},

		/**
		 * Open Selected Qualification
		 *
		 * Open the selected Qualification.
		 *
		 * @param item {object} the selected item
		 */
		openSelectedQualification(item) {
			const t = this

			//Set the selected qualification
			t.selectedQualification = item

			t.isQualificationFormVisible = true
		},

		/**
		 * Toggle Qualification Form Visibility
		 *
		 * Toggle the Qualification form visibility between true and false.
		 */
		toggleQualificationFormVisibility() {
			const t = this

			t.isQualificationFormVisible = !t.isQualificationFormVisible
		},

		/**
		 * Toggle Filters Visibility
		 *
		 * Toggle the visibility of the filters panel.
		 */
		toggleFiltersVisibility() {
			const t = this

			t.isFiltersPanelVisible = !t.isFiltersPanelVisible
		},

	},

	async mounted() {
		const t = this

		await t.loadData()
	},

}
</script>

<style scoped>
</style>
