<template>
	<div>

		<!--Header-->
		<div :class="$vuetify.breakpoint.width < 600 ? '' : 'd-flex align-center justify-space-between'">

			<!--Page Title-->
			<page-title icon="eye"
						info="View and administer Reports."
						title="Reporting"/>

			<!--Report Type-->
			<app-form-field form-type="select"
							:class="$vuetify.breakpoint.width < 600 ? 'mt-4' : ''"
							:items="computedReportingOptions"
							label="Report Type"
							style="min-width: 216px"
							v-model="pageType"/>

		</div>

		<v-divider class="mt-4"/>

		<div v-if="!pageType" class="d-flex align-center justify-center"
			 :style="$vuetify.breakpoint.width < 600 ? 'height: 50vh' : 'height:70vh'">
			<page-start-information title="What do you want to do?"
									:points="['Select a Report Type to get started']"/>
		</div>

		<accidents-page v-if="pageType === 'Accident'"/>

		<observations-page v-if="pageType === 'Observation'"/>

		<debrief-page v-if="pageType === 'Debrief'"/>

		<ejection-page v-if="pageType === 'Ejection'"/>

	</div>
</template>

<script>
import ObservationsPage from "@/views/reporting/observations/ObservationsPage.vue";
import EjectionPage from "@/views/reporting/ejections/EjectionsPage";
import DebriefPage from "@/views/reporting/debriefs/DebriefPage";
import AccidentsPage from "@/views/reporting/accidents/AccidentsPage.vue";

export default {

	name: "Reporting",

	components: {
		AccidentsPage,
		DebriefPage,
		EjectionPage,
		ObservationsPage,
	},

	data: () => ({
		pageType: '',
	}),

	computed: {

		computedReportingOptions() {
			const t = this
			const CURRENT_USER_DATA = t.MIX_getCurrentUser()
			let reportingOptions = []

			if (CURRENT_USER_DATA.userLevel === 'Staff-Admin') reportingOptions = ['Observation']
			if (CURRENT_USER_DATA.userLevel === 'Staff-User') reportingOptions = ['Observation']

			if (CURRENT_USER_DATA.userLevel === 'Contractor-Manager') reportingOptions = ['Observation']

			return reportingOptions
		}

	},

	mounted() {
		const t = this

		// If there is a Report Type, use it
		// This is so the correct form, type can be presented when hitting this page from another
		const PAGE_TYPE = t.MIX_getFromLocalStorage('selectedReportType')
		if (PAGE_TYPE) {
			t.pageType = PAGE_TYPE
			t.MIX_deleteFromLocalStorage('selectedReportType')
		}
	}

}
</script>

<style scoped>
</style>
