<template>
	<div class="appGrey" style="min-height: 100%">

		<page-loading-animation :is-loading="isLoading"/>

		<!--Teams-->
		<div v-if="!isLoading">

			<!--Form-->
			<div class="d-flex align-center justify-space-between">

				<!--Teams Selection-->
				<app-form-field form-type="autoComplete"
								:deletable-chips="true"
								:disabled="!isSelectionEditable"
								:items="computedAvailableTeamsData"
								item-text="teamName"
								label="Teams"
								:multiple="true"
								:return-object="true"
								:small-chips="true"
								style="width: 100%"
								v-model="tempEventTeamsData"/>

				<!--Select All Button-->
				<app-btn v-if="isSelectionEditable"
						 @click.native="handleAddAllButton"
						 class="ml-4"
						 :color="(tempEventTeamsData.length === computedAvailableTeamsData.length) && 'grey'"
						 :label="tempEventTeamsData.length === computedAvailableTeamsData.length ? 'All Selected' : 'Select All'"/>

				<!--Save Button-->
				<app-btn v-if="isSelectionEditable"
						 @click.native="saveEventTeams(tempEventTeamsData)"
						 class="ml-4"
						 color="green"
						 :disabled="!tempEventTeamsData.length"
						 icon="save"
						 label="Save"/>

				<!--Edit Button-->
				<edit-icon @click.native="editItem" :isActive="isSelectionEditable"/>

			</div>

			<!--Table-->
			<v-data-table class="appWhite rounded-lg mt-4"
						  :items="computedTableData"
						  :headers="tableHeaders">

				<!--Team Name-->
				<template v-slot:item.teamName="{item}">
					<app-text size="small">{{ item.eventTeamExtraData.teamName }}</app-text>
				</template>

				<!--Steward Start/Finish-->
				<template v-slot:item.eventTeamStewardsTimes="{item}">
					<app-text size="small">
						{{ item.eventTeamData.eventTeamStewardsStartTime }}
						-
						{{ item.eventTeamData.eventTeamStewardsFinishTime }}
					</app-text>
				</template>

				<!--Supervisor Start/Finish-->
				<template v-slot:item.eventTeamSupervisorsTimes="{item}">
					<app-text size="small">
						{{ item.eventTeamData.eventTeamSupervisorsStartTime }}
						-
						{{ item.eventTeamData.eventTeamSupervisorsFinishTime }}
					</app-text>
				</template>

				<!--Quota-->
				<template v-slot:item.eventTeamQuota="{item}">
					<app-text size="small">{{ item.eventTeamData.eventTeamQuota }}</app-text>
				</template>

				<!--Members-->
				<template v-slot:item.numberOfTeamMembers="{item}">
					<app-text size="small">{{ item.eventTeamExtraData.numberOfTeamMembers }}</app-text>
				</template>

				<!--Available-->
				<template v-slot:item.numberOfAvailableMembers="{item}">
					<app-text color="orange" size="small-bold">{{ item.eventTeamExtraData.numberOfAvailableMembers }}
					</app-text>
				</template>

				<!--Confirmed-->
				<template v-slot:item.numberOfConfirmedMembers="{item}">
					<app-text color="green" size="small-bold">{{ item.eventTeamExtraData.numberOfConfirmedMembers }}
					</app-text>
				</template>

				<!--Reserved-->
				<template v-slot:item.numberOfReservedMembers="{item}">
					<app-text color="blue" size="small-bold">{{ item.eventTeamExtraData.numberOfReservedMembers }}
					</app-text>
				</template>

				<!--Balance-->
				<template v-slot:item.teamBalance="{item}">
					<app-text
						:color="item.eventTeamExtraData.teamBalance === 0 ? 'grey' : item.eventTeamExtraData.teamBalance < 0 ? 'red' : 'green'"
						size="small-bold">{{ item.eventTeamExtraData.teamBalance }}
					</app-text>
				</template>

				<!--Action-->
				<template v-slot:item.action="{item}">
					<more-actions-menu @emitMenuSelection="emittedMenuSelection($event, item)"
									   :button="false"
									   :menuList="moreActionsMenuOption"/>
				</template>

			</v-data-table>

		</div>

		<!--Dialogs ----------------------------------------------------------------------------------------- Dialogs-->

		<!--Steward Times-->
		<app-dialog :is-visible="isStewardsTimesDialogVisible">
			<event-stewards-times-dialog @emitCloseDialog="closeStewardsTimesDialog"
										 @emitUpdateStewardsTimes="emittedUpdateStewardsTimes"
										 :startTime="selectedEventTeamTableRow.eventTeamStewardsStartTime"
										 :finishTime="selectedEventTeamTableRow.eventTeamStewardsFinishTime"/>
		</app-dialog>

		<!--Supervisor/Deputy Times-->
		<app-dialog :is-visible="isSupervisorsTimesDialogVisible">
			<event-supervisors-times-dialog @emitCloseDialog="closeSupervisorsTimesDialog"
											@emitUpdateSupervisorsTimes="emittedUpdateSupervisorsTimes"
											:startTime="selectedEventTeamTableRow.eventTeamSupervisorsStartTime"
											:finishTime="selectedEventTeamTableRow.eventTeamSupervisorsFinishTime"/>
		</app-dialog>

		<!--Quota-->
		<app-dialog :is-visible="isTeamQuotaDialogVisible">
			<event-quota-dialog @emitCloseDialog="closeTeamQuotaDialog"
								@emitUpdatedTeamQuota="emittedUpdatedTeamQuota"
								:teamQuota="selectedEventTeamTableRow.eventTeamQuota"/>
		</app-dialog>

		<!--Remove Team-->
		<app-dialog :is-visible="isRemoveTeamDialogVisible">
			<event-remove-team-dialog @emitCloseDialog="closeRemoveTeamDialog"
									  @emitDeleteEventTeam="emittedDeleteEventTeam"/>
		</app-dialog>

	</div>
</template>

<script>
import EventStewardsTimesDialog from "@/views/events/admin/eventStewardsTimesDialog/EventStewardsTimesDialog.vue";
import EventSupervisorsTimesDialog
	from "@/views/events/admin/eventSupervisorsTimesDialog/EventSupervisorsTimesDialog.vue";
import EventQuotaDialog from "@/views/events/admin/eventQuotaDialog/EventQuotaDialog.vue";
import EventRemoveTeamDialog from "@/views/events/admin/eventRemoveTeamDialog/EventRemoveTeamDialog.vue";

export default {

	name: "EventTeamsForm",

	components: {EventRemoveTeamDialog, EventQuotaDialog, EventSupervisorsTimesDialog, EventStewardsTimesDialog},

	props: ['eventData', 'isReadOnly', 'teamsData'],

	data: () => ({
		currentEventTeamsData: [],
		isLoading: true,
		isSelectionEditable: false,
		isRemoveTeamDialogVisible: false,
		isStewardsTimesDialogVisible: false,
		isSupervisorsTimesDialogVisible: false,
		isTeamQuotaDialogVisible: false,
		moreActionsMenuOption: [
			{name: 'Steward Start/Finish Time', icon: 'time'},
			{name: 'Supervisor Start/Finish Time', icon: 'time'},
			{name: 'Event Quota', icon: 'users'},
			{name: 'Remove', icon: 'delete'},
		],
		selectedEventTeamTableRow: {},
		tableHeaders: [
			{text: 'Name', value: 'teamName', sortable: false, align: 'start'},
			{text: 'Steward Times', value: 'eventTeamStewardsTimes', sortable: false, align: 'center'},
			{text: 'Supervisor Times', value: 'eventTeamSupervisorsTimes', sortable: false, align: 'center'},
			{text: 'Quota', value: 'eventTeamQuota', sortable: false, align: 'center'},
			{text: 'Members', value: 'numberOfTeamMembers', sortable: false, align: 'center'},
			{text: 'Available', value: 'numberOfAvailableMembers', sortable: false, align: 'center'},
			{text: 'Confirmed', value: 'numberOfConfirmedMembers', sortable: false, align: 'center'},
			{text: 'Reserved', value: 'numberOfReservedMembers', sortable: false, align: 'center'},
			{text: 'Balance', value: 'teamBalance', sortable: false, align: 'center'},
			{text: '', value: 'action', sortable: false, align: 'center', width: '10px'},
		],
		tempEventTeamsData: [],

		// Data
		eventTeamsData: [],
		eventTeamsFiguresData: [],
	}),

	computed: {

		/**
		 * Computed Table Data
		 *
		 * Create and return an array of modified EventTeams data.
		 * Each table object contains the EventTeam data, and the extra data required for the table.
		 *
		 * @returns {array} - an array of modified EventTeam (with extra table data), objects
		 */
		computedTableData() {
			const t = this
			const TEAMS_DATA = t.$props.teamsData
			const EVENT_TEAMS_FIGURES_DATA = t.eventTeamsFiguresData
			let currentEventTeamsData = t.currentEventTeamsData
			let tableData = []

			currentEventTeamsData.forEach(et => {

				// Get the Team data for the current EventTeam
				const TEAM_DATA = TEAMS_DATA.find(t => t.entityId === et.eventTeamTeamId)
				const TEAM_FIGURES_DATA = EVENT_TEAMS_FIGURES_DATA.find(tf => tf.teamId === TEAM_DATA.entityId)

				// Create a table object (has transient values so needs to be constructed)
				const TABLE_OBJECT = {
					eventTeamData: {
						...et,
					},
					eventTeamExtraData: {
						teamName: TEAM_DATA.teamName,
						numberOfTeamMembers: TEAM_FIGURES_DATA.numberOfTeamMembers,
						numberOfAvailableMembers: TEAM_FIGURES_DATA.numberOfAvailableMembers,
						numberOfConfirmedMembers: TEAM_FIGURES_DATA.numberOfConfirmedMembers,
						numberOfReservedMembers: TEAM_FIGURES_DATA.numberOfReservedMembers,
						teamBalance: TEAM_FIGURES_DATA.teamBalance,
					},
				}

				// Add the table object to the table data array
				tableData.push(TABLE_OBJECT)
			})

			return tableData
		},

		/**
		 * Available Teams Data
		 *
		 * Return an array of available Teams, (not already saved to this Event).
		 *
		 * @returns {array} An array of available Teams
		 */
		computedAvailableTeamsData() {
			const t = this
			const TEAMS_DATA = t.$props.teamsData
			const CURRENT_EVENT_TEAMS_DATA = t.currentEventTeamsData

			return TEAMS_DATA.filter(team => {
				return !CURRENT_EVENT_TEAMS_DATA.some(eventTeam => {
					return eventTeam.eventTeamTeamId === team.entityId
				})
			})

		},

	},

	methods: {

		/**
		 * Clear Errors
		 *
		 * Clear all errors and their messages.
		 */
		clearErrors() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		/**
		 * Close Remove Team Dialog
		 *
		 * Close the Remove Team dialog.
		 */
		closeRemoveTeamDialog() {
			const t = this

			t.isRemoveTeamDialogVisible = false
		},

		/**
		 * Close Team Quota Dialog
		 *
		 * Close the Quota dialog.
		 */
		closeTeamQuotaDialog() {
			const t = this

			t.isTeamQuotaDialogVisible = false
		},

		/**
		 * Close Stewards Times Dialog
		 *
		 * Close the Times dialog.
		 */
		closeStewardsTimesDialog() {
			const t = this

			t.isStewardsTimesDialogVisible = false
		},

		/**
		 * Close Stewards Times Dialog
		 *
		 * Close the Times dialog.
		 */
		closeSupervisorsTimesDialog() {
			const t = this

			t.isSupervisorsTimesDialogVisible = false
		},

		/**
		 * Delete Event Team
		 *
		 * Delete the selected EventTeam from this Event.
		 * If there are any EventUser document (Event has been published), these will also be removed.
		 *
		 * @param eventTeam - the EventTeam to remove
		 * @returns {Promise<void>}
		 */
		async deleteEventTeam(eventTeam) {
			const t = this

			const RESPONSE = await t.MIX_redis_deleteEventTeamFromEvent(eventTeam.entityId)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error Removing Team: ', RESPONSE.error)
				return
			}

		},

		/**
		 * Edit Item
		 *
		 * Toggle the editability of the form.
		 */
		editItem() {
			const t = this

			t.isSelectionEditable = !t.isSelectionEditable
		},

		/**
		 * Handle Emitted Menu Selection
		 *
		 * Take the emitted selection from the menu, and call the relevant function.
		 *
		 * @param selection - a string-based menu option
		 * @param tableRow - the EventTeams data from the table
		 */
		emittedMenuSelection(selection, tableRow) {
			const t = this

			// Set the selected EventTeam row data
			t.selectedEventTeamTableRow = tableRow.eventTeamData

			if (selection === 'Steward Start/Finish Time') t.openStewardsTimesDialog()
			if (selection === 'Supervisor Start/Finish Time') t.openSupervisorsTimesDialog()
			if (selection === 'Event Quota') t.openTeamQuotaDialog()
			if (selection === 'Remove') t.openRemoveTeamDialog()

		},

		/**
		 * Emitted Delete Event Team
		 *
		 * Call to delete the EventTeam, close the dialog, and reload the page.
		 *
		 * @returns {Promise<void>}
		 */
		emittedDeleteEventTeam() {
			const t = this

			t.deleteEventTeam(t.selectedEventTeamTableRow)

			t.$sharedState.successMessage = 'Removed Team'

			t.closeRemoveTeamDialog()
			t.reloadPage()
		},

		/**
		 * Emitted Update Quota
		 *
		 * Take the emitted Quota and update the EventTeam.
		 *
		 * @param teamQuota - the new Quota to update to
		 */
		emittedUpdatedTeamQuota(teamQuota) {
			const t = this
			let eventTeamData = t.selectedEventTeamTableRow

			eventTeamData.eventTeamQuota = teamQuota

			t.updateEventTeam()
		},

		/**
		 * Emitted Update Stewards Times
		 *
		 * Take the emitted data from the time picker and update the start/finish times.
		 *
		 * @param times - the new start/finish times to update to
		 */
		emittedUpdateStewardsTimes(times) {
			const t = this
			let eventTeamData = t.selectedEventTeamTableRow

			eventTeamData.eventTeamStewardsStartTime = times.newStartTime
			eventTeamData.eventTeamStewardsFinishTime = times.newFinishTime

			t.updateEventTeam()
		},

		/**
		 * Emitted Update Supervisors Times
		 *
		 * Take the emitted data from the time picker and update the start/finish times.
		 *
		 * @param times - the new start/finish times to update to
		 */
		emittedUpdateSupervisorsTimes(times) {
			const t = this
			let eventTeamData = t.selectedEventTeamTableRow

			eventTeamData.eventTeamSupervisorsStartTime = times.newStartTime
			eventTeamData.eventTeamSupervisorsFinishTime = times.newFinishTime

			t.updateEventTeam()
		},

		/**
		 * Handle Add All Button
		 *
		 * Handles the action when the "Select All" button is pressed.
		 * Adds all teams from the database to the event that are not already associated with the event,
		 * or present in the temporary selection list.
		 *
		 * @returns {void}
		 */
		handleAddAllButton() {
			const t = this

			// Fetch the teams already added to the event
			const CURRENT_EVENT_TEAMS = t.currentEventTeamsData

			// Check if the temporary teams list already includes all available teams.
			// If so, exit early and do nothing.
			if (t.tempEventTeamsData.length === t.computedAvailableTeamsData.length) return

			// Loop through all teams
			t.teamsData.forEach(team => {
				// Add teams that are:
				// 1) Not already added to the event.
				// 2) Not already present in the tempEventTeamsData.
				if (
					!CURRENT_EVENT_TEAMS.some(eventTeam => eventTeam.eventTeamTeamId === team.entityId)
					&&
					!t.tempEventTeamsData.some(tempTeam => tempTeam.entityId === team.entityId)
				) {
					t.tempEventTeamsData.push(team)
				}
			})
		},

		/**
		 * Load Data
		 *
		 * Load all the data required for the page.
		 *
		 * @returns {Promise<void>}
		 */
		async loadData() {
			const t = this
			const EVENT_DATA = t.$props.eventData

			t.isLoading = true

			await Promise.all([
				t.loadEventTeamsData(EVENT_DATA.entityId),
				t.loadEventTeamsFiguresByEvent(EVENT_DATA.entityId)
			])

			t.isLoading = false
		},

		/**
		 * Load EventTeams Data
		 *
		 * Load EventTeams data from the database.
		 *
		 * @returns {Promise<void>}
		 */
		async loadEventTeamsData(eventId) {
			const t = this

			// Fetch the data
			const RESPONSE = await t.MIX_redis_getEventTeamsByEvent(eventId)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting EventTeams: ', RESPONSE.error)
				return
			}

			// Assign the data to the state
			t.currentEventTeamsData = RESPONSE.data
		},

		/**
		 * Load EventTeams Figures By Event
		 *
		 * Load the EventTeams status figures for the current Event.
		 * This will return an array of objects, each with the calculated figures for the statuses.
		 *
		 * @returns {Promise<void>}
		 */
		async loadEventTeamsFiguresByEvent(eventId) {
			const t = this

			// Fetch the data
			const RESPONSE = await t.MIX_redis_getEventTeamsFiguresByEvent(eventId)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting EventUsers status figures: ', RESPONSE.error)
				return
			}

			t.eventTeamsFiguresData = RESPONSE.data
		},

		/**
		 * Open Remove Team Dialog
		 *
		 * Open the Remove Team dialog.
		 */
		openRemoveTeamDialog() {
			const t = this

			t.isRemoveTeamDialogVisible = true
		},

		/**
		 * Open Stewards Times Dialog
		 *
		 * Open the Times dialog.
		 */
		openStewardsTimesDialog() {
			const t = this

			t.isStewardsTimesDialogVisible = true
		},

		/**
		 * Open Stewards Times Dialog
		 *
		 * Open the Times dialog.
		 */
		openSupervisorsTimesDialog() {
			const t = this

			t.isSupervisorsTimesDialogVisible = true
		},

		/**
		 * Open Quota Dialog
		 *
		 * Open the Quota dialog.
		 */
		openTeamQuotaDialog() {
			const t = this

			t.isTeamQuotaDialogVisible = true
		},

		/**
		 * Reload Data
		 *
		 * Emit back to the parent to reload the data.
		 */
		reloadData() {
			const t = this

			t.$emit('emitReloadData')
		},

		/**
		 * Reload Page
		 *
		 * Emit back to the parent to reload the page.
		 */
		reloadPage() {
			const t = this

			t.$emit('emitReloadPage')
		},

		/**
		 * Save Event Teams
		 *
		 * Send the new eventTeamsData array off to the server to create/update the Teams working this Event.
		 * This will update the EventTeams collection.
		 */
		async saveEventTeams(eventTeamsData) {
			const t = this
			const EVENT_DATA = t.$props.eventData

			if (!t.tempEventTeamsData.length) return

			eventTeamsData = {eventData: EVENT_DATA, eventTeamsData}

			// Create EventTeams
			const RESPONSE = await t.MIX_redis_createEventTeams(eventTeamsData)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error creating EventTeams: ', RESPONSE.error)
				return
			}

			t.$sharedState.successMessage = 'Updated Event Teams'

			t.isSelectionEditable = false

			t.reloadPage()
		},

		/**
		 * Update Event Team
		 *
		 * Update a single Event Team with the required new data.
		 * Close time dialogs when done.
		 *
		 * @returns {Promise<void>}
		 */
		async updateEventTeam() {
			const t = this

			const RESPONSE = await t.MIX_redis_update('eventTeam', t.selectedEventTeamTableRow.entityId, t.selectedEventTeamTableRow)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error updating EventTeam: ', RESPONSE.error)
				return
			}

			t.$sharedState.successMessage = 'Updated'

			t.closeStewardsTimesDialog()
			t.closeSupervisorsTimesDialog()
			t.closeTeamQuotaDialog()
		},

		/**
		 * Validate Form
		 *
		 * Validate the form as required.
		 *
		 * @returns {boolean} if the form has passed validation, or not
		 */
		validateForm() {
			const t = this

			t.clearErrors()

			return !Object.values(t.errors).includes(true)
		}

	},

	async created() {
		const t = this

		await t.loadData()
	},

}
</script>

<style scoped>

</style>
