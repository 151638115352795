<template>
	<div class="appGrey" style="min-height: 100%">

		{{ computedInit }}

		<!--No Locations Message-->
		<app-text
			v-if="formData?.entityId && !locationsData.filter(location => location.locationSite === formData.entityId)?.length"
			class="my-4" color="red" size="small">
			This Site has no Locations, so it cannot be SWAPPed into
		</app-text>

		<!--Site-->
		<form-section-title title="Site"/>
		<v-row no-gutters>

			<!--Site Name-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? '' : 'pr-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="textInput"
								:disabled="isReadOnly"
								:error="errors.siteName"
								:error-message="errors.siteNameErrorMessage"
								label="Site Name"
								v-model.trim="form.siteName"/>
			</v-col>

			<!--Site Telephone-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'pl-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="textInput"
								:disabled="isReadOnly"
								:error="errors.siteTelephone"
								:error-message="errors.siteTelephoneErrorMessage"
								label="Site Telephone"
								v-model.trim="form.siteTelephone"/>
			</v-col>

		</v-row>

		<!--Address-->
		<form-section-title class="mt-4" title="Address"/>
		<v-row no-gutters>

			<!--Line 1-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? '' : 'pr-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="textInput"
								:disabled="isReadOnly"
								:error="errors.siteAddressLine1"
								:error-message="errors.siteAddressLine1ErrorMessage"
								label="Line 1"
								v-model.trim="form.siteAddressLine1"/>
			</v-col>

			<!--Line 2-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'pl-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="textInput"
								:disabled="isReadOnly"
								label="Line 2 (optional)"
								v-model.trim="form.siteAddressLine2"/>
			</v-col>

			<!--Town-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="textInput"
								:disabled="isReadOnly"
								label="Town (optional)"
								v-model.trim="form.siteAddressTown"/>
			</v-col>

			<!--City-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pl-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="textInput"
								:disabled="isReadOnly"
								label="City (optional)"
								v-model.trim="form.siteAddressCity"/>
			</v-col>

			<!--County-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="textInput"
								:disabled="isReadOnly"
								label="County (optional)"
								v-model.trim="form.siteAddressCounty"/>
			</v-col>

			<!--Postcode-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pl-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="textInput"
								:disabled="isReadOnly"
								:error="errors.siteAddressPostcode"
								:error-message="errors.siteAddressPostcodeErrorMessage"
								label="Postcode (optional)"
								v-model.trim="form.siteAddressPostcode"/>
			</v-col>

			<!--Latitude-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="textInput"
								:disabled="isReadOnly"
								:error="errors.siteAddressCoordsLatitude"
								:error-message="errors.siteAddressCoordsLatitudeErrorMessage"
								label="Latitude (optional)"
								v-model.trim.number="form.siteAddressCoords.latitude"/>
			</v-col>

			<!--Longitude-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pl-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="textInput"
								:disabled="isReadOnly"
								:error="errors.siteAddressCoordsLongitude"
								:error-message="errors.siteAddressCoordsLongitudeErrorMessage"
								label="Longitude (optional)"
								v-model.trim.number="form.siteAddressCoords.longitude"/>
			</v-col>

		</v-row>

		<!--Site Contact-->
		<form-section-title class="mt-4"
							description="You can set the site manager here, but their details will need to be updated in Users."
							title="Site Contact"/>
		<v-row no-gutters>

			<!--Name-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? '' : 'pr-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="select"
								:disabled="isReadOnly"
								:error="errors.siteContactObject"
								:error-message="errors.siteContactObjectErrorMessage"
								:items="usersData"
								item-text="userName"
								label="Name"
								:return-object="true"
								v-model="siteContactObject"/>
			</v-col>

			<!--Position-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'pl-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="textInput"
								:disabled="true"
								label="Position"
								v-model.trim="siteContactUserPosition"/>
			</v-col>

			<!--Telephone-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="textInput"
								:disabled="true"
								label="Telephone"
								v-model.trim="siteContactUserTelephone"/>
			</v-col>

			<!--Email-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pl-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="textInput"
								:disabled="true"
								label="Email"
								v-model.trim="siteContactUserEmail"/>
			</v-col>

		</v-row>

		<!--Site Notes-->
		<form-section-title class="mt-4" title="Notes"/>
		<v-row no-gutters>

			<!--Notes-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? '' : ''"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
				<app-form-field form-type="textArea"
								:disabled="isReadOnly"
								label="Notes"
								v-model.trim="form.siteNotes"/>
			</v-col>

		</v-row>

		<!--Save Button-->
		<div class="d-flex justify-end mt-4">
			<app-btn v-if="!isReadOnly"
					 @click.native="saveItem"
					 color="green"
					 icon="save"
					 label="Save"/>
		</div>

	</div>
</template>

<script>
export default {

	name: "SitePanel",

	props: ['formData', 'isReadOnly', 'locationsData', 'usersData'],

	data: () => ({
		errors: {
			siteName: false,
			siteNameErrorMessage: '',
			siteTelephone: false,
			siteTelephoneErrorMessage: '',
			siteAddressLine1: false,
			siteAddressLine1ErrorMessage: '',
			siteAddressPostcode: false,
			siteAddressPostcodeErrorMessage: '',
			siteAddressCoordsLatitude: false,
			siteAddressCoordsLatitudeErrorMessage: '',
			siteAddressCoordsLongitude: false,
			siteAddressCoordsLongitudeErrorMessage: '',
			siteContactObject: false,
			siteContactObjectErrorMessage: '',
		},
		form: {
			entityId: '',
			siteName: '',
			siteTelephone: '',
			siteAddressLine1: '',
			siteAddressLine2: '',
			siteAddressTown: '',
			siteAddressCity: '',
			siteAddressCounty: '',
			siteAddressPostcode: '',
			siteAddressCoords: {latitude: null, longitude: null},
			siteContact: '',
			siteNotes: '',

			createdUserId: '',
			createdDateTime: 0,
			createdUserName: '',
			modifiedUserId: '',
			modifiedDateTime: 0,
			modifiedUserName: '',
			isDeleted: false,
			deletedUserId: '',
			deletedDateTime: 0,
			deletedUserName: '',
		},

		// Site Contact data
		siteContactObject: {},
		siteContactUserPosition: '',
		siteContactUserTelephone: '',
		siteContactUserEmail: '',
	}),

	computed: {

		/**
		 * Computed Init
		 *
		 * If there is any form data, assign it to the form.
		 * If there is no form data, it will be blank (new).
		 */
		computedInit() {
			const t = this
			const formData = t.$props.formData

			if (formData?.entityId) t.form = formData
		},

	},

	methods: {

		/**
		 * Clear Errors
		 *
		 * Clear all errors and their messages.
		 */
		clearErrors() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		/**
		 * Save Item
		 *
		 * Save/Update the form to the DB, after it has passed validation.
		 */
		async saveItem() {
			const t = this
			let response

			if (!t.validateForm()) return

			// Convert the siteContact to an ID to save
			t.form.siteContact = t.siteContactObject?.entityId || ''

			// If the Latitude or Longitude are null, set them to 0
			if (!t.form.siteAddressCoords.latitude || !t.form.siteAddressCoords.longitude) {
				t.form.siteAddressCoords.latitude = 0
				t.form.siteAddressCoords.longitude = 0
			}

			// Create
			if (!t.form.entityId) {

				// Redis throws an error for null entries, so 0 the coordinates if they're not set
				if (!t.form.siteAddressCoords.latitude || !t.form.siteAddressCoords.latitude) {
					t.form.siteAddressCoords.latitude = 0
					t.form.siteAddressCoords.longitude = 0
				}

				response = await t.MIX_redis_create('site', t.form)

				// Handle any errors
				if (response.hasErrors) {
					console.error('Error creating Site: ', response.error)
					return
				}

				t.$sharedState.successMessage = 'Created Site'
			}

			// Update
			if (t.form.entityId) {

				response = await t.MIX_redis_update('site', t.form.entityId, t.form)

				// Handle any errors
				if (response.hasErrors) {
					console.error('Error updating Site: ', response.error)
					return
				}

				t.$sharedState.successMessage = 'Updated Site'
			}

			t.$emit('emitReloadPage')
		},

		/**
		 * Validate Form
		 *
		 * Validate the form as required.
		 *
		 * @returns {boolean} if the form has passed validation, or not
		 */
		validateForm() {
			const t = this

			t.clearErrors()

			// Site Name
			const SITE_NAME = t.form.siteName
			if (!SITE_NAME) {
				t.errors.siteName = true
				t.errors.siteNameErrorMessage = 'Site required'
			}

			// Site Telephone
			const SITE_TELEPHONE_NUMBER = t.form.siteTelephone
			if (SITE_TELEPHONE_NUMBER) {
				const VALIDATED_NUMBER = t.MIX_isTelephoneNumberValid(SITE_TELEPHONE_NUMBER)
				if (!VALIDATED_NUMBER) {
					t.errors.siteTelephone = true
					t.errors.siteTelephoneErrorMessage = 'Invalid Telephone Number'
				} else {
					t.form.siteTelephone = VALIDATED_NUMBER
				}
			}

			// Site Address Line 1
			const SITE_ADDRESS_LINE1 = t.form.siteAddressLine1
			if (
				!SITE_ADDRESS_LINE1
			) {
				t.errors.siteAddressLine1 = true
				t.errors.siteAddressLine1ErrorMessage = 'Address Line 1 is required'
			}

// Postcode
			const SITE_POSTCODE = t.form.siteAddressPostcode
			if (SITE_POSTCODE && !t.MIX_isPostcodeValid(SITE_POSTCODE)) {
				t.errors.siteAddressPostcode = true
				t.errors.siteAddressPostcodeErrorMessage = 'Postcode is not in the expected format'
			}

// Coordinates
			if (t.form.siteAddressCoords.latitude && t.form.siteAddressCoords.longitude) {
				const COORDS = t.form.siteAddressCoords
				const RESULT = t.MIX_isLatLngValid(COORDS)

				if (!RESULT.isLatValid) {
					t.errors.siteAddressCoordsLatitude = true
					t.errors.siteAddressCoordsLatitudeErrorMessage = 'Latitude is not in the expected format'
				}

				if (!RESULT.isLngValid) {
					t.errors.siteAddressCoordsLongitude = true
					t.errors.siteAddressCoordsLongitudeErrorMessage = 'Longitude is not in the expected format'
				}
			}

// Site Contact
			const SITE_CONTACT = t.siteContactObject
			if (!SITE_CONTACT?.entityId) {
				t.errors.siteContactObject = true
				t.errors.siteContactObjectErrorMessage = 'Site Contact is required'
			}

			return !Object.values(t.errors).includes(true)
		}

	},

	watch: {

		/**
		 * Update Site Contact's read only details when the SiteContact changes.
		 *
		 * @param siteContact the siteContact User object
		 */
		'siteContactObject'(siteContact) {
			const t = this

			t.siteContactUserPosition = siteContact?.userPosition
			t.siteContactUserTelephone = siteContact?.userTelephone
			t.siteContactUserEmail = siteContact?.userEmail
		}
		,

	},

	mounted() {
		const t = this

		// Get the user object from its ID
		t.siteContactObject = t.$props.usersData.find(u => u.entityId === t.form.siteContact)
	}

}
</script>

<style scoped>

</style>
