<template>

	<page-loading-animation v-if="isLoading" :is-loading="isLoading"/>

	<!--Page Content-->
	<div v-else>

		<!--Action Bar-->
		<div class="d-flex align-center mt-4">

			<!--Search-->
			<app-form-field form-type="textInput"
							append-icon="icons8-search"
							class="mr-4"
							:clearable="true"
							label="Question"
							style="width: 100%"
							v-model.trim="searchByQuestion"/>

			<!--Add Button-->
			<app-btn @click.native="openRightPanel" icon="add" label="Add"/>

			<!--Filter Button - with a numbered badge-->
			<!--<div style="position:relative;">-->
			<!--	<app-btn @click.native="toggleFiltersVisibility"-->
			<!--			 class="mr-4" color="appWhite" icon="filter" icon-color="primary"/>-->
			<!--	<span v-if="computedNumberOfActiveFilters > 0" class="badge">{{ computedNumberOfActiveFilters }}</span>-->
			<!--</div>-->

			<!--More Actions Menu-->
			<!--<more-actions-menu @emitMenuSelection="handleEmittedMenuSelection" :menuList="moreActionsMenuOption"/>-->

		</div>

		<!--Table-->
		<v-data-table v-if="$vuetify.breakpoint.width >= 600"
					  class="appWhite rounded-lg mt-4"
					  :headers="computedHeaders"
					  :items="computedTableData">

			<!--ID-->
			<template v-slot:item.id="{item}">
				<app-text size="small">{{ item.id }}</app-text>
			</template>

			<!--Question-->
			<template v-slot:item.inductionQuestion="{item}">
				<app-text size="small">{{ item.inductionQuestion }}</app-text>
			</template>

			<!--Answer-->
			<template v-slot:item.inductionCorrectAnswer="{item}">
				<app-text size="small">{{ item.inductionCorrectAnswer }}</app-text>
			</template>

			<!--Action Button-->
			<template v-slot:item.action="{item}">
				<div class="d-flex justify-end">
					<app-icon @click.native="openRightPanel(item)"
							  class="cursorPointer" color="primary" icon="arrowForward"/>
				</div>
			</template>

		</v-data-table>

		<!--Mobile Cards-->
		<div v-if="$vuetify.breakpoint.width < 600">

			<!--No data message-->
			<app-text v-if="!computedTableData.length"
					  class="text-center mt-4" color="grey9">You have no questions to view
			</app-text>

			<induction-management-mobile-card v-for="item in computedTableData" :key="item.entityId"
											  @click.native="openRightPanel(item)"
											  :card-data="item"
											  class="mt-4"/>

		</div>

		<!--Right Side ----------------------------------------------------------------------------------- Right Side-->
		<transition enter-active-class="animate__animated animate__fadeInRight animate__faster"
					leave-active-class="animate__animated animate__fadeOutRight animate__faster"
					mode="out-in">

			<div v-if="isRightPanelVisible"
				 class="appGrey formShadow"
				 style="position:absolute; top:0; bottom: 0; right: 0; overflow: auto"
				 :style="$vuetify.breakpoint.width < 600 ? 'width: 100%' : 'width: 50%'">

				<!--Header-->
				<div class="d-flex align-center primary pa-4">
					<v-spacer/>
					<app-icon @click.native="closeRightPanel" class="cursorPointer" color="white" icon="close"
							  size="32"/>
				</div>

				<!--Action Bar-->
				<div class="d-flex align-center pa-4">

					<v-spacer/>

					<edit-icon @click.native="editItem"
							   :isActive="!isReadOnly"/>

					<delete-icon v-if="selectedItem?.entityId"
								 @emitDeleteItem="deleteItem(selectedItem)"
								 itemName=""
								 :selectedItem="selectedItem"/>

				</div>

				<!--Tabs-->
				<v-tabs v-model="tabs">
					<v-tab href="#overview">
						<app-text size="small">Overview</app-text>
					</v-tab>
				</v-tabs>

				<!--Tabs Content-->
				<v-tabs-items v-model="tabs">

					<!--Overview-->
					<v-tab-item value="overview">
						<induction-management-form @refreshData="refreshData"
												   :form-data="selectedItem"
												   :is-read-only="isReadOnly"/>
					</v-tab-item>

				</v-tabs-items>

			</div>

		</transition>

	</div>

</template>

<script>

import InductionManagementForm
	from "@/views/induction/inductionManagement/inductionManagementForm/InductionManagementForm.vue";
import NoticeBoardMobileCard from "@/views/noticeBoard/noticeBoardMobileCard/NoticeBoardMobileCard.vue";
import InductionManagementMobileCard
	from "@/views/induction/inductionManagement/inductionaManagementMobileCard/InductionManagementMobileCard.vue";

export default {

	name: "InductionManagement",

	components: {InductionManagementMobileCard, NoticeBoardMobileCard, InductionManagementForm},

	data: () => ({
		isLoading: false,
		isReadOnly: false,
		isRightPanelVisible: false,
		moreActionsMenuOption: [
			{name: 'Export', icon: 'export'}
		],
		searchByQuestion: '',
		selectedItem: {},
		tableHeaders: [
			{text: 'ID', value: 'id', align: 'start', sortable: false, hidden: true},
			{text: 'Question', value: 'inductionQuestion', align: 'start', sortable: false},
			{text: 'Answer', value: 'inductionCorrectAnswer', align: 'start', sortable: false},
			{text: '', value: 'action', align: 'right', sortable: false, width: '48px'},
		],
		tabs: '',

		// Data
		inductionQuestionsData: [],
	}),

	computed: {

		/**
		 * Computed Export CSV
		 *
		 * Return the data and headers for the CSV export
		 *
		 * @returns {{headers: {}, data: *[]}}
		 */
		computedExportCSV() {
			const t = this
			let data = []
			let headers = {}

			// Add the readable headers for the CSV columns
			headers = {
				entityId: 'ID',
				siteName: 'Site Name',
				siteTelephone: 'Site Telephone',
				siteAddressLine1: 'Site Address Line 1',
				siteAddressLine2: 'Site Address Line 2',
				siteAddressTown: 'Site Address Town',
				siteAddressCity: 'Site Address City',
				siteAddressCounty: 'Site Address County',
				siteAddressPostcode: 'Site Address Postcode',
				// siteAddressCoords: 'Site Address Coords',
				siteContact: 'Site Contact',
				siteNotes: 'Site Notes',
			}

			// Add the data
			t.computedTableData.forEach(entry => {

				const DATA_OBJECT = {
					entityId: entry?.siteData?.entityId,
					siteName: entry?.siteData?.siteName,
					siteTelephone: entry?.siteData?.siteTelephone,
					siteAddressLine1: entry?.siteData?.siteAddressLine1,
					siteAddressLine2: entry?.siteData?.siteAddressLine2,
					siteAddressTown: entry?.siteData?.siteAddressTown,
					siteAddressCity: entry?.siteData?.siteAddressCity,
					siteAddressCounty: entry?.siteData?.siteAddressCounty,
					siteAddressPostcode: entry?.siteData?.siteAddressPostcode,
					// siteAddressCoords: `${entry?.siteData?.siteAddressCoords?.latitude}, ${entry?.siteData?.siteAddressCoords?.longitude}`,
					siteContact: t.usersData.find(u => u.entityId === entry.siteData.siteContact)?.userName,
					siteNotes: entry?.siteData?.siteNotes,
				}

				data.push(DATA_OBJECT)
			})

			return {headers, data}
		},

		/**
		 * Computed Headers
		 *
		 * Remove hidden headers and only show the actions column to those with access.
		 *
		 * @returns an array of header objects
		 */
		computedHeaders() {
			const t = this
			let headers = t.tableHeaders

			// Remove hidden headers
			headers = headers.filter(h => !h.hidden)

			return headers
		},

		/**
		 * Computed Table Data
		 *
		 * Sort and return the form data.
		 *
		 * @returns {array[{}]} an array of objects
		 */
		computedTableData() {
			const t = this
			let tableData = t.inductionQuestionsData

			// Search by Question
			if (t.searchByQuestion) {
				const SEARCH_CRITERIA = t.searchByQuestion.toUpperCase()
				tableData = tableData.filter(i => i.inductionQuestion.toUpperCase().includes(SEARCH_CRITERIA))
			}

			// Sort by question
			tableData = tableData.sort((a, b) => a.inductionQuestion < b.inductionQuestion ? -1 : 1)

			return tableData
		},

	},

	methods: {

		/**
		 * Close Right Panel
		 *
		 * Close the right panel.
		 */
		closeRightPanel() {
			const t = this

			t.isRightPanelVisible = false
			t.selectedItem = {}
			t.tabs = ''
		},

		/**
		 *
		 * Delete Item
		 *
		 * Delete the selected document by its ID.
		 *
		 * @param itemData {object} the item to delete
		 */
		async deleteItem(itemData) {
			const t = this

			const RESPONSE = await t.MIX_redis_delete('inductionQuestion', itemData.entityId, itemData)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error deleting Site', RESPONSE.error)
				return
			}

			t.$sharedState.successMessage = 'Deleted Site'

			// Reload the data
			await t.loadData()

			// Close the panel
			t.closeRightPanel()
		},

		/**
		 * Edit Item
		 *
		 * Toggle the editability of the form.
		 */
		editItem() {
			const t = this

			t.isReadOnly = !t.isReadOnly
		},

		/**
		 * Export Data
		 *
		 * Export the data to CSV.
		 */
		exportData() {
			const t = this

			t.MIX_exportDocuments(t.computedExportCSV.headers, 'Induction', t.computedExportCSV.data)
		},

		/**
		 * Handle Emitted Menu Selection
		 *
		 * Take the emitted selection from the menu, and call the relevant function.
		 *
		 * @param selection a string-based menu option
		 */
		handleEmittedMenuSelection(selection) {
			const t = this

			if (selection === 'Export') t.exportData()
		},

		/**
		 * Load Data
		 *
		 * Load all the data required for the page.
		 *
		 * @returns {Promise<void>}
		 */
		async loadData() {
			const t = this

			t.isLoading = true

			await Promise.all([
				t.loadInductionQuestionsData(),
			])

			t.isLoading = false
		},

		/**
		 * Load Induction Questions Data
		 *
		 * Load the induction questions data.
		 *
		 * @returns {Promise<void>}
		 */
		async loadInductionQuestionsData() {
			const t = this

			const RESPONSE = await t.MIX_redis_getAll('inductionQuestion')

			if (RESPONSE.hasErrors) {
				console.error('Error loading Induction Questions: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem loading Induction Questions, please try again.'
				return
			}

			t.inductionQuestionsData = RESPONSE.data
		},

		/**
		 * Open Form
		 *
		 * Open the form dialog.
		 * If no item is passed in, the form will open as new (blank).
		 *
		 * @param item the selected key
		 */
		openRightPanel(item) {
			const t = this

			// New
			if (!item?.entityId) {
				t.isReadOnly = false
				t.selectedItem = {}
			}

			// Existing
			else {
				t.isReadOnly = true
				t.selectedItem = item
			}

			t.isRightPanelVisible = true
		},

		/**
		 * Refresh Data
		 *
		 * Refresh the data.
		 */
		refreshData() {
			const t = this

			t.closeRightPanel()
			t.loadData()
		},

	},

	async mounted() {
		const t = this

		await t.loadData()
	}

}
</script>

<style scoped>

</style>
