<template>

	<!--No Orientations completed message-->
	<div v-if="!orientationsData.length" class="d-flex align-center">
		<app-icon class="mr-4" color="red" icon="orientation" size="48"/>
		<app-text class="text-center">No Orientations have been completed.</app-text>
	</div>

	<div v-else>

		<!--Search by Site-->
		<app-form-field form-type="textInput"
						append-icon="icons8-search"
						:clearable="true"
						label="Search by Site"
						v-model.trim="searchBySiteName"/>

		<div v-for="item in computedOrientationsData" class="appWhite d-flex align-center rounded-lg mt-4 pa-4">

			<!--Icon-->
			<app-icon class="mr-4" color="green" icon="success" size="32"/>

			<!--Site Name | Created Date Time-->
			<div>

				<!--Site Name-->
				<app-text size="normal-bold">{{ item.siteData.siteName }}</app-text>

				<!--Created Date Time-->
				<app-text size="small">
					{{ MIX_formatDate(item.orientationData.createdDateTime, 'long') }}
					@
					{{ MIX_formatDateTimeToTime(item.orientationData.createdDateTime) }}
				</app-text>

			</div>

		</div>
	</div>
</template>

<script>

export default {

	name: "UserOrientations",

	props: ['orientationsData'],

	data: () => ({
		searchBySiteName: '',
	}),

	computed: {

		/**
		 * Computed Orientations Data
		 *
		 * Filter by Site Name.
		 * Sort by Site Name.
		 *
		 * @returns {Array} Orientations Data
		 */
		computedOrientationsData() {
			const t = this
			const ORIENTATIONS_DATA = t.$props.orientationsData

			// Filter by Site Name
			if (t.searchBySiteName) {
				const SEARCH_CRITERIA = t.searchBySiteName.toUpperCase()
				return ORIENTATIONS_DATA.filter(item => item.siteData.siteName.toUpperCase().includes(SEARCH_CRITERIA))
			}

			// Sort by Site Name
			ORIENTATIONS_DATA.sort((a, b) => a.siteData.siteName > b.siteData.siteName ? 1 : -1)

			return ORIENTATIONS_DATA
		},

	},

	methods: {},

}
</script>

<style scoped>

</style>
