<template>
	<div>

		<!--Searches-->
		<div class="d-flex">

			<!--User Name-->
			<app-form-field form-type="textInput"
							class="pr-2"
							label="Name"
							style="width: 100%"
							v-model.trim="searchByUserName"/>

			<!--Has Accepted-->
			<app-form-field form-type="select"
							class="pl-2"
							:clearable="true"
							:items="['Yes', 'No']"
							label="Accepted?"
							style="width: 100%"
							v-model.trim="filterByHasAccepted"/>

		</div>

		<!--Table-->
		<v-data-table class="appWhite rounded-lg mt-4"
					  mobile-breakpoint="0"
					  :headers="computedHeaders"
					  :items="computedTableData">

			<!--ID-->
			<template v-slot:item.entityId="{item}">
				<app-text size="small">{{ item.entityId }}</app-text>
			</template>

			<!--Accepted?-->
			<template v-slot:item.documentActionHasAccepted="{item}">
				<app-icon v-if="item.documentActionHasAccepted" color="green" icon="success" size="32"/>
				<app-icon v-if="!item.documentActionHasAccepted" color="red" icon="cancel" size="32"/>
			</template>

			<!--User Name-->
			<template v-slot:item.userName="{item}">
				<app-text size="small">
					{{ usersData.find(user => user.entityId === item.documentActionUserId)?.userName }}
				</app-text>
			</template>

			<!--Opened On-->
			<template v-slot:item.documentActionFirstOpenedDateTime="{item}">
				<app-text v-if="item.documentActionFirstOpenedDateTime !== 0" size="small">
					{{ MIX_formatDate(item.documentActionFirstOpenedDateTime, 'short') }}
					@
					{{ MIX_formatDateTimeToTime(item.documentActionFirstOpenedDateTime) }}
				</app-text>
				<app-text v-else color="greyD" size="small">Not Opened</app-text>
			</template>

			<!--Accepted On-->
			<template v-slot:item.documentActionAcceptedDateTime="{item}">
				<app-text v-if="item.documentActionAcceptedDateTime" size="small">
					{{ MIX_formatDate(item.documentActionAcceptedDateTime, 'short') }}
					@
					{{ MIX_formatDateTimeToTime(item.documentActionAcceptedDateTime) }}
				</app-text>
				<app-text v-else color="greyD" size="small">Not Accepted</app-text>
			</template>

		</v-data-table>

	</div>
</template>

<script>

export default {

	name: "DocumentationResponses",

	props: ['documentActionsData', 'usersData'],

	data: () => ({
		filterByHasAccepted: '',
		searchByUserName: '',
		tableHeaders: [
			{text: 'ID', value: 'entityId', align: 'start', sortable: false, hidden: true},
			{text: 'Accepted?', value: 'documentActionHasAccepted', align: 'center', sortable: false, width: '96px'},
			{text: 'Name', value: 'userName', align: 'start', sortable: false},
			{text: 'Opened On', value: 'documentActionFirstOpenedDateTime', align: 'start', sortable: false},
			{text: 'Accepted On', value: 'documentActionAcceptedDateTime', align: 'start', sortable: false},
		],
	}),

	computed: {

		/**
		 * Computed Headers
		 *
		 * Remove hidden headers and only show the actions column to those with access.
		 *
		 * @returns an array of header objects
		 */
		computedHeaders() {
			const t = this
			const CURRENT_USER_DATA = t.MIX_getCurrentUser()
			let headers = t.tableHeaders

			// Remove hidden headers
			headers = headers.filter(h => !h.hidden)

			// Only those with access can view the actions column
			if (!['Staff-Admin'].includes(CURRENT_USER_DATA.userLevel)) headers = headers.filter(h => h.value !== 'action')

			return headers
		},

		computedTableData() {
			const t = this
			const USERS_DATA = t.$props.usersData
			let tableData = t.$props.documentActionsData

			// Search by user name
			if (t.searchByUserName) {
				tableData = tableData.filter(d => {
					const SEARCH_CRITERIA = t.searchByUserName.toUpperCase()
					const USER_NAME = USERS_DATA.find(u => u.entityId === d.documentActionUserId)?.userName
					return USER_NAME?.toUpperCase().includes(SEARCH_CRITERIA)
				})
			}

			// Filter by has accepted
			if (t.filterByHasAccepted) {
				tableData = tableData.filter(d => {
					if (t.filterByHasAccepted === 'Yes') return d.documentActionHasAccepted
					if (t.filterByHasAccepted === 'No') return !d.documentActionHasAccepted
				})
			}

			return tableData
		},

	},

	methods: {},

}
</script>

<style scoped>

</style>
