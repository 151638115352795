<template>
	<div>

		<!--Action Bar-->
		<div class="d-flex align-center mt-4">

			<!--Search-->
			<app-form-field form-type="textInput"
							append-icon="icons8-search"
							class="mr-4"
							:clearable="true"
							label="Car Park Name"
							style="width: 100%"
							v-model.trim="searchByCarParkName"/>

			<!--Add Button-->
			<app-btn @click.native="openRightPanel" class="mr-4" icon="add" label="Add"/>

			<!--Filter Button - with a numbered badge-->
			<div style="position:relative;">
				<app-btn @click.native="toggleFiltersVisibility"
						 class="mr-4" color="appWhite" icon="filter" icon-color="primary"/>
				<span v-if="computedNumberOfActiveFilters > 0" class="badge">{{ computedNumberOfActiveFilters }}</span>
			</div>

			<!--More Actions Menu-->
			<more-actions-menu @emitMenuSelection="handleEmittedMenuSelection" :menuList="moreActionsMenuOption"/>

		</div>

		<!--Clear Filters-->
		<div v-if="computedNumberOfActiveFilters > 0" class="d-flex justify-end mt-4">
			<app-btn @click.native="clearFilters" icon="close" label="Clear Filters"/>
		</div>

		<!--Table-->
		<v-data-table v-if="$vuetify.breakpoint.width >= 600"
					  class="appWhite rounded-lg mt-4"
					  :headers="computedHeaders"
					  :items="computedTableData">

			<!--ID-->
			<template v-slot:item.entityId="{item}">
				<app-text size="small">{{ item.entityId }}</app-text>
			</template>

			<!--Status-->
			<template v-slot:item.carParkStatus="{item}">
				<app-icon v-if="item.carParkStatus === 'Open'"
						  color="green" icon="success" size="32" :title="item.carParkStatus"/>
				<app-icon v-if="item.carParkStatus === 'Closed'"
						  color="red" icon="cancel" size="32" :title="item.carParkStatus"/>
			</template>

			<!--Name-->
			<template v-slot:item.carParkName="{item}">
				<app-text size="small">{{ item.carParkName }}</app-text>
			</template>

			<!--Regular Spaces-->
			<template v-slot:item.carParkRegularSpacesAvailable="{item}">
				<div class="d-flex justify-center">
					<app-text :color="item.carParkRegularSpacesAvailable > 0 ? 'green' : 'grey9'"
							  :size="item.carParkRegularSpacesAvailable > 0 ? 'normal-bold' : 'normal'">
						{{ item.carParkRegularSpacesAvailable }}
					</app-text>
					<app-text class="mx-1" color="grey9">/</app-text>
					<app-text :color="item.carParkRegularSpacesOutOfAction > 0 ? 'red' : 'grey9'"
							  :size="item.carParkRegularSpacesOutOfAction > 0 ? 'normal-bold' : 'normal'">
						{{ item.carParkRegularSpacesOutOfAction }}
					</app-text>
				</div>
			</template>

			<!--Disabled Spaces-->
			<template v-slot:item.carParkDisabledSpacesAvailable="{item}">
				<div class="d-flex justify-center">
					<app-text :color="item.carParkDisabledSpacesAvailable > 0 ? 'green' : 'grey9'"
							  :size="item.carParkDisabledSpacesAvailable > 0 ? 'normal-bold' : 'normal'">
						{{ item.carParkDisabledSpacesAvailable }}
					</app-text>
					<app-text class="mx-1" color="grey9">/</app-text>
					<app-text :color="item.carParkDisabledSpacesOutOfAction > 0 ? 'red' : 'grey9'"
							  :size="item.carParkDisabledSpacesOutOfAction > 0 ? 'normal-bold' : 'normal'">
						{{ item.carParkDisabledSpacesOutOfAction }}
					</app-text>
				</div>
			</template>

			<!--Action Button-->
			<template v-slot:item.action="{item}">
				<app-icon @click.native="openRightPanel(item)"
						  class="cursorPointer" color="primary" icon="arrowForward"/>
			</template>

		</v-data-table>

		<!--Mobile Cards-->
		<div v-if="$vuetify.breakpoint.width < 600">

			<!--No data message-->
			<app-text v-if="!computedTableData.length"
					  class="text-center mt-4" color="grey9">You have no Car Parks to view
			</app-text>

			<parking-car-park-mobile-card v-for="item in computedTableData" :key="item.id"
										  @click.native="openRightPanel(item)"
										  :cardData="item"/>

		</div>

		<!--Filters Panel ------------------------------------------------------------------------------------------ -->
		<filter-panel :is-filters-panel-visible="isFiltersPanelVisible" @toggle="toggleFiltersVisibility">
			<div class="pa-4">

				<!--Statuses-->
				<page-break-title title="Statuses"/>
				<div class="mt-4"
					 style="display: grid; grid-template-columns: repeat(auto-fit, minmax(120px, 1fr)); grid-gap: 16px">
					<app-btn @click.native="handleFilterStatusSelection('Open')"
							 :block="true"
							 color="green"
							 label="Open"
							 :outlined="!filterByStatuses.includes('Open')"/>
					<app-btn @click.native="handleFilterStatusSelection('Closed')"
							 :block="true"
							 color="red"
							 label="Closed"
							 :outlined="!filterByStatuses.includes('Closed')"/>
				</div>

				<!--Regular Spaces - Available-->
				<page-break-title class="mt-8" title="Regular Spaces (Available)"/>
				<div class="d-flex">

					<!--Less Than-->
					<app-form-field form-type="textInput"
									class="mt-4 pr-2"
									label="Less Than"
									style="width: 100%"
									type="number"
									v-model="filterByRegularSpacesAvailableLessThan"/>

					<!--More Than-->
					<app-form-field form-type="textInput"
									class="mt-4 pl-2"
									label="More Than"
									style="width: 100%"
									type="number"
									v-model="filterByRegularSpacesAvailableMoreThan"/>

				</div>

				<!--Regular Spaces - Out of Action-->
				<page-break-title class="mt-8" title="Regular Spaces (Out of Action)"/>
				<div class="d-flex">

					<!--Less Than-->
					<app-form-field form-type="textInput"
									class="mt-4 pr-2"
									label="Less Than"
									style="width: 100%"
									type="number"
									v-model="filterByRegularSpacesOutOfActionLessThan"/>

					<!--More Than-->
					<app-form-field form-type="textInput"
									class="mt-4 pl-2"
									label="More Than"
									style="width: 100%"
									type="number"
									v-model="filterByRegularSpacesOutOfActionMoreThan"/>
				</div>

				<!--Accessible Spaces - Available-->
				<page-break-title class="mt-8" title="Accessible Spaces (Available)"/>
				<div class="d-flex">

					<!--Less Than-->
					<app-form-field form-type="textInput"
									class="mt-4 pr-2"
									label="Less Than"
									style="width: 100%"
									type="number"
									v-model="filterByAccessibleSpacesAvailableLessThan"/>

					<!--More Than-->
					<app-form-field form-type="textInput"
									class="mt-4 pl-2"
									label="More Than"
									style="width: 100%"
									type="number"
									v-model="filterByAccessibleSpacesAvailableMoreThan"/>
				</div>

				<!--Accessible Spaces - Out of Action-->
				<page-break-title class="mt-8" title="Accessible Spaces (Out of Action)"/>
				<div class="d-flex">

					<!--Less Than-->
					<app-form-field form-type="textInput"
									class="mt-4 pr-2"
									label="Less Than"
									style="width: 100%"
									type="number"
									v-model="filterByAccessibleSpacesOutOfActionLessThan"/>

					<!--More Than-->
					<app-form-field form-type="textInput"
									class="mt-4 pl-2"
									label="More Than"
									style="width: 100%"
									type="number"
									v-model="filterByAccessibleSpacesOutOfActionMoreThan"/>
				</div>

			</div>
		</filter-panel>

		<!--Right Side ----------------------------------------------------------------------------------- Right Side-->
		<transition enter-active-class="animate__animated animate__fadeInRight animate__faster"
					leave-active-class="animate__animated animate__fadeOutRight animate__faster"
					mode="out-in">

			<div v-if="isRightPanelVisible"
				 class="appGrey formShadow"
				 style="position:absolute; top:0; bottom: 0; right: 0; overflow: auto"
				 :style="$vuetify.breakpoint.width < 600 ? 'width: 100%' : 'width: 50%'"
				 v-model="isRightPanelVisible">

				<!--Header-->
				<div class="d-flex align-center primary pa-4">
					<app-text color="appWhite" size="normal">{{ selectedItem?.carParkName || 'New' }}</app-text>
					<v-spacer/>
					<app-icon @click.native="closeRightPanel" class="cursorPointer" color="white" icon="close"
							  size="32"/>
				</div>

				<!--Action Bar-->
				<div class="d-flex align-center pa-4">

					<!--Status Icon-->
					<div>
						<app-icon v-if="selectedItem?.carParkStatus === 'Open'" color="green" icon="success" size="48"/>
						<app-icon v-if="selectedItem?.carParkStatus === 'Closed'" color="red" icon="cancel" size="48"/>
					</div>

					<v-spacer/>

					<!--Action Buttons-->
					<edit-icon @click.native="editItem" :isActive="!isReadOnly"/>
					<delete-icon v-if="selectedItem?.entityId" @emitDeleteItem="deleteItem(selectedItem)"
								 itemName=""
								 :selectedItem="selectedItem"/>

				</div>

				<!--Tabs-->
				<v-tabs v-model="tabs">
					<v-tab href="#overview">
						<app-text size="small">Overview</app-text>
					</v-tab>
				</v-tabs>

				<!--Tabs Content-->
				<v-tabs-items v-model="tabs">
					<v-tab-item value="overview">
						<parking-car-park-form @emitReloadPage="emittedReloadPage"
											   class="pa-4"
											   :formData="selectedItem"
											   :isReadOnly="isReadOnly"/>
					</v-tab-item>
				</v-tabs-items>

			</div>

		</transition>

	</div>
</template>

<script>
import ParkingCarParkForm from "@/views/parking/admin/parkingCarParks/parkingCarParkForm/ParkingCarParkForm.vue";
import ParkingCarParkMobileCard
	from "@/views/parking/admin/parkingCarParks/parkingCarParkMobileCard/ParkingCarParkMobileCard.vue";

export default {

	name: "CarParksPage",

	components: {ParkingCarParkMobileCard, ParkingCarParkForm},

	data: () => ({
		filterByRegularSpacesAvailableLessThan: null,
		filterByRegularSpacesAvailableMoreThan: null,
		filterByRegularSpacesOutOfActionLessThan: null,
		filterByRegularSpacesOutOfActionMoreThan: null,
		filterByAccessibleSpacesAvailableLessThan: null,
		filterByAccessibleSpacesAvailableMoreThan: null,
		filterByAccessibleSpacesOutOfActionLessThan: null,
		filterByAccessibleSpacesOutOfActionMoreThan: null,
		filterByStatuses: [],
		isFiltersPanelVisible: false,
		isRightPanelVisible: false,
		isLoading: true,
		isReadOnly: false,
		moreActionsMenuOption: [
			{name: 'Export', icon: 'export'}
		],
		searchByCarParkName: '',
		selectedItem: {},
		tableHeaders: [
			{text: 'ID', value: 'entityId', align: 'left', sortable: false, hidden: true},
			{text: 'Status', value: 'carParkStatus', align: 'center', sortable: false, hidden: false, width: '48px'},
			{text: 'Name', value: 'carParkName', align: 'left', sortable: false, hidden: false},
			{
				text: 'Regular Available/Out of Action',
				value: 'carParkRegularSpacesAvailable',
				align: 'center',
				sortable: false,
				hidden: false
			},
			{
				text: 'Accessible Available/Out of Action',
				value: 'carParkDisabledSpacesAvailable',
				align: 'center',
				sortable: false,
				hidden: false
			},
			{text: '', value: 'action', align: 'right', sortable: false, width: '48px'},
		],
		tabs: '',

		carParksData: [],
	}),

	computed: {

		/**
		 * Computed Headers
		 *
		 * Remove hidden headers and only show the actions column to those with access.
		 *
		 * @returns an array of header objects
		 */
		computedHeaders() {
			const t = this
			let headers = t.tableHeaders

			headers = headers.filter(h => !h.hidden)

			return headers
		},

		/**
		 * Computed Number of Active Filters
		 *
		 * Count the number of active filters to display in the filters button badge.
		 *
		 * @returns {*}
		 */
		computedNumberOfActiveFilters() {
			const t = this
			return [
				t.filterByStatuses.length,
				t.filterByRegularSpacesAvailableLessThan ? 1 : 0,
				t.filterByRegularSpacesAvailableMoreThan ? 1 : 0,
				t.filterByRegularSpacesOutOfActionLessThan ? 1 : 0,
				t.filterByRegularSpacesOutOfActionMoreThan ? 1 : 0,
				t.filterByAccessibleSpacesAvailableLessThan ? 1 : 0,
				t.filterByAccessibleSpacesAvailableMoreThan ? 1 : 0,
				t.filterByAccessibleSpacesOutOfActionLessThan ? 1 : 0,
				t.filterByAccessibleSpacesOutOfActionMoreThan ? 1 : 0,
			].reduce((acc, curr) => acc + curr, 0)
		},

		/**
		 * Computed Table Data
		 *
		 * Sort and return the form data.
		 *
		 * @returns {array[{}]} an array of objects
		 */
		computedTableData() {
			const t = this
			let tableData = t.carParksData

			// Search by Name
			if (t.searchByCarParkName) {
				const SEARCH_CRITERIA = t.searchByCarParkName.toUpperCase()
				tableData = tableData.filter(item => {
					const CAR_PARK_NAME = item.carParkName.toUpperCase()
					return CAR_PARK_NAME.includes(SEARCH_CRITERIA)
				})
			}

			// Filter by Statuses
			if (t.filterByStatuses.length) tableData = tableData.filter(item => t.filterByStatuses.includes(item.carParkStatus))

			// Filter by Regular Spaces (Available) within a range
			if (t.filterByRegularSpacesAvailableLessThan && t.filterByRegularSpacesAvailableMoreThan) {
				tableData = tableData.filter(item => item.carParkRegularSpacesAvailable < t.filterByRegularSpacesAvailableLessThan && item.carParkRegularSpacesAvailable > t.filterByRegularSpacesAvailableMoreThan)
			} else {
				// Filter by Regular Spaces "Less Than"
				if (t.filterByRegularSpacesAvailableLessThan) tableData = tableData.filter(item => item.carParkRegularSpacesAvailable < t.filterByRegularSpacesAvailableLessThan)

				// Filter by Regular Spaces "More Than"
				if (t.filterByRegularSpacesAvailableMoreThan) tableData = tableData.filter(item => item.carParkRegularSpacesAvailable > t.filterByRegularSpacesAvailableMoreThan)
			}

			// Filter by Regular Spaces (Out of Action) within a range
			if (t.filterByRegularSpacesOutOfActionLessThan && t.filterByRegularSpacesOutOfActionMoreThan) {
				tableData = tableData.filter(item => item.carParkRegularSpacesOutOfAction < t.filterByRegularSpacesOutOfActionLessThan && item.carParkRegularSpacesOutOfAction > t.filterByRegularSpacesOutOfActionMoreThan)
			} else {
				// Filter by Regular Spaces "Less Than"
				if (t.filterByRegularSpacesOutOfActionLessThan) tableData = tableData.filter(item => item.carParkRegularSpacesOutOfAction < t.filterByRegularSpacesOutOfActionLessThan)

				// Filter by Regular Spaces "More Than"
				if (t.filterByRegularSpacesOutOfActionMoreThan) tableData = tableData.filter(item => item.carParkRegularSpacesOutOfAction > t.filterByRegularSpacesOutOfActionMoreThan)
			}

			// Filter by Accessible Spaces (Available) within a range
			if (t.filterByAccessibleSpacesAvailableLessThan && t.filterByAccessibleSpacesAvailableMoreThan) {
				tableData = tableData.filter(item => item.carParkDisabledSpacesAvailable < t.filterByAccessibleSpacesAvailableLessThan && item.carParkDisabledSpacesAvailable > t.filterByAccessibleSpacesAvailableMoreThan)
			} else {
				// Filter by Accessible Spaces "Less Than"
				if (t.filterByAccessibleSpacesAvailableLessThan) tableData = tableData.filter(item => item.carParkDisabledSpacesAvailable < t.filterByAccessibleSpacesAvailableLessThan)

				// Filter by Accessible Spaces "More Than"
				if (t.filterByAccessibleSpacesAvailableMoreThan) tableData = tableData.filter(item => item.carParkDisabledSpacesAvailable > t.filterByAccessibleSpacesAvailableMoreThan)
			}

			// Filter by Accessible Spaces (Out of Action) within a range
			if (t.filterByAccessibleSpacesOutOfActionLessThan && t.filterByAccessibleSpacesOutOfActionMoreThan) {
				tableData = tableData.filter(item => item.carParkDisabledSpacesOutOfAction < t.filterByAccessibleSpacesOutOfActionLessThan && item.carParkDisabledSpacesOutOfAction > t.filterByAccessibleSpacesOutOfActionMoreThan)
			} else {
				// Filter by Accessible Spaces "Less Than"
				if (t.filterByAccessibleSpacesOutOfActionLessThan) tableData = tableData.filter(item => item.carParkDisabledSpacesOutOfAction < t.filterByAccessibleSpacesOutOfActionLessThan)

				// Filter by Accessible Spaces "More Than"
				if (t.filterByAccessibleSpacesOutOfActionMoreThan) tableData = tableData.filter(item => item.carParkDisabledSpacesOutOfAction > t.filterByAccessibleSpacesOutOfActionMoreThan)
			}

			// Sort by Name
			tableData = tableData.sort((a, b) => a.carParkName > b.carParkName ? 1 : -1)

			return tableData
		},

	},

	methods: {

		/**
		 * Clear Filters
		 *
		 * Clear all the filters.
		 */
		clearFilters() {
			const t = this

			t.filterByStatuses = []
			t.filterByRegularSpacesAvailableLessThan = null
			t.filterByRegularSpacesAvailableMoreThan = null
			t.filterByRegularSpacesOutOfActionLessThan = null
			t.filterByRegularSpacesOutOfActionMoreThan = null
			t.filterByAccessibleSpacesAvailableLessThan = null
			t.filterByAccessibleSpacesAvailableMoreThan = null
			t.filterByAccessibleSpacesOutOfActionLessThan = null
			t.filterByAccessibleSpacesOutOfActionMoreThan = null
		},

		/**
		 * Close Right Panel
		 *
		 * Close the right panel.
		 */
		closeRightPanel() {
			const t = this

			t.tabs = ''
			t.selectedItem = {}
			t.isRightPanelVisible = false
		},

		/**
		 *
		 * Delete Item
		 *
		 * Delete the selected document by its ID.
		 *
		 * @param itemData {object} the item to delete
		 */
		async deleteItem(itemData) {
			const t = this

			// Stringify the Reserved List to save it to the DB
			itemData.carParkReservedList = JSON.stringify(itemData.carParkReservedList)

			const RESPONSE = await t.MIX_redis_delete('carPark', itemData.entityId, itemData)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error deleting Car Park: ', RESPONSE.error)
				return
			}

			t.$sharedState.successMessage = 'Deleted Car Park'

			// Reload the data
			await t.loadData()

			// Close the panel
			t.closeRightPanel()
		},

		/**
		 * Edit Item
		 *
		 * Toggle the editability of the form.
		 */
		editItem() {
			const t = this

			t.isReadOnly = !t.isReadOnly
		},

		/**
		 * Emitted Reload Page
		 *
		 * Close the right panel and reload the data to refresh the page.
		 */
		emittedReloadPage() {
			const t = this

			t.closeRightPanel()
			t.loadData()
		},

		/**
		 * Handle Emitted Menu Selection
		 *
		 * Take the emitted selection from the menu, and call the relevant function.
		 *
		 * @param selection a string-based menu option
		 */
		handleEmittedMenuSelection(selection) {
			const t = this

			if (selection === 'New') t.openRightPanel()
		},

		/**
		 * Handle Filter Status Selection
		 *
		 * Add or remove the selected status from the filterStatuses array.
		 *
		 * @param status {string} the status to add or remove
		 */
		handleFilterStatusSelection(status) {
			const t = this

			if (t.filterByStatuses.includes(status)) t.filterByStatuses = t.filterByStatuses.filter(s => s !== status)
			else t.filterByStatuses.push(status)
		},

		/**
		 * Load Data
		 *
		 * Load all the data required for the page.
		 *
		 * @returns {Promise<void>}
		 */
		async loadData() {
			const t = this

			t.isLoading = true

			await Promise.all([
				t.loadCarParksData()
			])

			t.isLoading = false
		},

		/**
		 * Load Car Parks Data
		 *
		 * Load the required data from the database.
		 *
		 * @returns {Promise<void>}
		 */
		async loadCarParksData() {
			const t = this

			// Fetch the data
			const RESPONSE = await t.MIX_redis_getAll('carPark')

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Car Parks: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem loading this page, please try again.'
				return
			}

			// Assign the data to the state
			t.carParksData = RESPONSE.data
		},

		/**
		 * Open Form
		 *
		 * Open the form dialog.
		 * If no item is passed in, the form will open as new (blank).
		 *
		 * @param item the selected key
		 */
		openRightPanel(item) {
			const t = this

			t.isReadOnly = !!item?.entityId

			t.selectedItem = item

			t.isRightPanelVisible = true
		},

		/**
		 * Toggle Filters Visibility
		 *
		 * Toggle the visibility of the filters panel.
		 */
		toggleFiltersVisibility() {
			const t = this

			t.isFiltersPanelVisible = !t.isFiltersPanelVisible
		},

	},

	async created() {
		const t = this

		await t.loadData()
	},

}
</script>

<style scoped>
</style>
